import { takeLatest, put, select } from "redux-saga/effects";
import {
  NOTIFICATIONS,
  NOTIFICATIONS_MARK_AS_READ,
} from "utils/constants/endpoints";
import { Types } from "../ducks/notifications";
import { Types as TypesUser } from "../ducks/user";
import { API_V2 } from "../../utils/API/API";

const getNotificationsActual = (state) => state.notifications.notifications;

function* getNotifications({ payload }) {
  const { page, per_page = 10 } = payload;
  try {
    const {
      data: { notifications },
    } = yield API_V2({
      url: `${NOTIFICATIONS}?page=${page}&per_page=${per_page}`,
      method: "GET",
    });

    const actualList =
      // eslint-disable-next-line no-nested-ternary
      page === 1 ? [] : yield select(getNotificationsActual);
    const newList = actualList.concat(notifications);
    const hasMore = notifications.length === 10;

    yield put({
      notifications: newList,
      hasMore: hasMore,
      type: Types.GET_NOTIFICATIONS_SUCCESS,
      page,
    });
  } catch (error) {
    yield put({
      type: Types.GET_NOTIFICATIONS_FAIL,
      ...error.response.data,
    });
  }
}

function* markAsRead() {
  try {
    yield API_V2({
      url: NOTIFICATIONS_MARK_AS_READ,
      method: "POST",
    });

    yield put({
      type: Types.MARK_AS_READ_SUCCESS,
    });

    yield put({
      type: TypesUser.GET_NOT_VIEWD_NOTIFICATIONS,
    });
  } catch (error) {
    yield put({
      type: Types.MARK_AS_READ_FAIL,
      ...error.response.data,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_NOTIFICATIONS, getNotifications);
  yield takeLatest(Types.MARK_AS_READ, markAsRead);
}
