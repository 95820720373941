import styled from "styled-components";
import css from "@styled-system/css";

export const ContainerSearch = styled("div")(
  css({
    flex: 1,
    display: "flex",
    gridArea: "search",
    position: "relative",
    alignItems: "center",

    "> svg": {
      position: "absolute",
      "&.magnifier": {
        left: 10,
        opacity: "0.8",
        path: {
          fill: "textColor",
        },
      },
    },
  }),
);

export const InputSearch = styled("input")(
  css({
    margin: 0,
    height: 40,
    width: "100%",
    border: "none",
    borderRadius: 4,
    padding: "10px 10px 10px 40px",

    color: "charcoalGrey",
    backgroundColor: "focusInput",

    fontSize: 16,
    fontFamily: "textLight",

    "::placeholder": {
      color: "textColor",
    },
  }),
  ({ value }) =>
    value
      ? css({
          borderRadius: "4px 0px 0px 4px",
        })
      : css({
          borderRadius: "4px",
        }),
);

export const ContainerInputIcons = styled("div")(
  css({
    height: 40,
    display: "flex",
    alignItems: "center",
    paddingRight: "10px",
    backgroundColor: "focusInput",
    borderRadius: "0px 4px 4px 0px",
    justifyContent: "space-between",
    "> svg": {
      cursor: "pointer",
      "&.clearInput": {
        path: {
          stroke: "textColor",
        },
      },
      "&:hover": {
        opacity: "0.4",
      },
    },
  }),
);
