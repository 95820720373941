import React from "react";
import { FormattedMessage } from "react-intl";
import { takeLatest, put, select } from "redux-saga/effects";
import { PATCH, GET, POST, DELETE } from "utils/constants/verbs";
import { requestAPI } from "helpers/requestHelpers";
import { API_V2 } from "utils/API/API";
import {
  DETAILS_PUBLICATION,
  VIEW_PUBLICATION,
  VIEW_PUBLICATION_V2,
  LIKE_PUBLICATIONS,
  COMMENTS_PUBLICATION,
  FORM_ANSWER,
  SURVEY_ANSWER,
  GET_LIST_TO_MARK_V2,
  POST_FAVORITE_PUBLICATION,
  DELETE_FAVORITE_PUBLICATION,
  LIKE_USERS_PUBLICATIONS,
  baseURLV3,
  baseURLV2,
  basePathV2,
} from "utils/constants/endpoints";
import Axios from "axios";
import { Types } from "../ducks/detailspublication";
import { Types as PostCommentsTypes } from "../ducks/postComments";
import { Types as AlertTypes } from "../ducks/modalAlert";
import { style } from "../../style";

const api = Axios.create({
  baseURL: baseURLV3,
  headers: {
    "Content-Type": "application/json",
    "access-token": `${localStorage.getItem("access-token")}`,
    uid: `${localStorage.getItem("uid")}`,
    client: `${localStorage.getItem("client")}`,
    "resource-type": `${localStorage.getItem("resource-type")}`,
    "host-header": `${style.api.hostHeader}`,
  },
});

// Request to score user when viewing a specific post
function* viewPublication(id) {
  try {
    yield requestAPI({
      verb: PATCH,
      endPoint: VIEW_PUBLICATION,
      id,
      data: {
        visualization: true,
      },
    });
  } catch (error) {}
}

function* viewPublicationV2(id) {
  try {
    yield API_V2({
      url: VIEW_PUBLICATION_V2(id),
      method: 'PATCH'
    });
  } catch (error) {
  }
}

function* getDetailsPublication({ payload }) {
  try {
    yield viewPublicationV2(payload);

    const { data } = yield api({
      url: DETAILS_PUBLICATION(payload),
      method: "GET",
    });

    sessionStorage.removeItem("idCanvasLike");

    const publication = {
      ...data,
      canvas_comments: data.canvas_comments,
      canvas_contents: data.canvas_contents.sort(function (x, y) {
        return x.queue - y.queue;
      }),
    };

    yield put({
      type: Types.GET_DETAILS_PUBLICATION_SUCCESS,
      publication,
    });
  } catch (error) {
    const errorMessage = error.response?.data || error;
    yield put({
      type: Types.GET_DETAILS_PUBLICATION_FAIL,
      ...errorMessage,
      isLoading: false,
    });
  }
}

function* commentPublication({ payload }) {
  const { id, comment, repliedId } = payload;

  try {
    const newComment = yield requestAPI({
      verb: POST,
      endPoint: COMMENTS_PUBLICATION,
      id,
      data: { body: comment, canvas_comment_id: repliedId },
    });

    yield put({
      payload: { postId: id },
      type: PostCommentsTypes.GET_POST_COMMENTS,
    });

    yield put({
      newComment,
      type: Types.POST_COMMENT_PUBLICATION_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.POST_COMMENT_PUBLICATION_FAIL,
      ...error.response.data,
      isLoading: false,
    });
  }
}

function* sendFormAnswer({ payload }) {
  try {
    yield requestAPI({
      verb: POST,
      endPoint: FORM_ANSWER,
      id: payload.publicationId,
      data: payload.body,
    });
    yield put({
      type: Types.POST_FORM_ANSWER_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.POST_FORM_ANSWER_FAIL,
      error: error.response.data.errors[0],
    });
  }
}

function* sendSurveyAnswer({ payload }) {
  try {
    yield requestAPI({
      verb: POST,
      endPoint: `${SURVEY_ANSWER}${payload.canvas_publication_id}/canvas_contents/${payload.canvas_content_id}/surveys/bulk_vote`,
      canvas_publication_id: payload.canvas_publication_id,
      canvas_content_id: payload.canvas_content_id,
      data: payload.body,
    });
    yield put({
      type: Types.POST_SURVEY_ANSWER_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.POST_SURVEY_ANSWER_FAIL,
      error: error.response.data.errors[0],
    });
  }
}

function* patchCommentPublication({ payload }) {
  try {
    const patchComment = yield requestAPI({
      verb: PATCH,
      endPoint: COMMENTS_PUBLICATION(payload.id),
      id: payload.idEditCommentPublication,
      data: { body: payload.comment },
    });
    yield put({
      payload: { postId: payload.id },
      type: PostCommentsTypes.GET_POST_COMMENTS,
    });
    yield put({
      patchComment,
      type: Types.PATCH_COMMENT_PUBLICATION_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.PATCH_COMMENT_PUBLICATION_FAIL,
      ...error.response.data,
      isLoading: false,
    });
  }
}

function* deleteCommentPublication({ payload }) {
  try {
    yield requestAPI({
      verb: DELETE,
      endPoint: COMMENTS_PUBLICATION(payload.id),
      id: payload.idComment,
    });
    yield put({
      payload: { postId: payload.id },
      type: PostCommentsTypes.GET_POST_COMMENTS,
    });
    yield put({
      deletedCommentId: payload.idComment,
      type: Types.DELETE_COMMENT_PUBLICATION_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.DELETE_COMMENT_PUBLICATION_FAIL,
      ...error.response.data,
      isLoading: false,
    });
  }
}

const getMarkUsersListActual = (state) => state.detailspublication.markUsersList;

function* getMarkUsers({ payload }) {

  const { page = 1, perPage = 10, name } = payload;

  try {
    const { data: { pagination, users }, } = yield API_V2({
      method: 'GET',
      url: GET_LIST_TO_MARK_V2(page, perPage, name),
    });

    const actualList =
      // eslint-disable-next-line no-nested-ternary
      page === 1 ? [] : yield select(getMarkUsersListActual);
    const newList = actualList.concat(users);
    const hasMore = !!pagination?.next_page;

    yield put({
      type: Types.GET_MARK_USERS_SUCCESS,
      markUsers: newList,
      hasMore,
      name,
      page: pagination?.current_page
    });
  } catch (error) {
    yield put({
      type: Types.GET_MARK_USERS_FAIL,

      ...error.response.data,

      isLoading: false,
    });
  }
}

function* postFavoritePublicationPageRequest({ payload }) {
  const { id } = payload;
  try {
    yield requestAPI({
      verb: POST,
      endPoint: POST_FAVORITE_PUBLICATION(id),
      data: null,
    });

    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        type: "success",
        title: <FormattedMessage id="modal.textPage.successModal" />,
        msg: "Publicação Favoritada com Sucesso !",
      },
    });

    yield put({
      type: Types.POST_FAVORITE_PUBLICATION_PAGE_SUCCESS,
      payload,
    });
  } catch (error) {
    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        type: "errors",
        title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
        msg: error.response.data[Object.keys(...error.response.data)],
      },
    });

    yield put({
      type: Types.POST_FAVORITE_PUBLICATION_PAGE_FAIL,
      payload: { error: error.response.data, isLoading: false },
    });
  }
}

function* deleteFavoritePublicationPageRequest({ payload }) {
  const { id } = payload;
  try {
    yield requestAPI({
      verb: DELETE,
      endPoint: DELETE_FAVORITE_PUBLICATION(id),
      data: null,
    });

    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        type: "success",
        title: <FormattedMessage id="modal.textPage.successModal" />,
        msg: "Publicação Desfavoritada !",
      },
    });

    yield put({
      type: Types.DELETE_FAVORITE_PUBLICATION_PAGE_SUCCESS,
      payload,
    });
  } catch (error) {
    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        type: "errors",
        title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
        msg: error.response.data[Object.keys(error.response.data)],
      },
    });

    yield put({
      type: Types.DELETE_FAVORITE_PUBLICATION_PAGE_FAIL,
      payload: { error: error.response.data, isLoading: false },
    });
  }
}

const getLikesViewListActual = (state) =>
  state.detailspublication.likesViewList;

function* getLikeUsersPublication({ payload }) {
  const { postId, page = 1, perPage = 10 } = payload;
  try {
    const response = yield requestAPI({
      verb: GET,
      endPoint: LIKE_USERS_PUBLICATIONS(postId, page),
    });

    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        isLikesView: true,
        postId,
      },
    });

    const actualList = page === 1 ? [] : yield select(getLikesViewListActual);
    const newList = actualList.concat(response);

    yield put({
      type: Types.GET_LIKE_USERS_PUBLICATION_SUCCESS,
      likesViewList: newList,
      hasMoreLikesViewList: response.length === 10,
      pageLikesView: page,
    });
  } catch (error) {
    // yield put({
    //   type: AlertTypes.SET_ALERTS_REQUEST,
    //   messages: {
    //     type: "errors",
    //     title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
    //     msg: error.response.data[Object.keys(...error.response.data)],
    //   },
    // });

    yield put({
      type: Types.GET_LIKE_USERS_PUBLICATION_FAIL,
      error: error.response.data,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_DETAILS_PUBLICATION, getDetailsPublication);
  yield takeLatest(Types.POST_COMMENT_PUBLICATION, commentPublication);
  yield takeLatest(Types.POST_FORM_ANSWER, sendFormAnswer);
  yield takeLatest(Types.POST_SURVEY_ANSWER, sendSurveyAnswer);
  yield takeLatest(Types.PATCH_COMMENT_PUBLICATION, patchCommentPublication);
  yield takeLatest(Types.DELETE_COMMENT_PUBLICATION, deleteCommentPublication);
  yield takeLatest(Types.GET_MARK_USERS, getMarkUsers);
  yield takeLatest(
    Types.POST_FAVORITE_PUBLICATION_PAGE_REQUEST,
    postFavoritePublicationPageRequest,
  );
  yield takeLatest(
    Types.DELETE_FAVORITE_PUBLICATION_PAGE_REQUEST,
    deleteFavoritePublicationPageRequest,
  );
  yield takeLatest(Types.GET_LIKE_USERS_PUBLICATION, getLikeUsersPublication);
}
