import React, { useState, useLayoutEffect } from "react";
import Skeleton from "react-loading-skeleton";

import Flex from "components/core/Grid/Flex";

const AcademySkeleton = () => {
  const [items, setItems] = useState([]);

  useLayoutEffect(() => {
    for (let i = 0; i < 5; i++) {
      setItems((oldState) => [
        ...oldState,
        <Flex
          key={i}
          mb={2}
          pb={12}
          spaceChildren={2}
          borderBottom="1px solid #ccd2e0"
        >
          <Skeleton duration={0.8} width={260} height={60} />
          <Skeleton circle={true} width={48} height={48} />
        </Flex>,
      ]);
    }
  }, []);

  return (
    <Flex flexDirection="column" p={3}>
      {items}
      <Flex mb={2} pb={12} spaceChildren={2}>
        <Skeleton duration={0.8} width={260} height={60} />
        <Skeleton circle={true} width={48} height={48} />
      </Flex>
    </Flex>
  );
};

export default AcademySkeleton;
