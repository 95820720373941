/* eslint-disable no-unused-vars */
import React from "react";
import Skeleton from "react-loading-skeleton";
import Flex from "components/core/Grid/Flex";

import * as S from "./CourseProgressStyle";

const CourseProgressSkeleton = () => {
  const skeletonRepeat = 5;

  return (
    <S.CourseContainer>
      <S.CourseInfo>
        <Skeleton duration={0.8} width={200} height={48} />
        <Skeleton circle width={48} height={48} />
      </S.CourseInfo>

      <S.CourseModulesList>
        {[...Array(skeletonRepeat)].map((_val, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <S.CourseModule key={index}>
            <Flex justifyContent="space-between">
              <Skeleton duration={0.8} width={200} height={50} />
              <Skeleton
                circle
                width={24}
                height={24}
                style={{ marginLeft: "-8px" }}
              />
            </Flex>
          </S.CourseModule>
        ))}
      </S.CourseModulesList>
    </S.CourseContainer>
  );
};

export default CourseProgressSkeleton;
