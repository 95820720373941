import React from "react";
import Skeleton from "react-loading-skeleton";
import Flex from "components/core/Grid/Flex";
import { v1 as uuidv1 } from "uuid";

const CommentsLoadMoreSkeleton = () => (
  <Flex px={38} flexDirection="column" spaceChildren={4}>
    <Flex spaceChildren={2} key={uuidv1()}>
      <Skeleton circle duration={0.8} width={35} height={35} />
      <Flex flexDirection="column" mt={2} spaceChildren={2}>
        <Skeleton duration={0.8} width={98} height={20} />
        <Skeleton duration={0.8} count={2} width={200} height={20} />
      </Flex>
    </Flex>
  </Flex>
);

export default CommentsLoadMoreSkeleton;

export const MarkUsersLoadMoreSkeleton = () => (
  <Flex px={2} flexDirection="column" spaceChildren={2}>
    <Flex spaceChildren={2} key={uuidv1()}>
      <Skeleton circle duration={0.8} width={30} height={30} />
      <Flex flexDirection="column" mt={2} spaceChildren={2}>
        <Skeleton duration={0.8} width={98} height={20} />
      </Flex>
    </Flex>
  </Flex>
);
