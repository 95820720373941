import { useEffect } from "react";
import { style as teamAttributes } from "../../style";
import { teamFirebase } from "../constants/teamsFirebase";
import usePrevious from "../hooks/usePrevious";

const AnalyticsPageView = ({ title }) => {
  const defaultTitle = teamAttributes.names.nameApp;
  const { gtag } = window;
  const previousTitle = usePrevious(title);

  const {
    firebaseConfig: { gtagId: teamGtagId },
  } = teamFirebase.find(
    (team) =>
      team.teamName.toLowerCase() ===
      teamAttributes.api.nameProfile.toLowerCase(),
  );

  useEffect(() => {
    if (
      (title.length &&
        title !== previousTitle &&
        title === document.title.split(" -")[0] &&
        document.title.split(" -")[0] !== defaultTitle) ||
      (title.length &&
        previousTitle !== undefined &&
        title !== document.title.split(" -")[0] &&
        document.title.split(" -")[0] !== defaultTitle)
    ) {
      gtag("event", "page_view", {
        page_title: title,
        page_location: window.location.href,
        send_to: teamGtagId,
      });
    }
  }, [title, gtag, teamGtagId, previousTitle, defaultTitle]);

  return null;
};

export default AnalyticsPageView;
