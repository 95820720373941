import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { reactionsListArray } from "../../../utils/Reactions/reactions";
import { Creators as PublicationReactionActions } from "../../../store/ducks/reactions";

import * as S from "./ReactionsStyle";

export const Reactions = ({
  type,
  idPublication,
  idComment,
  reactions,
  currentUser,
  commentReactions,
  comment,
  currentUserHasReacted,
  user_reaction,
  commentUserId,
  idReaction,
  userReaction,
  onEmojiClick,
}) => {
  const dispatch = useDispatch();

  const handleCommentsReactions = (payload) => {
    onEmojiClick && onEmojiClick();
    const { canvasUserReactionsInfo, reaction, comment, isLikeButton } =
      payload;

    const currentUserHasReactedToComment = commentUserId === currentUser.id;

    if (isLikeButton && currentUserHasReactedToComment) {
      dispatch(
        PublicationReactionActions.deletePublicationCommentsReactions({
          idComment,
          reaction,
          idReaction,
          postId: idPublication,
        }),
      );
    } else if (!currentUserHasReactedToComment) {
      dispatch(
        PublicationReactionActions.createPublicationCommentsReactions({
          idComment,
          reaction,
          postId: idPublication,
        }),
      );
    } else if (currentUserHasReactedToComment && reaction !== userReaction) {
      dispatch(
        PublicationReactionActions.editPublicationCommentsReactions({
          idComment,
          idReaction,
          reaction,
          postId: idPublication,
        }),
      );
    }
  };

  // eslint-disable-next-line consistent-return
  const handleReactions = (reaction) => {
    if (type === "publication" || type === "publicationMobile") {
      onEmojiClick && onEmojiClick();
      return dispatch(
        PublicationReactionActions.postLikedPublication({
          idPublication,
          reaction,
          currentUser,
          currentUserHasReacted,
          user_reaction,
        }),
      );
    }

    if (
      type === "comment" ||
      type === "commentReply" ||
      type === "commentMobile" ||
      type === "commentReplyMobile"
    ) {
      return handleCommentsReactions({
        idComment,
        isLikeButton: false,
        reaction,
        canvasUserReactionsInfo:
          commentReactions[idComment] &&
          commentReactions[idComment].reactions.find(
            (item) => item.user.id === currentUser.id,
          ),
        currentUser,
        comment,
        commentUserId,
        idReaction,
        userReaction,
        idPublication,
      });
    }
  };

  return (
    <S.ReactionsBar type={type}>
      {reactionsListArray.map((reaction) => (
        <div key={reaction.id} style={{ position: "relative" }}>
          <S.ReactionsIcons
            type={type}
            src={reaction.icon}
            alt={reaction.title}
            onClick={() => handleReactions(reaction.value)}
          />
          <S.ReactionTitle>{reaction.title}</S.ReactionTitle>
        </div>
      ))}
    </S.ReactionsBar>
  );
};

Reactions.defaultProps = {
  type: null,
  idPublication: null,
  reactions: null,
  commentReactions: null,
  currentUser: null,
  idComment: null,
  user_reaction: null,
  commentUserId: null,
  idReaction: null,
  userReaction: null,
  onEmojiClick: null,
};

Reactions.propTypes = {
  onEmojiClick: PropTypes.func,
  type: PropTypes.string,
  idPublication: PropTypes.string,
  commentUserId: PropTypes.number,
  idReaction: PropTypes.number,
  userReaction: PropTypes.string,
  user_reaction: PropTypes.objectOf(
    PropTypes.shape({
      reaction_id: PropTypes.number,
      reaction: PropTypes.string,
      user_id: PropTypes.number,
    }),
  ),
  reactions: PropTypes.arrayOf(
    PropTypes.shape({
      canvas_likes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          reaction: PropTypes.string,
          user: PropTypes.objectOf(
            PropTypes.shape({
              id: PropTypes.number,
              user_image: PropTypes.string,
              user_name: PropTypes.string,
            }),
          ),
        }),
      ),
    }),
  ),
  commentReactions: PropTypes.arrayOf(
    PropTypes.shape({
      canvas_likes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          reaction: PropTypes.string,
          user: PropTypes.objectOf(
            PropTypes.shape({
              id: PropTypes.number,
              user_image: PropTypes.string,
              user_name: PropTypes.string,
            }),
          ),
        }),
      ),
    }),
  ),
  currentUser: PropTypes.bool,
  idComment: PropTypes.number,
};
