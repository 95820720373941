import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getPayroll: ["payload"],
  getPayrollSuccess: ["payload", "isLoading"],
  getPayrollFail: ["isLoading", "isError", "payload"],
  getPayrollPdf: ["payload", "isLoadingPdf"],
  getPayrollPdfSuccess: ["isLoadingPdf"],
  getPayrollPdfFail: ["isLoadingPdf"],
  getPayrollTotem: ["payload", "isLoadingTotem"],
  getPayrollTotemSuccess: ["isLoadingTotem"],
  getPayrollTotemFail: ["isLoadingTotem"],

  getHoleriteMachado: ["payload"],
  getHoleriteMachadoSuccess: ["payload", "isLoading"],
  getHoleriteMachadoFail: ["isLoading", "isError", "payload"],
});

const INITIAL_STATE = {
  payroll: {},
  error: "",
  isLoading: true,
  isLoadingPdf: false,
  isLoadingTotem: false,
  isError: false,

  isLoadingMachado: false,
  linkHoleriteMachado: '',
};

const getPayroll = (state) => ({
  state,
  isLoading: true,
});

const getPayrollSuccess = (state, { payroll }) => ({
  ...state,
  payroll,
  error: "",
  isError: false,
  isLoading: false,
});

const getPayrollFail = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
  isError: true,
});

const getPayrollPdf = (state) => ({
  ...state,
  isLoadingPdf: true,
});

const getPayrollPdfSuccess = (state) => ({
  ...state,
  isLoadingPdf: false,
});

const getPayrollPdfFail = (state) => ({
  ...state,
  isLoadingPdf: false,
});

const getPayrollTotem = (state) => ({
  ...state,
  isLoadingTotem: true,
});

const getPayrollTotemSuccess = (state) => ({
  ...state,
  isLoadingTotem: false,
});

const getPayrollTotemFail = (state) => ({
  ...state,
  isLoadingTotem: false,
});

const getHoleriteMachado = (state) => ({
  ...state,
  isLoadingMachado: true,
});

const getHoleriteMachadoSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoadingMachado: false,
});

const getHoleriteMachadoFail = (state, error) => ({
  ...state,
  ...error,
  isLoadingMachado: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_PAYROLL]: getPayroll,
  [Types.GET_PAYROLL_SUCCESS]: getPayrollSuccess,
  [Types.GET_PAYROLL_FAIL]: getPayrollFail,
  [Types.GET_PAYROLL_PDF]: getPayrollPdf,
  [Types.GET_PAYROLL_PDF_SUCCESS]: getPayrollPdfSuccess,
  [Types.GET_PAYROLL_PDF_FAIL]: getPayrollPdfFail,
  [Types.GET_PAYROLL_TOTEM]: getPayrollTotem,
  [Types.GET_PAYROLL_TOTEM_SUCCESS]: getPayrollTotemSuccess,
  [Types.GET_PAYROLL_TOTEM_FAIL]: getPayrollTotemFail,
  [Types.GET_HOLERITE_MACHADO]: getHoleriteMachado,
  [Types.GET_HOLERITE_MACHADO_SUCCESS]: getHoleriteMachadoSuccess,
  [Types.GET_HOLERITE_MACHADO_FAIL]: getHoleriteMachadoFail,
});
