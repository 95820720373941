/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback, useRef, memo } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import urls from "utils/constants/urls";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import InfiniteScroll from "react-infinite-scroller";
import { style } from "style";

import { Creators as UserActions } from "store/ducks/user";
import { Creators as MuralActions } from "store/ducks/mural";
import { Creators as PublicationActions } from "store/ducks/detailspublication";
import { Creators as PostCommentsActions } from "store/ducks/postComments";
import { Creators as PublicationReactionActions } from "store/ducks/reactions";

import Flex from "components/core/Grid/Flex";
import Avatar from "components/core/Avatar/Avatar";
import { PageTitle } from "components/core/Typography/Typography";
import { ReactComponent as EditIcon } from "assets/img/icons/editIcon.svg";
import { ReactComponent as IconClose } from "assets/img/icons/iconFechar.svg";
import { ReactComponent as RemoveIcon } from "assets/img/icons/removeIcon.svg";
import { ReactComponent as SendIcon } from "assets/img/icons/send.svg";
import { ReactComponent as ActionOptionsIcon } from "assets/img/icons/actionOptions.svg";
import { ReactComponent as LikeIconDefault } from "assets/img/icons/iconLikeDefault.svg";
import { ReactComponent as LikeIcon } from "assets/img/icons/iconLike.svg";
import { ReactComponent as LovedIcon } from "assets/img/icons/iconLoved.svg";
import { ReactComponent as LaughterIcon } from "assets/img/icons/iconLaughter.svg";
import { ReactComponent as SurprisedIcon } from "assets/img/icons/iconSurprised.svg";
import { ReactComponent as SupportIcon } from "assets/img/icons/iconSupport.svg";
import { ReactComponent as CongratsIcon } from "assets/img/icons/iconCongrats.svg";
import {
  smartFormatterDate,
  handleCommentDateFns,
} from "helpers/sharedHelpers";
import { reactionsList } from "utils/Reactions/reactions";
import { ModalProvider } from "styled-react-modal";
import SidebarPublicationSkeleton from "./SidebarPublicationSkeleton";
import CommentsLoadMoreSkeleton, {
  MarkUsersLoadMoreSkeleton,
} from "./CommentsLoadMoreSkeleton";
import { Reactions } from "../../../../components/core/Reactions/Reactions";
import ReactionsViewModal from "../reactionsModal/ReactionsViewModal";
import * as S from "./SidebarPublicationStyle";

const Publication = memo(
  ({
    isLiked,
    idMentionedComment,
    publications,
    isLoadingPublication,
    isLoading,
    isLoadingUser,
    likes,
    comments_enabled,
    likes_enabled,
    min_size_comments,
    sendingComment,
    // markUsersPublish,
  }) => {
    const { id } = useParams();
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    const commentRef = useRef(null);
    const commentAreaRef = useRef(null);
    const [showSend, setShowSend] = useState(false);
    const [timerTimeout, setTimerTimeout] = useState(null);
    const [highlight, setHighlight] = useState(true);
    const [idEditCommentPublication, setIdEditCommentPublication] = useState(0);
    const [showUserMarked, setShowUserMarked] = useState(false);
    const [idCommentAnswer, setIdCommentAnswer] = useState(0);
    const [nickname, setNickname] = useState("");
    const [blockComment, setBlockComment] = useState(false);
    const [keyboardOnFocus, setKeyboardOnFocus] = useState(false);
    const [openReactionsModal, setOpenReactionsModal] = useState(false);
    const [typeOfReaction, setTypeOfReaction] = useState("");
    const [shouldSaveAllReactions, setShouldSaveAllReactions] = useState(true);
    const [selectedId, setSelectedId] = useState();
    const [toogleCollapsed, setToogleCollapsed] = useState(false);

    const reactionsIconsList = {
      like: { icon: LikeIcon, text: "Curtir" },
      loved_it: { icon: LovedIcon, text: "Amei" },
      laughter: { icon: LaughterIcon, text: "Risada" },
      surprized: { icon: SurprisedIcon, text: "Surpreso" },
      support: { icon: SupportIcon, text: "Apoio" },
      congrats: { icon: CongratsIcon, text: "Parabéns" },
    };

    const reactionDefault = {
      icon: LikeIconDefault,
      title: reactionsIconsList.like.text,
      value: reactionsList.like.value,
    };

    const {
      //isLoading,
      publication: {
        // liked,
        likes: totalPublicationReactions,
        userslikes,
        user_reaction,
        reactions: publicationReactions,
        // comments_enabled,
        // likes_enabled,
        // min_size_comments,
        comments_amount,
      },
      // sendingComment,
      // markUsersPublish,
    } = useSelector(({ detailspublication }) => detailspublication);

    const {
      markUsersList,
      hasMoreMarkUsers,
      isLoadingMarkUsers,
      pageMarkUsers,
      markUsersName,
    } = useSelector(({ detailspublication }) => detailspublication);

    const {
      reactions,
      commentReactions,
      allReactions,
      liked,
      isLoading: isReactionsLoading,
    } = useSelector(({ reactions }) => reactions);

    const { user: currentUser, /* isLoading: isLoadingUser, */ originRequest } =
      useSelector(({ user }) => user);

    const hasReactions = userslikes?.length > 0;

    const currentUserHasReacted =
      user_reaction && user_reaction.user_id === currentUser.id;

    const [howManyCharsLeftToComment, setHowManyCharsLeftToComment] =
      useState(min_size_comments);

    const {
      comments: canvas_comments,
      hasMore,
      page,
      pagination,
      isLoading: isLoadingComment,
    } = useSelector(({ postComments }) => postComments);

    const currentUserHasReactedToComment = (comment) => {
      return comment.user_reaction?.user_id === currentUser.id;
    };

    const currentUserHasReactedToCommentReply = (answer) => {
      return answer.reply_user_reaction?.user_id === currentUser.id;
    };

    const publicationsFilterImage = publications
      ?.filter(({ kind }) => kind === "image")
      .slice(0, 3);

    const commentedUser = canvas_comments?.find(
      ({ user }) => user?.id === currentUser?.id,
    );

    useEffect(() => {
      if (idMentionedComment) {
        setHighlight(true);
        clearTimeout(timerTimeout);

        if (!isLoading && !isLoadingComment) {
          commentRef.current?.scrollIntoView({ behavior: "smooth" });

          setTimerTimeout(
            setTimeout(() => {
              setHighlight(false);
            }, 5000),
          );
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commentRef, isLoading, idMentionedComment, isLoadingComment]);

    const dispatchGetMarkUsers = useCallback(
      (userName) => {
        dispatch(
          PublicationActions.getMarkUsers({
            page: 1,
            perPage: 10,
            name: userName,
          }),
        );
      },
      [dispatch, id],
    );

    const dispatchPublicationsRequest = useCallback(() => {
      dispatch(
        MuralActions.publicationsRequest({
          search: "",
          page: 1,
          favorite: false,
          selectTags: [],
        }),
      );
    }, [dispatch]);

    const dispatchGetUserRequest = useCallback(
      () => dispatch(UserActions.getUserRequest()),
      [dispatch],
    );

    const dispatchGetUserRequestOrigin = useCallback(
      () =>
        dispatch(
          UserActions.getUserRequestOrigin({
            originRequest: "SidebarPublication",
          }),
        ),
      [dispatch],
    );

    useEffect(() => {
      if (liked === false) {
        isLiked(false);
      }
    });

    useEffect(() => {
      if (!isLoading && !!currentUser?.id) {
        dispatchGetUserRequest();
      }
    }, [publicationReactions, canvas_comments]);

    useEffect(() => {
      if (!isLoadingUser && !currentUser?.id) {
        dispatchGetUserRequestOrigin();
      }
    }, [dispatchGetUserRequestOrigin]);

    useEffect(() => {
      if (originRequest === "SidebarPublication") dispatchGetUserRequest();
    }, [originRequest, dispatchGetUserRequest]);

    useEffect(() => {
      if (style.variables.gamificationEnabled) {
        dispatchGetMarkUsers();
      } else {
        dispatchPublicationsRequest();
      }
    }, [dispatchGetMarkUsers, dispatchPublicationsRequest]);

    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.keyCode === 13) {
        handleCreateComment(event);
      }
    };

    const handleCreateComment = (evt) => {
      evt.preventDefault();
      const commentPublication = commentAreaRef.current?.value;
      if (commentPublication.trim().length > 0) {
        if (idEditCommentPublication !== 0) {
          dispatch(
            PublicationActions.patchCommentPublication({
              id,
              idEditCommentPublication,
              comment: commentPublication,
            }),
          );
        } else if (idCommentAnswer !== 0) {
          dispatch(
            PublicationActions.postCommentPublication({
              id,
              repliedId: idCommentAnswer,
              comment: commentPublication,
            }),
          );
        } else {
          dispatch(
            PublicationActions.postCommentPublication({
              id,
              comment: commentPublication,
            }),
          );
        }
      }
      setNickname("");
      setIdCommentAnswer(0);
      commentAreaRef.current.value = "";
      setIdEditCommentPublication(0);
    };

    const handleMarkUserComment = () => {
      const value = commentAreaRef.current?.value.trim();
      const lastElement = value[value.length - 1];
      value.length >= min_size_comments && value.length > 0
        ? (setShowSend(true), setHowManyCharsLeftToComment(0))
        : (setShowSend(false),
          setHowManyCharsLeftToComment(min_size_comments - value.length));
      setBlockComment(false);

      if (lastElement === "@") {
        setShowUserMarked(true);
      }

      if (lastElement) {
        const markUserIndex = value.lastIndexOf("@", value);
        if (markUserIndex >= 0 && lastElement !== " ") {
          const stringPresent = value.substring(
            markUserIndex + 1,
            value.length,
          );
          if (stringPresent.length > 0) {
            dispatchGetMarkUsers(stringPresent);
          } else {
            dispatchGetMarkUsers("");
          }
        }
        return;
      }

      setShowUserMarked(false);
    };

    const handleEditComment = (idComment, valueComment) => {
      commentAreaRef.current.value = valueComment;
      setIdEditCommentPublication(idComment);
    };

    const handleRemoveComment = (idComment) => {
      dispatch(PublicationActions.deleteCommentPublication({ id, idComment }));
    };

    const toggleCollapse = (index) => {
      const target = document.getElementById(`comment-${index}`);
      const toogleCollapsed = target.classList.toggle("uncollapsed");
      setToogleCollapsed(toogleCollapsed);
    };

    const handleSelectedUser = (nickName) => {
      const value = commentAreaRef.current?.value;
      const lastElem = commentAreaRef.current?.selectionStart;
      const startElem = value.lastIndexOf("@", lastElem);
      const valueString = `${value.slice(
        0,
        startElem,
      )}@${nickName}${value.slice(lastElem)}`;
      commentAreaRef.current.value = valueString;
      setShowUserMarked(false);
      dispatchGetMarkUsers();
    };

    const handleCommentDate = (comment) => {
      const currentDate = smartFormatterDate(new Date(), "L").split("/");
      const commentData = smartFormatterDate(comment?.created_at, "L").split(
        "/",
      );

      if (currentDate[1] - commentData[1] >= 4) {
        return smartFormatterDate(comment?.created_at, "L");
      } else return handleCommentDateFns(comment?.created_at);
    };

    const handleCommentsReactions = (payload) => {
      const {
        canvasUserReactionsInfo,
        reaction,
        comment,
        isLikeButton,
        idComment,
        commentUserId,
        idReaction,
        userReaction,
        idPublication,
      } = payload;

      const currentUserHasReactedToComment = commentUserId === currentUser.id;

      if (isLikeButton && currentUserHasReactedToComment) {
        dispatch(
          PublicationReactionActions.deletePublicationCommentsReactions({
            idComment,
            reaction,
            idReaction,
            postId: idPublication,
          }),
        );
      } else if (!currentUserHasReactedToComment) {
        dispatch(
          PublicationReactionActions.createPublicationCommentsReactions({
            idComment,
            reaction,
            postId: idPublication,
          }),
        );
      } else if (currentUserHasReactedToComment && reaction !== userReaction) {
        dispatch(
          PublicationReactionActions.editPublicationCommentsReactions({
            idComment,
            idReaction,
            reaction,
            postId: idPublication,
          }),
        );
      }
    };

    const handleCommentsShowReactions = (payload) => {
      const { id, reaction, perPage } = payload;

      dispatch(
        PublicationReactionActions.getPublicationAllCommentsReactions({
          id,
          reaction,
          page: 1,
          per_page: perPage,
        }),
      );

      dispatch(
        PublicationReactionActions.getPublicationCommentsReactions({
          id,
          reaction,
          page: 1,
          per_page: 5,
        }),
      );

      setTypeOfReaction("comment");
      setOpenReactionsModal(true);
      setShouldSaveAllReactions(true);
      setSelectedId(id);
    };

    const handlePublicationShowReactions = (payload) => {
      const { id, reaction } = payload;

      dispatch(
        PublicationReactionActions.getPublicationAllReactions({
          id,
          reaction,
          page: 1,
          per_page: totalPublicationReactions,
        }),
      );

      dispatch(
        PublicationReactionActions.getPublicationReactions({
          id,
          reaction,
          page: 1,
          per_page: 5,
        }),
      );

      setOpenReactionsModal(true);
      setTypeOfReaction("post");
      setShouldSaveAllReactions(true);
      setSelectedId(id);
    };

    const popoverEditComment = (valueComment, idComment, repliesComment) => (
      <S.StyledPopover>
        <S.ContainerPopoveActions>
          <PageTitle noBorder title="O que deseja fazer?" fontSize={2} />
          <S.ContainerOption>
            <S.OptionPopove
              type="button"
              onClick={() => handleEditComment(idComment, valueComment)}
            >
              <EditIcon />
              <S.TextOption>
                {intl.messages["general.buttons.edit"]}
              </S.TextOption>
            </S.OptionPopove>
          </S.ContainerOption>
          {!repliesComment && (
            <S.ContainerOption>
              <S.OptionPopove
                type="button"
                onClick={() => handleRemoveComment(idComment)}
              >
                <RemoveIcon />
                <S.TextOption>
                  {intl.messages["general.buttons.remove"]}
                </S.TextOption>
              </S.OptionPopove>
            </S.ContainerOption>
          )}
        </S.ContainerPopoveActions>
      </S.StyledPopover>
    );

    const fetchMoreData = () => {
      hasMore &&
        !isLoadingComment &&
        dispatch(
          PostCommentsActions.getPostComments({
            postId: id,
            page: page + 1,
            commentId: idMentionedComment,
          }),
        );
    };

    const checkIfCanComment = () => {
      if (!showSend) setBlockComment(true);
    };

    const redirectToUserProfile = (comment, word) => {
      comment.user_markups.map((user) => {
        if (word.replace("@", "") === user.nickname) {
          history.push(`${urls.ROUTES.PROFILE}/${user.id}`);
        }
      });
    };

    const userIsMarkup = (comment, word) => {
      return (
        comment.user_markups.filter(
          (user) => user.nickname === word.replace("@", ""),
        ).length > 0
      );
    };

    const userIsMarkupInReply = (comment, word) => {
      return (
        comment.replies.map((reply) =>
          reply.user_markups.filter(
            (user) => user.nickname === word.replace("@", ""),
          ),
        ).length > 0
      );
    };

    const fetchMoreDataMarkUsers = () => {
      hasMoreMarkUsers &&
        !isLoadingMarkUsers &&
        dispatch(
          PublicationActions.getMarkUsers({
            page: pageMarkUsers + 1,
            perPage: 10,
            name: markUsersName,
          }),
        );
    };

    return isLoading ||
      (isLoadingPublication && !style.variables.gamificationEnabled) ? (
      <SidebarPublicationSkeleton />
    ) : (
      <S.ContainerSideBar>
        {localStorage.getItem("roles") !== "guest" && likes_enabled && (
          <S.LikeContainer liked={liked} hasReactions={hasReactions}>
            {hasReactions && (
              <S.LikeCounter
                onClick={() =>
                  handlePublicationShowReactions({ id, reaction: "" })
                }
              >
                <>
                  <S.UsersLike>
                    {hasReactions &&
                      Object.keys(publicationReactions)
                        .sort(
                          (a, b) =>
                            publicationReactions[b] - publicationReactions[a],
                        )
                        .slice(0, 3)
                        .map((reaction, index, array) => (
                          <Avatar
                            url={reactionsList[reaction]?.icon}
                            size="28px"
                            style={{
                              zIndex: array.length - index,
                            }}
                          />
                        ))}
                  </S.UsersLike>
                  <S.Counter>{totalPublicationReactions}</S.Counter>
                </>
              </S.LikeCounter>
            )}
            <S.LikeButton
              style={{ minHeight: "52px" }}
              icon={
                currentUserHasReacted
                  ? reactionsIconsList[user_reaction?.reaction]?.icon
                  : reactionDefault.icon
              }
              name={
                currentUserHasReacted
                  ? reactionsIconsList[user_reaction?.reaction]?.text
                  : reactionDefault.title
              }
              variant={
                localStorage.getItem("roles") === "guest"
                  ? ["gradient", "disabled"]
                  : ["gradient"]
              }
              action={() =>
                dispatch(
                  PublicationReactionActions.postLikedPublication({
                    idPublication: id,
                    canvasUserReactionsInfo: reactions?.canvas_likes?.find(
                      (reaction) => reaction.user.user_id === currentUser.id,
                    ),
                    reaction: "like",
                    isLikeButton: true,
                    currentUser,
                    currentUserHasReacted,
                    user_reaction,
                  }),
                )
              }
            />

            <Reactions
              type="publication"
              idPublication={id}
              reactions
              liked
              currentUser={currentUser}
              currentUserHasReacted={currentUserHasReacted}
              user_reaction={user_reaction}
            />
          </S.LikeContainer>
        )}

        {style.variables.gamificationEnabled &&
        localStorage.getItem("roles") !== "guest" &&
        comments_enabled ? (
          <S.ContainerComments>
            <S.CommentsHeader commented={commentedUser}>
              {comments_amount && comments_amount}{" "}
              {comments_amount > 1
                ? intl.messages["publication.textPage.comments"]
                : intl.messages["publication.textPage.commentSingular"]}
            </S.CommentsHeader>

            {canvas_comments?.length > 0 && (
              <S.OverflowComments>
                <InfiniteScroll
                  pageStart={1}
                  initialLoad={false}
                  loadMore={fetchMoreData}
                  hasMore={hasMore}
                  useWindow={false}
                  threshold={50}
                  // getScrollParent={() => scrollParentRef}
                  loader={<CommentsLoadMoreSkeleton />}
                >
                  <Flex
                    px={16}
                    flexDirection="column"
                    spaceChildren={4}
                    pb={50}
                  >
                    {canvas_comments &&
                      canvas_comments?.map(
                        (comment) =>
                          comment?.replied_comment_id === null && (
                            <S.Comment
                              key={comment.id}
                              ref={
                                comment.id === idMentionedComment
                                  ? commentRef
                                  : null
                              }
                              highlight={
                                comment.id === idMentionedComment
                                  ? highlight
                                  : false
                              }
                            >
                              <Flex
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Flex spaceChildren={2}>
                                  <Avatar
                                    url={comment.user?.image?.url}
                                    width={32}
                                    height={32}
                                    onClick={() =>
                                      history.push(
                                        `${urls.ROUTES.PROFILE}/${comment?.user?.id}`,
                                      )
                                    }
                                  />
                                  <div>
                                    <S.Username>
                                      {comment.user?.name}
                                    </S.Username>
                                    <S.TimeAnswer>
                                      {handleCommentDate(comment)}
                                    </S.TimeAnswer>
                                  </div>
                                </Flex>
                                {comment.user?.id === currentUser.id && (
                                  <OverlayTrigger
                                    trigger="click"
                                    rootClose
                                    placement="bottom"
                                    overlay={popoverEditComment(
                                      comment.body,
                                      comment.id,
                                      comment.replies.length > 0,
                                    )}
                                  >
                                    <S.ContainerActionOptions>
                                      <ActionOptionsIcon />
                                    </S.ContainerActionOptions>
                                  </OverlayTrigger>
                                )}
                              </Flex>
                              <S.UserComment>
                                {comment?.body.split(" ").map((word) =>
                                  word[0] === "@" &&
                                  userIsMarkup(comment, word) ? (
                                    <S.MarkUser
                                      key={word.id}
                                      markedUser
                                      onClick={() =>
                                        redirectToUserProfile(comment, word)
                                      }
                                    >
                                      {word}
                                    </S.MarkUser>
                                  ) : (
                                    <S.MarkUser key={word?.id}>
                                      {word}
                                    </S.MarkUser>
                                  ),
                                )}
                              </S.UserComment>
                              {comment.total_reactions > 0 && (
                                <S.LikeCommentCounter
                                  onClick={() =>
                                    handleCommentsShowReactions({
                                      id: comment.id,
                                      reaction: "",
                                      perPage: comment.total_reactions,
                                    })
                                  }
                                >
                                  <>
                                    <S.UsersLike
                                      style={{
                                        marginRight: "8px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {Object.keys(comment.comment_reactions)
                                        .sort(
                                          (a, b) =>
                                            comment.comment_reactions[b] -
                                            comment.comment_reactions[a],
                                        )
                                        .slice(0, 3)
                                        .map(
                                          (commentReaction, index, array) => (
                                            <Avatar
                                              url={
                                                reactionsList[commentReaction]
                                                  .icon
                                              }
                                              size="28px"
                                              style={{
                                                zIndex: array.length - index,
                                              }}
                                            />
                                          ),
                                        )}
                                    </S.UsersLike>
                                    <S.Counter>
                                      {comment.total_reactions}
                                    </S.Counter>
                                  </>
                                </S.LikeCommentCounter>
                              )}
                              <S.Divider />
                              <Flex
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <S.LikeCommentButton
                                    icon={
                                      currentUserHasReactedToComment(comment) &&
                                      reactionsIconsList[
                                        comment.user_reaction?.reaction
                                      ]?.icon
                                    }
                                    name={
                                      currentUserHasReactedToComment(comment)
                                        ? reactionsIconsList[
                                            comment.user_reaction?.reaction
                                          ]?.text
                                        : "Curtir"
                                    }
                                    action={() =>
                                      handleCommentsReactions({
                                        idComment: comment.id,
                                        reaction: "like",
                                        isLikeButton: true,
                                        canvasUserReactionsInfo:
                                          commentReactions[comment.id] &&
                                          commentReactions[
                                            comment.id
                                          ].reactions.find(
                                            (item) =>
                                              item.user.id === currentUser.id,
                                          ),
                                        comment,
                                        commentUserId:
                                          comment.user_reaction?.user_id,
                                        idReaction:
                                          comment.user_reaction?.reaction_id,
                                        userReaction:
                                          comment.user_reaction?.reaction,
                                        idPublication: id,
                                      })
                                    }
                                  />
                                  <Reactions
                                    type="comment"
                                    idComment={comment.id}
                                    commentReactions
                                    currentUser={currentUser}
                                    commentUserId={
                                      comment.user_reaction?.user_id
                                    }
                                    idReaction={
                                      comment.user_reaction?.reaction_id
                                    }
                                    userReaction={
                                      comment.user_reaction?.reaction
                                    }
                                    idPublication={id}
                                  />
                                </div>
                                <S.TimeAnswer>
                                  <Flex spaceChildren={2}>
                                    <S.ViewAnswers
                                      onClick={() => {
                                        setIdCommentAnswer(comment.id);
                                        setNickname(comment.user.nickname);
                                      }}
                                    >
                                      Responder
                                    </S.ViewAnswers>
                                    {comment.replies.length > 0 && (
                                      <S.ViewAnswers
                                        onClick={() =>
                                          toggleCollapse(comment.id)
                                        }
                                      >
                                        |{" "}
                                        {toogleCollapsed
                                          ? "Ocultar"
                                          : "Respostas"}
                                      </S.ViewAnswers>
                                    )}
                                  </Flex>
                                </S.TimeAnswer>
                              </Flex>

                              <S.ContainerAnswers id={`comment-${comment.id}`}>
                                {comment?.replies.length > 0 && (
                                  <>
                                    <S.Divider />
                                    {comment?.replies.map((answer, index) => (
                                      <Flex
                                        key={answer.id}
                                        ml={20}
                                        flexDirection="column"
                                      >
                                        <Flex
                                          alignItems="center"
                                          justifyContent="space-between"
                                        >
                                          <Flex spaceChildren={2}>
                                            <Avatar
                                              url={answer.user?.image?.url}
                                              width={32}
                                              height={32}
                                            />
                                            <div>
                                              <S.Username>
                                                {answer.user?.name}
                                              </S.Username>
                                              <S.TimeAnswer>
                                                {handleCommentDate(answer)}
                                              </S.TimeAnswer>
                                            </div>
                                          </Flex>
                                          {answer.user?.id ===
                                            currentUser.id && (
                                            <OverlayTrigger
                                              trigger="click"
                                              rootClose
                                              placement="bottom"
                                              overlay={popoverEditComment(
                                                answer.body,
                                                answer.id,
                                              )}
                                            >
                                              <S.ContainerActionOptions>
                                                <ActionOptionsIcon />
                                              </S.ContainerActionOptions>
                                            </OverlayTrigger>
                                          )}
                                        </Flex>
                                        <S.UserComment
                                          id={`reply-${answer.id}`}
                                        >
                                          {answer?.body.split(" ").map((word) =>
                                            word[0] === "@" &&
                                            userIsMarkupInReply(
                                              comment,
                                              word,
                                            ) ? (
                                              <S.MarkUser
                                                key={word.id}
                                                markedUser
                                                onClick={() =>
                                                  redirectToUserProfile(
                                                    comment,
                                                    word,
                                                  )
                                                }
                                              >
                                                {word}
                                              </S.MarkUser>
                                            ) : (
                                              <S.MarkUser key={word.id}>
                                                {word}
                                              </S.MarkUser>
                                            ),
                                          )}
                                        </S.UserComment>
                                        {answer.reply_total_reactions > 0 && (
                                          <S.LikeCommentCounter
                                            onClick={() =>
                                              handleCommentsShowReactions({
                                                id: answer.id,
                                                reaction: "",
                                                perPage:
                                                  answer.reply_total_reactions,
                                              })
                                            }
                                          >
                                            <>
                                              <S.UsersLike
                                                style={{ marginRight: "8px" }}
                                              >
                                                {Object.keys(
                                                  answer.reply_reactions,
                                                )
                                                  .sort(
                                                    (a, b) =>
                                                      answer.reply_reactions[
                                                        b
                                                      ] -
                                                      answer.reply_reactions[a],
                                                  )
                                                  .slice(0, 3)
                                                  .map(
                                                    (
                                                      commentReactionAnswer,
                                                      index,
                                                      array,
                                                    ) => (
                                                      <Avatar
                                                        url={
                                                          reactionsList[
                                                            commentReactionAnswer
                                                          ].icon
                                                        }
                                                        size="28px"
                                                        style={{
                                                          zIndex:
                                                            array.length -
                                                            index,
                                                        }}
                                                      />
                                                    ),
                                                  )}
                                              </S.UsersLike>
                                              <S.Counter>
                                                {answer.reply_total_reactions}
                                              </S.Counter>
                                            </>
                                          </S.LikeCommentCounter>
                                        )}
                                        <S.Divider />
                                        <Flex
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                              gap: "5px",
                                            }}
                                          >
                                            <S.LikeCommentButton
                                              icon={
                                                currentUserHasReactedToCommentReply(
                                                  answer,
                                                ) &&
                                                reactionsIconsList[
                                                  answer.reply_user_reaction
                                                    ?.reaction
                                                ]?.icon
                                              }
                                              name={
                                                currentUserHasReactedToCommentReply(
                                                  answer,
                                                )
                                                  ? reactionsIconsList[
                                                      answer.reply_user_reaction
                                                        ?.reaction
                                                    ]?.text
                                                  : "Curtir"
                                              }
                                              action={() =>
                                                handleCommentsReactions({
                                                  idComment: answer.id,
                                                  reaction: "like",
                                                  isLikeButton: true,
                                                  canvasUserReactionsInfo:
                                                    commentReactions[
                                                      comment.id
                                                    ] &&
                                                    commentReactions[
                                                      comment.id
                                                    ].reactions.find(
                                                      (item) =>
                                                        item.user.id ===
                                                        currentUser.id,
                                                    ),
                                                  comment: answer,
                                                  commentUserId:
                                                    answer.reply_user_reaction
                                                      ?.user_id,
                                                  idReaction:
                                                    answer.reply_user_reaction
                                                      ?.reaction_id,
                                                  userReaction:
                                                    answer.reply_user_reaction
                                                      ?.reaction,
                                                  idPublication: id,
                                                })
                                              }
                                            />
                                            <Reactions
                                              type="commentReply"
                                              idComment={answer.id}
                                              commentReactions
                                              currentUser={currentUser}
                                              commentUserId={
                                                answer.reply_user_reaction
                                                  ?.user_id
                                              }
                                              idReaction={
                                                answer.reply_user_reaction
                                                  ?.reaction_id
                                              }
                                              userReaction={
                                                answer.reply_user_reaction
                                                  ?.reaction
                                              }
                                              idPublication={id}
                                            />
                                          </div>
                                        </Flex>
                                        {index !==
                                          comment?.replies.length - 1 && (
                                          <S.Divider />
                                        )}
                                      </Flex>
                                    ))}
                                  </>
                                )}
                              </S.ContainerAnswers>
                            </S.Comment>
                          ),
                      )}
                  </Flex>
                </InfiniteScroll>
              </S.OverflowComments>
            )}

            <S.CommentForm onSubmit={handleCreateComment}>
              <Flex width={1} p={2} flexDirection="column" alignItems="center">
                <Flex
                  spaceChildren={1}
                  justifyContent="space-evenly"
                  alignItems="center"
                  width={0.9}
                >
                  <S.ContainerInputArea>
                    <S.ComponentExitComment
                      showCommentPublication={
                        idEditCommentPublication || idCommentAnswer
                      }
                    >
                      <S.ButtonCloseComment
                        onClick={() => {
                          setNickname("");
                          setIdCommentAnswer(0);
                          setIdEditCommentPublication(0);
                          commentAreaRef.current.value = "";
                        }}
                        type="button"
                      >
                        <IconClose />
                      </S.ButtonCloseComment>
                      <S.TextExitComment>
                        {idCommentAnswer !== 0
                          ? `Respondendo @${nickname}`
                          : "Editando"}
                      </S.TextExitComment>
                    </S.ComponentExitComment>
                    <S.ComponentUsersMarked userMarked={showUserMarked}>
                      <InfiniteScroll
                        pageStart={1}
                        initialLoad={false}
                        loadMore={fetchMoreDataMarkUsers}
                        hasMore={hasMoreMarkUsers}
                        useWindow={false}
                        threshold={50}
                        loader={<MarkUsersLoadMoreSkeleton />}
                      >
                        {markUsersList?.map((user) => (
                          <S.ContainerUser
                            key={user.id}
                            onClick={() => handleSelectedUser(user.nickname)}
                          >
                            <Avatar url={user.image?.url} size={28} />
                            <S.NameUser>{user.name}</S.NameUser>
                          </S.ContainerUser>
                        ))}
                      </InfiniteScroll>
                    </S.ComponentUsersMarked>
                    <S.CommentInput
                      blockComment={
                        blockComment && commentAreaRef.current?.value.length > 0
                      }
                      onFocus={() => setKeyboardOnFocus(true)}
                      onBlur={() => setKeyboardOnFocus(false)}
                      ref={commentAreaRef}
                      onChange={handleMarkUserComment}
                      onKeyDown={handleKeyDown}
                      disabled={localStorage.getItem("roles") === "guest"}
                      placeholder={
                        localStorage.getItem("roles") === "guest"
                          ? intl.messages[
                              "publication.textPage.commentInputPlaceholderUserGuest"
                            ]
                          : intl.messages[
                              "publication.textPage.commentInputPlaceholder"
                            ]
                      }
                      showCommentPublication={
                        idEditCommentPublication || idCommentAnswer
                      }
                    />
                  </S.ContainerInputArea>
                  <div onClick={checkIfCanComment}>
                    <S.SendComment
                      isLoading={sendingComment}
                      disabled={
                        !showSend &&
                        (commentAreaRef.current?.value.trim().length <
                          min_size_comments ||
                          commentAreaRef.current?.value.trim().length === 0)
                      }
                    >
                      {!sendingComment && <SendIcon />}
                    </S.SendComment>
                  </div>
                </Flex>
                {min_size_comments > 1 && (
                  <>
                    {!showSend &&
                      (commentAreaRef.current?.value.length === 0 &&
                      keyboardOnFocus ? (
                        <S.MessageMinSizeComments>
                          A quantidade de caracteres mínima para comentar é:{" "}
                          {min_size_comments}.
                        </S.MessageMinSizeComments>
                      ) : (
                        commentAreaRef.current?.value.length > 0 &&
                        (!blockComment ? (
                          <S.MessageMinSizeComments>
                            Falta pouco! Digite mais {howManyCharsLeftToComment}{" "}
                            caracteres para comentar.
                          </S.MessageMinSizeComments>
                        ) : (
                          <S.MessageMinSizeComments blockComment={true}>
                            Você não atingiu a quantidade mínima de caracteres.
                          </S.MessageMinSizeComments>
                        ))
                      ))}
                  </>
                )}
              </Flex>
            </S.CommentForm>
          </S.ContainerComments>
        ) : (
          <S.ContainerPublicationsSideBar>
            <PageTitle
              title={intl.messages["publication.textPage.commentsTitle"]}
            />
            {publicationsFilterImage?.map(({ id, title, data }) => (
              <S.CardPublication
                onClick={() =>
                  history.push(
                    `${urls.ROUTES.DETAILS_PUBLICATION}/${title}/${id}`,
                  )
                }
              >
                <S.TittlePublication>{title}</S.TittlePublication>
                <S.ContentImage>
                  <S.ImagePublication src={data} alt="" />
                </S.ContentImage>
              </S.CardPublication>
            ))}
          </S.ContainerPublicationsSideBar>
        )}

        {openReactionsModal && (
          <ModalProvider>
            <ReactionsViewModal
              openReactionsModal={openReactionsModal}
              shouldSaveAllReactions={shouldSaveAllReactions}
              setOpenReactionsModal={setOpenReactionsModal}
              setShouldSaveAllReactions={setShouldSaveAllReactions}
              setTypeOfReaction={setTypeOfReaction}
              typeOfReaction={typeOfReaction}
              isReactionsLoading={isReactionsLoading}
              reactions={reactions}
              allReactions={allReactions}
              isDesktop
              id={selectedId}
            />
          </ModalProvider>
        )}
      </S.ContainerSideBar>
    );
  },
  (prevProps, nextProps) => {
    if (
      nextProps.isLoading ||
      nextProps.isLoadingUser ||
      nextProps.isLoadingPublication
    ) {
      return true; // Not render again
    }
    if (
      prevProps.idMentionedComment === nextProps.idMentionedComment &&
      JSON.stringify(prevProps.publications) ===
        JSON.stringify(nextProps.publications)
    ) {
      return true; // Not render again
    }
    return false; // Render again
  },
);

Publication.defaultProps = {
  isLiked: null,
  idMentionedComment: null,
};

Publication.propTypes = {
  isLiked: PropTypes.func,
  idMentionedComment: PropTypes.number,
};

export default Publication;
