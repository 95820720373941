import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getAllTeams: ["payload"],
  getAllTeamsSuccess: ["payload", "isLoading"],
  getAllTeamsFail: ["isLoading", "error"],
  getAllSectors: ["payload"],
  getAllSectorsSuccess: ["payload", "isLoading"],
  getAllSectorsFail: ["isLoading", "error"],
  getSectorUsers: ["payload"],
  getSectorUsersSuccess: ["payload", "isLoading"],
  getSectorUsersFail: ["isLoading", "error"],
  getProfile: ["id", "isLoading"],
  getProfileSuccess: ["payload", "isLoading"],
  getProfileFail: ["isLoading", "error"],
  getAllBrandFilter: ["nameTeam", "isLoading"],
  getAllBrandFilterSuccess: ["payload", "isLoading"],
  getAllBrandFilterFail: ["isLoading", "error"],
  selectTeam: ["payload"],
  cleanProfile: [],
});

const INITIAL_STATE = {
  isLoading: true,
  isLoadingProfile: true,
  allTeams: [],
  selectedProfile: {},
  error: [],
  selectedTeam: null,
  sectors: [],
  hasMoreSectors: [],
  pageSector: 1,
  isLoadingSectors: true,
  searchingForSector: "",
  users: [],
  hasMoreUsers: [],
  pageUser: 1,
  isLoadingUsers: true,
  searchingForUser: ""
};

const getAllTeams = (state) => ({ ...state });

const getAllTeamsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  selectedTeam: payload.allTeams[0],
  isLoading: false,
});

const getAllTeamsFail = (state, payload) => ({
  ...state,
  error: [...payload],
  isLoading: false,
});

const getAllSectors = (state) => ({ ...state, isLoadingSectors: true });

const getAllSectorsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  selectedTeam: payload.sectors[0],
  isLoading: false,
  isLoadingSectors: false,
});

const getAllSectorsFail = (state, payload) => ({
  ...state,
  error: [...payload],
  isLoading: false,
  isLoadingSectors: false,
});

const getSectorUsers = (state) => ({ ...state, isLoadingUsers: true });

const getSectorUsersSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoadingUsers: false,
});

const getSectorUsersFail = (state, payload) => ({
  ...state,
  error: [...payload],
  isLoadingUsers: false,
});

const getProfile = (state) => ({
  ...state,
});

const getProfileSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoadingProfile: false,
});

const getProfileFail = (state, payload) => ({
  ...state,
  error: [...payload],
  isLoadingProfile: false,
});

const getAllBrandFilter = (state) => ({
  ...state,
  isLoading: true,
});

const getAllBrandFilterSuccess = (state, payload) => ({
  ...state,
  ...payload,
  selectedTeam: payload.allTeams[0] || [],
  isLoading: false,
});

const getAllBrandFilterFail = (state, payload) => ({
  ...state,
  error: [...payload],
  isLoading: false,
});

const selectTeam = (state, { payload }) => ({
  ...state,
  selectedTeam: payload,
});

const cleanProfile = (state) => ({
  ...state,
  isLoadingProfile: true,
  selectedProfile: {},
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_ALL_TEAMS]: getAllTeams,
  [Types.GET_ALL_TEAMS_SUCCESS]: getAllTeamsSuccess,
  [Types.GET_ALL_TEAMS_FAIL]: getAllTeamsFail,
  [Types.GET_ALL_SECTORS]: getAllSectors,
  [Types.GET_ALL_SECTORS_SUCCESS]: getAllSectorsSuccess,
  [Types.GET_ALL_SECTORS_FAIL]: getAllSectorsFail,

  [Types.GET_SECTOR_USERS]: getSectorUsers,
  [Types.GET_SECTOR_USERS_SUCCESS]: getSectorUsersSuccess,
  [Types.GET_SECTOR_USERS_FAIL]: getSectorUsersFail,

  [Types.GET_PROFILE]: getProfile,
  [Types.GET_PROFILE_SUCCESS]: getProfileSuccess,
  [Types.GET_PROFILE_FAIL]: getProfileFail,
  [Types.SELECT_TEAM]: selectTeam,
  [Types.CLEAN_PROFILE]: cleanProfile,
  [Types.GET_ALL_BRAND_FILTER]: getAllBrandFilter,
  [Types.GET_ALL_BRAND_FILTER_SUCCESS]: getAllBrandFilterSuccess,
  [Types.GET_ALL_BRAND_FILTER_FAIL]: getAllBrandFilterFail,
});
