import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getCibraMenu: ["id"],
  getCibraMenuSuccess: ["payload", "isLoading"],
  getCibraMenuFail: ["isLoading", "error"],

  getCibraDetails: ["id", "idDetails", "kindMenu"],
  getCibraDetailsSuccess: ["payload", "isLoading"],
  getCibraDetailsFail: ["isLoading", "error"],
});

const INITIAL_STATE = {
  error: [],
  isLoading: true,
  subMenuList: [],
  listMenuCibra: [],
  detailsPageTeamCibra: {},
};

const getCibraMenu = (state) => ({ ...state, isLoading: true });

const getCibraMenuSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const getCibraMenuFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false,
});

const getCibraDetails = (state) => ({ ...state, isLoading: true });

const getCibraDetailsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const getCibraDetailsFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_CIBRA_MENU]: getCibraMenu,
  [Types.GET_CIBRA_MENU_SUCCESS]: getCibraMenuSuccess,
  [Types.GET_CIBRA_MENU_FAIL]: getCibraMenuFail,

  [Types.GET_CIBRA_DETAILS]: getCibraDetails,
  [Types.GET_CIBRA_DETAILS_SUCCESS]: getCibraDetailsSuccess,
  [Types.GET_CIBRA_DETAILS_FAIL]: getCibraDetailsFail,
});
