import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getFiles: ["payload"],
  getFilesSuccess: ["payload", "isLoading"],
  getFilesFail: ["isLoading", "error"],

  getFilesFolder: ["id"],
  getFilesFolderSuccess: ["payload", "isLoading"],
  getFilesFolderFail: ["isLoading", "error"],

  searchFiles: ["payload"],
  searchFilesSuccess: ["payload"],
  searchFilesFailure: ["payload"],

  clean: [],
});

const INITIAL_STATE = {
  folders: [],
  repository: {},
  resultSearch: {},
  isLoading: false,
  searchingFor: "",
  error: [],
  hasMore: true,
  page: 1,
};

const getFiles = (state) => ({
  ...state,
  isLoading: true,
});
const getFilesSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getFilesFail = (state) => ({
  ...state,
  isLoading: false,
});

const getFilesFolder = (id) => ({ ...id, isLoading: true });
const getFilesFolderSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getFilesFolderFail = (state) => ({
  ...state,
  isLoading: false,
});

const searchFiles = (state, { payload }) => ({
  ...state,
  isLoading: true,
  searchingFor: payload,
});

const searchFilesSuccess = (state, { files }) => ({
  ...state,
  isLoading: false,
  repository: {
    repository_attachment: files,
  },
});

const searchFilesFailure = (state, { payload }) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const clean = () => ({
  ...INITIAL_STATE,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_FILES]: getFiles,
  [Types.GET_FILES_SUCCESS]: getFilesSuccess,
  [Types.GET_FILES_FAIL]: getFilesFail,

  [Types.GET_FILES_FOLDER]: getFilesFolder,
  [Types.GET_FILES_FOLDER_SUCCESS]: getFilesFolderSuccess,
  [Types.GET_FILES_FOLDER_FAIL]: getFilesFolderFail,

  [Types.SEARCH_FILES]: searchFiles,
  [Types.SEARCH_FILES_SUCCESS]: searchFilesSuccess,
  [Types.SEARCH_FILES_FAILURE]: searchFilesFailure,

  [Types.CLEAN]: clean,
});
