import styled from "styled-components";
import css from "@styled-system/css";
import theme from "styles/theme";

export const ContainerFormSignUp = styled("div")(
  css({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  }),
);

export const ContainerRegister = styled("div")(
  css({
    width: "100%",
    // height: "75%",
    display: "flex",
    padding: "0px 80px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",

    ">:nth-child(n)": { width: "100%" },

    ">:nth-child(n+2)": { marginTop: "20px" },

    "@media (max-width: 767px)": {
      padding: "0px 40px",
    },
  }),
);

export const ContainerButtonAndProgress = styled("div")(
  css({
    width: "100%",
    // height: "25%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop: "35px",
  }),
);

export const MonitoringProgress = styled("ul")(
  css({
    width: "200px",
    height: "20px",

    display: "flex",
    marginTop: "30px",
    alignItems: "center",
    justifyContent: "center",

    ">:nth-child(n)": { margin: "0px 9px" },
    ">:nth-child(1)": { opacity: 0.7 },
    ">:nth-child(2)": { opacity: 0.8 },
    ">:nth-child(3)": { opacity: 0.9 },
    ">:nth-child(4)": { opacity: 1 },
  }),
);

export const EclipsePage = styled("li")(
  css({
    width: "20px",
    height: "20px",
    listStyle: "none",
    cursor: "pointer",
    borderRadius: "100%",
    backgroundColor: "grayMedium",
  }),

  ({ progressForm, clientName }) =>
    progressForm &&
    css({
      backgroundColor: clientName === "Vedacit" ? "#D28B00" : "secondaryColor",
    }),
  ({ validateChecked }) =>
    !validateChecked &&
    css({
      cursor: "not-allowed",
    }),
);

export const ContentSelectCity = styled("div")(
  css({
    width: "100%",
    display: "flex",
    position: "relative",
    flexDirection: "column",
  }),
);

export const ListCities = styled("ul")(
  css({
    margin: 0,
    position: "absolute",
    bottom: 0,
    zIndex: 150,
    width: "100%",
    overflow: "auto",
    maxHeight: "150px",
    visibility: "hidden",
    marginBottom: "45px",
    listStyleType: "none",

    padding: "14px 0px",
    flexDirection: "column",

    borderRadius: "8px",
    backgroundColor: "paleGrey",
    boxShadow: `0 1px 5px 0 ${theme.colors.grayDark}`,
  }),
  ({ cityValidation }) =>
    cityValidation &&
    css({
      visibility: "visible",
    }),
);

export const ContentCities = styled("li")(
  css({
    margin: 0,
    display: "flex",
    cursor: "pointer",
    padding: "5px 14px",
    position: "relative",
    alignItems: "center",

    "&:hover": {
      backgroundColor: "secondaryColorLight",
    },
  }),
);

export const Terms = styled("div")(
  css({
    "& label": {
      marginTop: 2,
    },

    "& span": {
      fontSize: 16,
    },

    "& .link": {
      color: "secondaryColorLight",
      textDecoration: "underline",
    },
  }),
);
