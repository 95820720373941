import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  userAuthRequest: ["payload"],
  userAuthSuccess: ["payload", "isLoading"],
  userAuthFail: ["isLoading", "error"],

  forgotThePassword: ["payload"],
  forgotThePasswordSuccess: ["payload", "isLoading"],
  forgotThePasswordFail: ["isLoading", "error"],

  postCreateUserRequest: ["payload"],
  postCreateUserSuccess: ["payload", "isLoading"],
  postCreateUserFail: ["isLoading", "error"],

  getExperienceTimes: ["payload"],
  getExperienceTimesSuccess: ["payload", "isLoading"],
  getExperienceTimesFail: ["isLoading", "error"],

  getTypeOfWorks: ["payload"],
  getTypeOfWorksSuccess: ["payload", "isLoading"],
  getTypeOfWorksFail: ["isLoading", "error"],

  getUserInterests: ["payload"],
  getUserInterestsSuccess: ["payload", "isLoading"],
  getUserInterestsFail: ["isLoading", "error"],

  getUserTrainings: ["payload"],
  getUserTrainingsSuccess: ["payload", "isLoading"],
  getUserTrainingsFail: ["isLoading", "error"],

  getStateList: ["payload"],
  getStateListSuccess: ["payload", "isLoading"],
  getStateListFail: ["isLoading", "error"],

  getCitiesList: ["payload"],
  getCitiesListSuccess: ["payload", "isLoading"],
  getCitiesListFail: ["isLoading", "error"],

  getActivitiesList: ["payload"],
  getActivitiesListSuccess: ["payload", "isLoading"],
  getActivitiesListFail: ["isLoading", "error"],

  getTermOfUse: ["payload"],
  getTermOfUseSuccess: ["payload", "isLoading", "terms_url"],
  getTermOfUseFail: ["isLoading", "error"],

  getLgpd: ["payload"],
  getLgpdSuccess: ["payload", "isLoading", "terms_url"],
  getLgpdFail: ["isLoading", "error"],

  getTermsOfUse: ["payload", "showTermsOfUse"],
  getTermsOfUseSuccess: ["payload", "isLoading", "showTermsOfUse"],
  getTermsOfUseFail: ["isLoading", "error", "showTermsOfUse"],

  validateTeamsToken: ["payload"],
});
const INITIAL_STATE = {
  email: "",
  password: "",
  isLoading: false,
  listTimeExperience: [],
  listTypesWork: [],
  listUserInterests: [],
  listUserTrainings: [],
  listState: [],
  listCities: [],
  listActivities: [],
};

const userAuthRequest = () => ({ ...INITIAL_STATE, isLoading: true });
const userAuthSuccess = (state, payload) => ({
  ...state,
  payload,
  isLoading: false,
});
const userAuthFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const forgotThePassword = () => ({ ...INITIAL_STATE, isLoading: true });
const forgotThePasswordSuccess = (state, payload) => ({
  ...state,
  payload,
  isLoading: false,
});
const forgotThePasswordFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const postCreateUserRequest = (payload) => ({
  ...payload,
  isLoading: true,
});
const postCreateUserSuccess = (state) => ({
  ...state,
  isLoading: false,
});
const postCreateUserFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getExperienceTimes = (state) => ({ ...state });

const getExperienceTimesSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getExperienceTimesFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getTypeOfWorks = (state) => ({ ...state });

const getTypeOfWorksSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getTypeOfWorksFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getUserInterests = (state) => ({ ...state });

const getUserInterestsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getUserInterestsFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getUserTrainings = (state) => ({ ...state });

const getUserTrainingsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getUserTrainingsFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getStateList = (state) => ({ ...state });

const getStateListSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getStateListFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getCitiesList = (state) => ({ ...state });

const getCitiesListSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getCitiesListFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getActivitiesList = (state) => ({ ...state });

const getActivitiesListSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getActivitiesListFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getTermOfUse = (state) => ({
  ...state,
  isLoading: true,
});

const getTermOfUseSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  ...payload,
});

const getTermOfUseFail = (state, error) => ({
  ...state,
  isLoading: false,
  showTermsOfUse: false,
  ...error,
});

const getLgpd = (state) => ({
  ...state,
  isLoading: true,
});

const getLgpdSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  ...payload,
});

const getLgpdFail = (state, error) => ({
  ...state,
  isLoading: false,
  showTermsOfUse: false,
  ...error,
});

const getTermsOfUse = (state) => ({
  ...state,
  isLoading: true,
  showTermsOfUse: true,
});

const getTermsOfUseSuccess = (state) => ({
  ...state,
  isLoading: false,
  showTermsOfUse: false,
});
const getTermsOfUseFail = (state, error) => ({
  ...state,
  isLoading: false,
  showTermsOfUse: false,
  ...error,
});

const validateTeamsToken = (state) => ({ ...state });

export default createReducer(INITIAL_STATE, {
  [Types.USER_AUTH_REQUEST]: userAuthRequest,
  [Types.USER_AUTH_SUCCESS]: userAuthSuccess,
  [Types.USER_AUTH_FAIL]: userAuthFail,

  [Types.FORGOT_THE_PASSWORD]: forgotThePassword,
  [Types.FORGOT_THE_PASSWORD_SUCCESS]: forgotThePasswordSuccess,
  [Types.FORGOT_THE_PASSWORD_FAIL]: forgotThePasswordFail,

  [Types.POST_CREATE_USER_REQUEST]: postCreateUserRequest,
  [Types.POST_CREATE_USER_SUCCESS]: postCreateUserSuccess,
  [Types.POST_CREATE_USER_FAIL]: postCreateUserFail,

  [Types.GET_EXPERIENCE_TIMES]: getExperienceTimes,
  [Types.GET_EXPERIENCE_TIMES_SUCCESS]: getExperienceTimesSuccess,
  [Types.GET_EXPERIENCE_TIMES_FAIL]: getExperienceTimesFail,

  [Types.GET_TYPE_OF_WORKS]: getTypeOfWorks,
  [Types.GET_TYPE_OF_WORKS_SUCCESS]: getTypeOfWorksSuccess,
  [Types.GET_TYPE_OF_WORKS_FAIL]: getTypeOfWorksFail,

  [Types.GET_USER_INTERESTS]: getUserInterests,
  [Types.GET_USER_INTERESTS_SUCCESS]: getUserInterestsSuccess,
  [Types.GET_USER_INTERESTS_FAIL]: getUserInterestsFail,

  [Types.GET_USER_TRAININGS]: getUserTrainings,
  [Types.GET_USER_TRAININGS_SUCCESS]: getUserTrainingsSuccess,
  [Types.GET_USER_TRAININGS_FAIL]: getUserTrainingsFail,

  [Types.GET_STATE_LIST]: getStateList,
  [Types.GET_STATE_LIST_SUCCESS]: getStateListSuccess,
  [Types.GET_STATE_LIST_FAIL]: getStateListFail,

  [Types.GET_CITIES_LIST]: getCitiesList,
  [Types.GET_CITIES_LIST_SUCCESS]: getCitiesListSuccess,
  [Types.GET_CITIES_LIST_FAIL]: getCitiesListFail,

  [Types.GET_ACTIVITIES_LIST]: getActivitiesList,
  [Types.GET_ACTIVITIES_LIST_SUCCESS]: getActivitiesListSuccess,
  [Types.GET_ACTIVITIES_LIST_FAIL]: getActivitiesListFail,

  [Types.GET_TERM_OF_USE]: getTermOfUse,
  [Types.GET_TERM_OF_USE_SUCCESS]: getTermOfUseSuccess,
  [Types.GET_TERM_OF_USE_FAIL]: getTermOfUseFail,

  [Types.GET_LGPD]: getLgpd,
  [Types.GET_LGPD_SUCCESS]: getLgpdSuccess,
  [Types.GET_LGPD_FAIL]: getLgpdFail,

  [Types.GET_TERMS_OF_USE]: getTermsOfUse,
  [Types.GET_TERMS_OF_USE_SUCCESS]: getTermsOfUseSuccess,
  [Types.GET_TERMS_OF_USE_FAIL]: getTermsOfUseFail,

  [Types.VALIDATE_TEAMS_TOKEN]: validateTeamsToken,
});
