import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ModalProvider } from "styled-react-modal";
import moment from "moment";

import { Switch } from "components/core/Form/Form";
import { Creators as UserActions } from "store/ducks/user";

import { history } from "helpers/sharedHelpers";

import { ReactComponent as IconTag } from "assets/iconTag.svg";
import { ReactComponent as IconClose } from "assets/img/icons/iconClose.svg";

import ImageAngry from "assets/img/angry.png";
import ImageSad from "assets/img/sad.png";
import ImageNormal from "assets/img/normal.png";
import ImageHappy from "assets/img/happy.png";
import ImageVeryHappy from "assets/img/veryHappy.png";

import Flex from "../Grid/Flex";
import Button from "../Button/Button";
import urls from "utils/constants/urls";

import * as AlertModalStyle from "./AlertModalStyle";
import * as S from "./SurveyMoodModalStyle";

const SurveyMoodModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    moodSurveyCurrent,
    user,
    moodSurveySuccessfullyAnswered,
  } = useSelector((state) => state.user);

  const [isOpen, setIsOpen] = useState(true);
  const [replyAsAnonymous, setReplyAsAnonymous] = useState(false);
  const [activeResponse, setActiveResponse] = useState("");

  const toggleModal = () => {
    sessionStorage.setItem("@Noz-alreadyShow-moodSurvey", true);
    dispatchClearMoodSurvey();
    setIsOpen(!isOpen);
  };

  const dispatchClearMoodSurvey = useCallback(
    () => dispatch(UserActions.clearMoodSurvey()),
    [dispatch],
  );

  const dipatchVisualizationMoodSurveyCurrent = useCallback(
    () =>
      dispatch(
        UserActions.visualizationMoodSurveyCurrent({
          idMoodSurvey: moodSurveyCurrent?.id,
          idUser: user?.id,
        }),
      ),
    [dispatch],
  );

  const dipatchPostMoodSurveyAnswer = useCallback(
    (activeResponse, replyAsAnonymous, idMoodSurvey) =>
      dispatch(
        UserActions.postMoodSurveyAnswer({
          idMoodSurvey,
          answerKind: activeResponse,
          anonymous: replyAsAnonymous,
        }),
      ),
    [dispatch],
  );

  const dipatchPostMoodSurveyAnswerNotParticipate = useCallback(
    (replyAsAnonymous, idMoodSurvey) =>
      dispatch(
        UserActions.postMoodSurveyAnswer({
          idMoodSurvey: idMoodSurvey,
          answerKind: "not_participate",
          anonymous: replyAsAnonymous,
        }),
      ),
    [dispatch],
  );

  useEffect(() => {
    !!moodSurveyCurrent?.id &&
      !!user?.id &&
      dipatchVisualizationMoodSurveyCurrent();
  }, []);

  useEffect(() => {
    if (moodSurveySuccessfullyAnswered) {
      setTimeout(() => {
        toggleModal();
        history.push(urls.ROUTES.MURAL);
      }, 3000);
    }
  }, [moodSurveySuccessfullyAnswered]);

  return (
    <ModalProvider>
      {moodSurveySuccessfullyAnswered ? (
        <AlertModalStyle.StyledModal
          data-testid="modal-component"
          isOpen={isOpen}
          type={false}
          style={{ width: "26rem" }}
        >
          <AlertModalStyle.ContainerModal>
            <S.FistLineContainer>
              <S.IconAndTitleContainer>
                <IconTag />
                <span>{intl.messages["modal.textPage.quickSearch"]}</span>
              </S.IconAndTitleContainer>
              <IconClose onClick={toggleModal} />
            </S.FistLineContainer>

            <S.TitleModal>
              {activeResponse === ""
                ? intl.messages["modal.textPage.weWaitBextTime"]
                : intl.messages["modal.textPage.thanksForParticipating"]}
            </S.TitleModal>

            <S.SubtitleModal>
              {intl.messages["modal.textPage.youWillBeRedirectedToTheWall"]}
            </S.SubtitleModal>
          </AlertModalStyle.ContainerModal>
        </AlertModalStyle.StyledModal>
      ) : (
        <AlertModalStyle.StyledModal
          data-testid="modal-component"
          isOpen={isOpen}
          type={false}
          style={{ width: "26rem" }}
        >
          <AlertModalStyle.ContainerModal>
            <S.FistLineContainer>
              <S.IconAndTitleContainer>
                <IconTag />
                <span>{intl.messages["modal.textPage.quickSearch"]}</span>
              </S.IconAndTitleContainer>
              <IconClose onClick={toggleModal} />
            </S.FistLineContainer>

            <S.TitleModal>
              {intl.messages["modal.textPage.quickSearchQuestion"]}
            </S.TitleModal>

            <S.OptionSurveysContainer>
              <S.OptionSurvey
                isActiveByFilter={activeResponse === "angry"}
                onClick={() => setActiveResponse("angry")}
                className={activeResponse === "angry" && "isActiveByFilter"}
              >
                <img src={ImageAngry} />
              </S.OptionSurvey>
              <S.OptionSurvey
                isActiveByFilter={activeResponse === "sad"}
                onClick={() => setActiveResponse("sad")}
                className={activeResponse === "sad" && "isActiveByFilter"}
              >
                <img src={ImageSad} />
              </S.OptionSurvey>
              <S.OptionSurvey
                isActiveByFilter={activeResponse === "normal"}
                onClick={() => setActiveResponse("normal")}
                className={activeResponse === "normal" && "isActiveByFilter"}
              >
                <img src={ImageNormal} />
              </S.OptionSurvey>
              <S.OptionSurvey
                isActiveByFilter={activeResponse === "happy"}
                onClick={() => setActiveResponse("happy")}
                className={activeResponse === "happy" && "isActiveByFilter"}
              >
                <img src={ImageHappy} />
              </S.OptionSurvey>
              <S.OptionSurvey
                isActiveByFilter={activeResponse === "very_happy"}
                onClick={() => setActiveResponse("very_happy")}
                className={activeResponse === "very_happy" && "isActiveByFilter"}
              >
                <img src={ImageVeryHappy} />
              </S.OptionSurvey>
            </S.OptionSurveysContainer>

            <S.AnonymousContainer>
              <span>{intl.messages["modal.textPage.replyAsAnonymous"]}</span>
              <Switch
                active={replyAsAnonymous}
                onChange={() => setReplyAsAnonymous(!replyAsAnonymous)}
                isSmall
              />
            </S.AnonymousContainer>

            <Flex spaceChildren={4}>
              <Button
                name={intl.messages["modal.textPage.ratherNotAnswer"]}
                variant={["transparent"]}
                onClick={() => {
                  dipatchPostMoodSurveyAnswerNotParticipate(
                    replyAsAnonymous,
                    moodSurveyCurrent?.id,
                  );
                }}
                style={{
                  color: "#808080",
                  textTransform: "none",
                  fontWeight: "500",
                  padding: "0.2rem 1rem",
                }}
              />

              <Button
                name={intl.messages["modal.textPage.sendReply"]}
                onClick={
                  activeResponse !== ""
                    ? () =>
                        dipatchPostMoodSurveyAnswer(
                          activeResponse,
                          replyAsAnonymous,
                          moodSurveyCurrent?.id,
                        )
                    : () => {}
                }
                style={{
                  textTransform: "none",
                  fontWeight: "500",
                  color: activeResponse === "" ? "white" : "",
                  backgroundColor: activeResponse === "" ? "#808080" : "",
                  cursor: activeResponse === "" ? "default" : "",
                }}
              />
            </Flex>
          </AlertModalStyle.ContainerModal>
        </AlertModalStyle.StyledModal>
      )}
    </ModalProvider>
  );
};

export default SurveyMoodModal;
