import { takeLatest, put } from "redux-saga/effects";

import { Types } from "../ducks/modalAlert";

function* setAlerts({ messages }) {
  try {
    const alerts = messages;
    yield put({ type: Types.SET_ALERTS_SUCCESS, alerts });
  } catch (error) {
    yield put({ type: Types.SET_ALERTS_FAIL, error });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.SET_ALERTS_REQUEST, setAlerts);
}
