import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getBirthdays: ["nameBrand"],
  getBirthdaysSuccess: ["payload", "isLoading"],
  getBirthdaysFail: ["isLoading", "error"],
  getBirthdaysV2: ["payload"],
  getBirthdaysV2Success: ["payload", "isLoading"],
  getBirthdaysV2Fail: ["isLoading", "error"],
  getBirthdaysUsers: ["payload"],
  getBirthdaysUsersSuccess: ["payload", "isLoading"],
  getBirthdaysUsersFail: ["isLoading", "error"],
  selectMonth: ["payload"],
  birthdaysNow: ["payload"],
});

const INITIAL_STATE = {
  isLoading: true,
  isLoadingBirthdays: true,
  birthdays: [],
  birthdaysNow: [],
  error: [],
  selectedMonth: null,
  selectedMonthV2: null,
  birthdaysV2: [],
  birthdaysUsers: [],
  hasMoreUsers: [],
  pageUsers: 1,
  isLoadingUsers: true,
  searchingForUser: "",
  bdaysTodayCount: 0,
  bdaysTodayUsers: [],
  hasMoreBdaysTodayUsers:[],
  pageBdaysTodayUsers: 1,
  dateBdayToday: null,
};

const getBirthdays = (state) => ({ ...state, isLoading: true });

const getBirthdaysSuccess = (state, payload) => ({
  ...state,
  ...payload,
  selectedMonth: payload.birthdays[new Date().getMonth()],
  isLoading: false,
});

const getBirthdaysFail = (state, payload) => ({
  ...state,
  error: [...payload],
  isLoading: false,
});

const getBirthdaysV2 = (state) => ({ ...state });

const getBirthdaysV2Success = (state, payload) => ({
  ...state,
  ...payload,
  selectedMonthV2: payload.birthdaysV2[new Date().getMonth()],
  isLoading: false,
});

const getBirthdaysV2Fail = (state, payload) => ({
  ...state,
  error: [...payload],
  isLoading: false,
});

const getBirthdaysUsers = (state) => ({ ...state, isLoadingUsers: true });

const getBirthdaysUsersSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoadingUsers: false,
});

const getBirthdaysUsersFail = (state, payload) => ({
  ...state,
  error: [...payload],
  isLoadingUsers: false,
});

const selectMonth = (state, { payload }) => ({
  ...state,
  selectedMonth: payload,
  selectedMonthV2: payload,
});

const birthdaysNow = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_BIRTHDAYS]: getBirthdays,
  [Types.GET_BIRTHDAYS_SUCCESS]: getBirthdaysSuccess,
  [Types.GET_BIRTHDAYS_FAIL]: getBirthdaysFail,
  [Types.GET_BIRTHDAYS_V2]: getBirthdaysV2,
  [Types.GET_BIRTHDAYS_V2_SUCCESS]: getBirthdaysV2Success,
  [Types.GET_BIRTHDAYS_V2_FAIL]: getBirthdaysV2Fail,
  [Types.GET_BIRTHDAYS_USERS]: getBirthdaysUsers,
  [Types.GET_BIRTHDAYS_USERS_SUCCESS]: getBirthdaysUsersSuccess,
  [Types.GET_BIRTHDAYS_USERS_FAIL]: getBirthdaysUsersFail,
  [Types.SELECT_MONTH]: selectMonth,
  [Types.BIRTHDAYS_NOW]: birthdaysNow,
});
