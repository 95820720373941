import { takeLatest, put, select } from "redux-saga/effects";
import { GET } from "utils/constants/verbs";
import { GET_ALL_BIRTHDAYS, GET_BIRTHDAYS_USERS } from "utils/constants/endpoints";
import { requestAPI } from "helpers/requestHelpers";
import moment from "moment";
import { Types } from "../ducks/birthdays";
import { API_V2 } from "../../utils/API/API";

const getBirthdaysUsersActual = (state) => state.birthdays.birthdaysUsers;
const getBdayTodaysUsersActual = (state) => state.birthdays.bdaysTodayUsers;

const displayMonthOfYear = (month) => {
  switch (month) {
    case 0:
      return "Janeiro";
    case 1:
      return "Fevereiro";
    case 2:
      return "Março";
    case 3:
      return "Abril";
    case 4:
      return "Maio";
    case 5:
      return "Junho";
    case 6:
      return "Julho";
    case 7:
      return "Agosto";
    case 8:
      return "Setembro";
    case 9:
      return "Outubro";
    case 10:
      return "Novembro";
    case 11:
      return "Dezembro";
    default:
      return null;
  }
};

const sortBdays = (month) => {
  const orderedBdays = month.sort(
    (current, next) =>
      moment(next.datebirthday).format("DD") -
      moment(current.datebirthday).format("DD"),
  );
  const reversedBdays = orderedBdays.reverse();
  return reversedBdays;
};

function* getBirthdays({ nameBrand }) {
  try {
    const response = yield requestAPI({
      verb: GET,
      endPoint: nameBrand
        ? `${GET_ALL_BIRTHDAYS}?brand_name=${nameBrand}`
        : GET_ALL_BIRTHDAYS,
    });

    const birthdays = yield Object.values(response).map((month, i) => ({
      id: i,
      name: displayMonthOfYear(i),
      members: sortBdays(month),
    }));

    yield put({
      birthdays,
      type: Types.GET_BIRTHDAYS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_BIRTHDAYS_FAIL,
      ...error.response.data,
    });
  }
}

function* getBirthdaysV2(){
  try {
    const {data : {months, bday_today}} = yield API_V2({
      url: `${GET_ALL_BIRTHDAYS}`,
      method: "GET",
    });

    const birthdaysV2 = yield months.map((month, i) => ({
      id: month?.month,
      name: displayMonthOfYear(i),
      members: sortBdays(month?.users),
      totalUsers: month?.users_total
    }));

    yield put({
      birthdaysV2,
      bdaysTodayCount: bday_today,
      type: Types.GET_BIRTHDAYS_V2_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_BIRTHDAYS_V2_FAIL,
      ...error.response.data,
    });
  }
}

function* getBirthdaysUsers({payload}){
  const { id, page, bdayToday, search } = payload;
  try {
    
    const {data : {pagination, users}} = yield API_V2({
      url: bdayToday ? `${GET_BIRTHDAYS_USERS}?bday_today=${moment(bdayToday).format('YYYY-MM-DD')}&page=${page}`
        : search ? `${GET_BIRTHDAYS_USERS}?month=${id}&page=${page}&search=${search}`
          : `${GET_BIRTHDAYS_USERS}?month=${id}&page=${page}`,
      method: "GET",
    });

    const actualList =
      // eslint-disable-next-line no-nested-ternary
      page === 1
        ? []
        : bdayToday ? yield select(getBdayTodaysUsersActual) : yield select(getBirthdaysUsersActual);
    const newList = actualList.concat(users);
    const hasMore = users.length === 10;

    if(bdayToday){
      yield put({
        bdaysTodayUsers: newList,
        hasMoreBdaysTodayUsers: hasMore,
        pageBdaysTodayUsers: page,
        bdaysTodayCount: pagination?.total_count,
        isLoading: false,
        searchingForUser: search,
        dateBdayToday: moment(bdayToday).format('YYYY-MM-DD'),
        type: Types.GET_BIRTHDAYS_USERS_SUCCESS,
      });
    }else{
      yield put({
        birthdaysUsers: newList,
        hasMoreUsers: hasMore,
        pageUsers: page,
        searchingForUser: search,
        type: Types.GET_BIRTHDAYS_USERS_SUCCESS
      });
    }
    
  } catch (error) {
    yield put({
      type: Types.GET_BIRTHDAYS_USERS_FAIL,
      ...error?.response?.data,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_BIRTHDAYS, getBirthdays);
  yield takeLatest(Types.GET_BIRTHDAYS_V2, getBirthdaysV2);
  yield takeLatest(Types.GET_BIRTHDAYS_USERS, getBirthdaysUsers);
}
