import React, { useEffect, useCallback } from "react";
import { style } from "style";
import { useDispatch, useSelector } from "react-redux";
import { Creators as AppsActions } from "store/ducks/apps";
import { Creators as ToolsActions } from "store/ducks/toolsExternalLinks";

import Portal from "templates/Portal";
import Flex from "components/core/Grid/Flex";
import AppsComponent from "components/presentation/Apps";
import ToolsExternalLinks from "components/presentation/ToolsExternalLinks";

const Apps = () => {
  const dispatch = useDispatch();
  const { apps, isLoading } = useSelector((state) => state.apps);
  const { toolsExternalLinksAll, isLoadingTools } = useSelector(
    (state) => state.toolsExternalLinks,
  );
  const getApps = useCallback(() => dispatch(AppsActions.getApps()), [
    dispatch,
  ]);
  const getToolsExternalLinks = useCallback(
    () => dispatch(ToolsActions.getToolsExternalLinks()),
    [dispatch],
  );

  useEffect(() => {
    if (style.names.nameApp === "youseed") {
      getApps();
    } else {
      getToolsExternalLinks();
    }
  }, [getApps, getToolsExternalLinks]);

  return (
    <Portal>
      <Flex flexDirection="column">
        {style.names.nameApp === "youseed" ? (
          <AppsComponent apps={apps} isLoading={isLoading} />
        ) : (
          <ToolsExternalLinks
            allToolsExternalLinks={toolsExternalLinksAll.external_link_sections}
            isLoadingTools={isLoadingTools}
          />
        )}
      </Flex>
    </Portal>
  );
};

export default Apps;
