/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import Flex from "components/core/Grid/Flex";
import { PageTitle } from "components/core/Typography/Typography";
import { Creators as CalendarEventsActions } from "store/ducks/calendar";

import { ReactComponent as NoNextEvents } from "assets/newNoNextEvents.svg";
import { ReactComponent as ArrowLeftIcon } from "assets/img/icons/arrow-left.svg";

import { smartFormatterDate } from "helpers/sharedHelpers";
import { SkeletonEventList } from "./EventListSkeleton";

import { style } from "../../../../style";
import { MsgDoesNotExist, Overflow } from "../SidebarStyle";
import {
  DateEvent,
  Event,
  HourEvent,
  DescriptionText,
  ContainerListEvent,
  ContainerBodyList,
  SideContainerLeft,
  FooterEffectScroll,
  ContainerNoNextEvents,
} from "./EventsListStyle";

const clientsWithEventsV2 = ["Time Cibra"];

const EventList = ({ dateClick }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    events,
    nextEvents,
    nextEventsV2,
    hasMoreNextEventsV2,
    pageNextEventsV2,
    isLoadingNextEventsV2,
  } = useSelector(({ calendar }) => calendar);
  const eventsToday = events?.filter((event) => event.date_event === dateClick);
  const eventsList =
    eventsToday && dateClick
      ? eventsToday
      : clientsWithEventsV2.includes(style.names.nameTeam)
      ? nextEventsV2
      : nextEvents;

  const [overflowHeight, setOverflowHeight] = useState();
  const [overflowHeightEvents, setOverflowHeightEvents] = useState();

  const dispatchGetNextEvents = useCallback(
    () => dispatch(CalendarEventsActions.getNextEvents()),
    [dispatch],
  );

  const dispatchGetNextEventsV2 = useCallback(
    () => dispatch(CalendarEventsActions.getNextEventsV2({ page: 1 })),
    [dispatch],
  );

  useEffect(() => {
    setOverflowHeightEvents(
      document.body.getBoundingClientRect().height / 2 - 183,
    );
  }, []);
  useEffect(() => {
    setOverflowHeight(document.body.getBoundingClientRect().height / 3 - 90);
  }, []);

  useEffect(() => {
    !dateClick && clientsWithEventsV2.includes(style.names.nameTeam)
      ? dispatchGetNextEventsV2()
      : dispatchGetNextEvents();
  }, [dispatchGetNextEventsV2, dateClick]);

  const fetchMoreNextEventsV2 = () => {
    hasMoreNextEventsV2 &&
      !isLoadingNextEventsV2 &&
      dispatch(
        CalendarEventsActions.getNextEventsV2({
          page: pageNextEventsV2 + 1,
        }),
      );
  };

  return (
    <Flex id="sectionEvents" flex={3} flexDirection="column" width="100%">
      <PageTitle
        title={
          intl.messages[
            `${
              dateClick
                ? "calendar.textPage.events"
                : "calendar.textPage.nextEvent"
            }`
          ]
        }
        fontSize={3}
      />
      <Overflow
        flexDirection="column"
        spaceChildren={3}
        height={`${(dateClick && overflowHeightEvents) || overflowHeight}px`}
      >
        {(!!dateClick || !clientsWithEventsV2.includes(style.names.nameTeam)) &&
        eventsList?.length > 0 ? (
          eventsList.map((event) => {
            const {
              id,
              title,
              kind,
              hour_event,
              description,
              hour_event_end,
              date_event,
              local,
              link,
            } = event;

            return (
              <ContainerListEvent
                key={id}
                onClick={() =>
                  document

                    .getElementById(`${id}Event`)

                    .classList.toggle("uncollapsed")
                }
                id={`${id}Event`}
                collapsed={kind === "common" || (kind === "holiday" && !!description)}
              >
                <ContainerBodyList>
                  <SideContainerLeft />

                  <Flex flexDirection="column">
                    <Flex>
                      <DateEvent>{`${smartFormatterDate(
                        date_event,

                        "ddd",
                      )}, ${smartFormatterDate(date_event, "LL")}`}</DateEvent>
                    </Flex>

                    <Event>{title}</Event>

                    {kind === "common" && (
                      <>
                        <HourEvent>
                          {`${smartFormatterDate(
                            hour_event,
                            "LT",
                          )}h às ${smartFormatterDate(hour_event_end, "LT")}h`}
                        </HourEvent>
                      </>
                    )}
                  </Flex>

                  {(kind === "common" || (kind === "holiday" && !!description)) && (
                    <ArrowLeftIcon className="collapse-trigger" />
                  )}
                </ContainerBodyList>

                {(kind === "common" || kind === "holiday") && local && (
                  <DescriptionText className="collapse-element">
                    <p>{local}</p>
                  </DescriptionText>
                )}

                {kind === "common" && link && (
                  <DescriptionText className="collapse-element">
                    <a href={link} target="_blank">{`URL: ${link}`}</a>
                  </DescriptionText>
                )}

                {(kind === "common" || (kind === "holiday" && !!description)) && (
                  <DescriptionText className="collapse-element">
                    <p>{description}</p>
                  </DescriptionText>
                )}
              </ContainerListEvent>
            );
          })
        ) : !dateClick && eventsList?.length > 0 ? (
          <InfiniteScroll
            pageStart={1}
            initialLoad={false}
            loadMore={fetchMoreNextEventsV2}
            hasMore={hasMoreNextEventsV2}
            useWindow={false}
            threshold={50}
            loader={<SkeletonEventList />}
          >
            {eventsList.map((event) => {
              const {
                id,
                title,
                kind,
                hour_event,
                description,
                hour_event_end,
                date_event,
                local,
                link,
              } = event;

              return (
                <ContainerListEvent
                  key={id}
                  onClick={() =>
                    document

                      .getElementById(`${id}Event`)

                      .classList.toggle("uncollapsed")
                  }
                  id={`${id}Event`}
                  collapsed={kind === "common"}
                >
                  <ContainerBodyList>
                    <SideContainerLeft />

                    <Flex flexDirection="column">
                      <Flex>
                        <DateEvent>{`${smartFormatterDate(
                          date_event,

                          "ddd",
                        )}, ${smartFormatterDate(
                          date_event,
                          "LL",
                        )}`}</DateEvent>
                      </Flex>

                      <Event>{title}</Event>

                      {kind === "common" && (
                        <>
                          <HourEvent>
                            {`${smartFormatterDate(
                              hour_event,
                              "LT",
                            )}h às ${smartFormatterDate(
                              hour_event_end,
                              "LT",
                            )}h`}
                          </HourEvent>
                        </>
                      )}
                    </Flex>

                    {kind === "common" && (
                      <ArrowLeftIcon className="collapse-trigger" />
                    )}
                  </ContainerBodyList>

                  {kind === "common" && local && (
                    <DescriptionText className="collapse-element">
                      <p>{local}</p>
                    </DescriptionText>
                  )}

                  {kind === "common" && link && (
                    <DescriptionText className="collapse-element">
                      <a href={link} target="_blank">{`URL: ${link}`}</a>
                    </DescriptionText>
                  )}

                  {kind === "common" && (
                    <DescriptionText className="collapse-element">
                      <p>{description}</p>
                    </DescriptionText>
                  )}
                </ContainerListEvent>
              );
            })}
          </InfiniteScroll>
        ) : (
          <Flex p={2} alignItems="center">
            <ContainerNoNextEvents>
              <NoNextEvents />
              <MsgDoesNotExist>
                {intl.messages["calendar.textPage.notNextEvents"]}.
              </MsgDoesNotExist>
            </ContainerNoNextEvents>
          </Flex>
        )}
      </Overflow>
      <FooterEffectScroll />
    </Flex>
  );
};

EventList.propTypes = {
  // eslint-disable-next-line react/require-default-props
  dateClick: PropTypes.string,
};

export default EventList;
