import React from "react";
import { takeLatest, put } from "redux-saga/effects";
import {
  GET_HOLERITE,
  GET_HOLERITE_PDF,
  GET_SERVICES_MACHADDO,
} from "utils/constants/endpoints";
import { FormattedMessage } from "react-intl";
import axios from "axios";
import { Types } from "../ducks/holerite";
import { Types as AlertTypes } from "../ducks/modalAlert";
import { API_V2 } from "../../utils/API/API";

const getHeaders = () => {
  let headers = JSON.parse(localStorage.getItem("headers"));

  if (!headers) {
    headers = {
      client: localStorage.getItem("client"),
      uid: localStorage.getItem("uid"),
      "access-token": localStorage.getItem("access-token"),
      "adal-access-token": localStorage.getItem("adal-access-token"),
    };
  }

  headers["host-header"] = localStorage.getItem("host-header");

  return headers;
};

function* getPayroll({ payload }) {
  const { period, kind = "" } = payload;

  try {
    const { data: payroll } = yield axios({
      url: GET_HOLERITE(period, kind),
      method: "GET",
      headers: getHeaders(),
      data: {},
    }).catch((error) => {
      throw new Error(error.response.data.message);
    });

    if (payroll.retorno.tipo === "S") {
      yield put({
        payroll,
        type: Types.GET_PAYROLL_SUCCESS,
      });
    } else {
      throw new Error(payroll.retorno.msg);
    }
  } catch (error) {
    yield put({
      type: Types.GET_PAYROLL_FAIL,
      error,
    });
  }
}

function* getPayrollPdf({ payload }) {
  const { period, kind = "" } = payload;

  try {
    const { data } = yield axios({
      url: GET_HOLERITE_PDF(period, kind),
      method: "GET",
      headers: getHeaders(),
      responseType: "blob",
      data: {},
    }).catch((error) => {
      throw new Error(error.response.data.message);
    });

    const src = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = src;
    link.setAttribute("download", `holerite-${period}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();

    yield put({
      type: Types.GET_PAYROLL_PDF_SUCCESS,
    });
  } catch (error) {
    const messages = {
      type: "errors",
      title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
      msg: error.response.data[Object.keys(error.response.data)],
      buttons: [
        {
          title: "OK",
        },
      ],
    };

    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });

    yield put({
      type: Types.GET_PAYROLL_PDF_FAIL,
    });
  }
}

function* getPayrollTotem({ payload }) {
  const { period, kind = "" } = payload;

  try {
    const { data } = yield axios({
      url: GET_HOLERITE_PDF(period, kind),
      method: "GET",
      headers: getHeaders(),
      responseType: "blob",
      data: {},
    }).catch((error) => {
      throw new Error(error.response.data.message);
    });

    const src = window.URL.createObjectURL(
      new Blob([data], { type: "application/pdf" }),
    );
    const pdfToPrint = window.open(src);
    pdfToPrint.print();

    // const link = document.createElement("a");
    // link.href = src;
    // link.setAttribute("download", `holerite-${period}.pdf`);
    // document.body.appendChild(link);
    // link.click();
    // link.remove();

    yield put({
      type: Types.GET_PAYROLL_TOTEM_SUCCESS,
    });
  } catch (error) {
    const messages = {
      type: "errors",
      title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
      msg: error.response.data[Object.keys(error.response.data)],
      buttons: [
        {
          title: "OK",
        },
      ],
    };

    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });

    yield put({
      type: Types.GET_PAYROLL_TOTEM_FAIL,
    });
  }
}

function* getHoleriteMachado() {
  try {
    const { data : { data }} = yield API_V2({
      url: `${GET_SERVICES_MACHADDO}`,
      method: "GET",
    });

    yield put({
      linkHoleriteMachado: data,
      type: Types.GET_HOLERITE_MACHADO_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_HOLERITE_MACHADO_FAIL,
      error,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_PAYROLL, getPayroll);
  yield takeLatest(Types.GET_PAYROLL_PDF, getPayrollPdf);
  yield takeLatest(Types.GET_PAYROLL_TOTEM, getPayrollTotem);
  yield takeLatest(Types.GET_HOLERITE_MACHADO, getHoleriteMachado);
}
