import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  calendarEvents: ["payload"],
  getCalendarEventsSuccess: ["payload", "isLoading"],
  getCalendarEventsFail: ["isLoading", "error"],

  getNextEvents: [],
  getNextEventsSuccess: ["payload", "isLoading"],
  getNextEventsFail: ["isLoading", "error"],

  getNextEventsV2: ["payload"],
  getNextEventsV2Success: ["payload", "isLoading"],
  getNextEventsV2Fail: ["isLoading", "error"],
});
const INITIAL_STATE = {
  isLoading: true,
  monthCalender: "",

  nextEventsV2: [],
  hasMoreNextEventsV2: [],
  pageNextEventsV2: 1,
  isLoadingNextEventsV2: true,
};
const calendarEvents = (state) => ({ ...state });

const getCalendarEventsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getCalendarEventsFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getNextEvents = (state) => ({ ...state });

const getNextEventsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getNextEventsFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getNextEventsV2 = (state) => ({ ...state, isLoadingNextEventsV2: true});

const getNextEventsV2Success = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getNextEventsV2Fail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

export default createReducer(INITIAL_STATE, {
  [Types.CALENDAR_EVENTS]: calendarEvents,
  [Types.GET_CALENDAR_EVENTS_SUCCESS]: getCalendarEventsSuccess,
  [Types.GET_CALENDAR_EVENTS_FAIL]: getCalendarEventsFail,

  [Types.GET_NEXT_EVENTS]: getNextEvents,
  [Types.GET_NEXT_EVENTS_SUCCESS]: getNextEventsSuccess,
  [Types.GET_NEXT_EVENTS_FAIL]: getNextEventsFail,

  [Types.GET_NEXT_EVENTS_V2]: getNextEventsV2,
  [Types.GET_NEXT_EVENTS_V2_SUCCESS]: getNextEventsV2Success,
  [Types.GET_NEXT_EVENTS_V2_FAIL]: getNextEventsV2Fail,
});
