import React from "react";
import { FormattedMessage } from "react-intl";
import { takeLatest, put, select } from "redux-saga/effects";
import { GET, POST, DELETE } from "utils/constants/verbs";
import { requestAPI } from "helpers/requestHelpers";
import { COURSE, COURSERS } from "utils/constants/endpoints";
import { history, convertToSlug } from "helpers/sharedHelpers";
import urls from "utils/constants/urls";
import { Types } from "../ducks/academy";
import { Types as AlertTypes } from "../ducks/modalAlert";

const getCoursesActual = (state) => state.academy.coursesList;
const pageActual = (state) => state.academy.page;

function* getCourses() {
  const page = yield select(pageActual);
  try {
    const courses = yield requestAPI({
      verb: GET,
      endPoint: `${COURSE}?page=${page}`,
    });

    const { courses: coursesL } = courses;
    const actualList = page === 1 ? [] : yield select(getCoursesActual);
    const newList = actualList.concat(coursesL);
    const hasMore = coursesL.length === 10;
    const nextpage = page + 1;

    yield put({
      courses,
      hasMore,
      page: nextpage,
      coursesList: newList,
      type: Types.GET_COURSES_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_COURSES_FAIL,
      ...error.response.data,
    });
  }
}

function* getDetailsCourse({ idCourse }) {
  try {
    const response = yield requestAPI({
      verb: GET,
      endPoint: `${COURSE}/${idCourse}`,
    });

    const {
      id,
      title,
      description,
      image,
      score,
      user_status,
      module_courses,
      finished_modules,
      has_certificate,
      minimum_percentage_for_certificate,
      modality,
      validated
    } = response;

    const course_details = {
      id,
      title,
      description,
      image,
      score,
      user_status,
      module_courses,
      finished_modules,
      has_certificate,
      minimum_percentage_for_certificate,
      modality,
      validated
    };

    yield put({
      course_details,
      type: Types.GET_DETAILS_COURSE_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_DETAILS_COURSE_FAIL,
      ...error.response.data,
    });
  }
}

function* getMyCourses() {
  try {
    const response = yield requestAPI({
      verb: GET,
      endPoint: `${COURSE}?origin=web&initiated=true`,
    });
    const { size } = response;
    const myCourses = [];

    // eslint-disable-next-line
    for (var i = 1; i <= size[1]; i++) {
      const myCourse = yield requestAPI({
        verb: GET,
        endPoint: `${COURSE}?page=${i}&initiated=true`,
      });
      const { courses } = myCourse;
      courses.map((course) => myCourses.push(course));
    }

    yield put({
      myCourses,
      type: Types.GET_MY_COURSES_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_MY_COURSES_FAIL,
      ...error.response.data,
    });
  }
}

function* resetCourse({ payload }) {
  const { id } = payload;

  const urlEndPoint = `${id}/restart`;

  try {
    yield requestAPI({
      verb: DELETE,
      endPoint: `${COURSERS}/${urlEndPoint}`,
    });
    yield put({
      type: Types.RESET_COURSE_SUCCESS,
    });
    window.location.reload(true);
  } catch (error) {
    yield put({
      type: Types.RESET_COURSE_FAIL,
      ...error.response.data,
    });
  }
}

function* postAnswer({ payload }) {
  const { idCourse, idModule, idContent, idAnswer } = payload;

  const urlEndPoint = `${idCourse}/module_courses/${idModule}/contents/${idContent}/validate_answer_repeateds`;

  try {
    yield requestAPI({
      verb: POST,
      endPoint: `${COURSE}/${urlEndPoint}`,
      data: { answer_id: idAnswer },
    });
    const response = yield requestAPI({
      verb: GET,
      endPoint: `${COURSE}/${idCourse}`,
    });
    const {
      id,
      title,
      description,
      image,
      score,
      user_status,
      module_courses,
      finished_modules,
    } = response;

    const course_details = {
      id,
      title,
      description,
      image,
      score,
      user_status,
      module_courses,
      finished_modules,
    };

    yield put({
      course_details,
      type: Types.GET_DETAILS_COURSE_SUCCESS,
    });
    yield put({
      type: Types.POST_ANSWER_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.POST_ANSWER_FAIL,
      ...error.response.data,
    });
  }
}

function* finalizeSubmodule({ payload }) {
  const { idCourse, idSubmodule } = payload;
  const bodyEndPoint = `${idCourse}/module_courses/${idSubmodule}/mark_as_seen`;

  try {
    yield requestAPI({
      verb: POST,
      endPoint: `${COURSERS}/${bodyEndPoint}`,
      data: idSubmodule,
    });
    yield put({
      type: Types.FINALIZE_SUBMODULE_SUCCESS,
      idSubmodule,
    });
  } catch (error) {
    const messages = {
      type: "errors",
      title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
      msg: error.response.data[Object.keys(error.response.data)],
    };
    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });
    yield put({
      type: Types.FINALIZE_SUBMODULE_FAIL,
      errorfinalizeModule: true,
    });
  }
}

function* finalizeModule({ payload }) {
  const { idCourse, idModule, titleModule, isFinalized, titleCourse } = payload;
  const bodyEndPoint = `${idCourse}/module_courses/${idModule}/validate_modules`;
  let courseDetails = {
    user_status:null,
    minimum_percentage_for_certificate: null
  }
  try {
    yield requestAPI({
      verb: GET,
      endPoint: `${COURSE}/${idCourse}`,
    });
    yield requestAPI({
      verb: POST,
      endPoint: `${COURSERS}/${bodyEndPoint}`,
      data: idModule,
    });
    if(isFinalized){
      courseDetails = yield requestAPI({
        verb: GET,
        endPoint: `${COURSE}/${idCourse}`,
      });
    }
    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: isFinalized
        ? {
            title: (
              <FormattedMessage id="academy.couseDetail.finalizedCourse" />
            ),
            msg: courseDetails.user_status?.is_entitled_to_certificate ? 
              [
                `Parabéns, você alcançou o resultado ideal!`,
                `Adquira o seu certificado, se foi disponibilizado para este curso.`
              ]
              : `Infelizmente, você teve um resultado abaixo do ideal, sugerimos que você tente novamente.`,
            buttons: [
              {
                title: "Ok",
                event: () => history.push(`${urls.ROUTES.ACADEMY}/${idCourse}`),
              },
            ],
          }
        : {
            title: (
              <FormattedMessage id="academy.couseDetail.finalizedModule" />
            ),
            msg: `Parabéns, você finalizou o módulo ${titleModule}!`,
            buttons: [
              {
                title: "OK",
                event: () => {
                  history.push(
                    `${urls.ROUTES.ACADEMY}/${idCourse}/${convertToSlug(
                      titleCourse,
                    )}`,
                  );
                  window.location.reload();
                },
              },
            ],
          },
    });
    yield put({
      type: Types.FINALIZE_MODULE_SUCCESS,
    });
  } catch (error) {
    const messages = {
      type: "errors",
      title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
      msg: error.response.data[Object.keys(error.response.data)],
    };
    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });
    yield put({
      type: Types.FINALIZE_MODULE_FAIL,
      errorfinalizeModule: true,
    });
  }
}

function* getCertificate({ payload }) {
  const { id: idCourse, title: titleCourse } = payload;

  try {
    const response = yield requestAPI({
      verb: GET,
      endPoint: `${COURSERS}/${idCourse}/certificate`,
    });

    const link = document.createElement("a");
    link.href = `data:application/pdf;base64,${response}`;
    link.setAttribute("download", `certificado-${titleCourse}.pdf`);
    document.body.appendChild(link);
    link.click();

    yield put({
      type: Types.GET_CERTIFICATE_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_CERTIFICATE_FAIL,
      ...error.response.data,
    });
  }
}

function* postVoucher({ payload }) {
  const { idCourse, code } = payload;

  try {
    yield requestAPI({
      verb: GET,
      endPoint: `${COURSERS}/${idCourse}/vouchers/validate?code=${code}`,
    });

    yield put({
      type: Types.POST_VOUCHER_SUCCESS,
    });

    // const link = document.createElement("a");
    // link.href = `data:application/pdf;base64,${response}`;
    // link.setAttribute("download", `certificado-${titleCourse}.pdf`);
    // document.body.appendChild(link);
    // link.click();

    // yield put({
    //   type: Types.GET_CERTIFICATE_SUCCESS,
    // });
  } catch (error) {
    yield put({
      type: Types.POST_VOUCHER_FAIL,
      ...error.response.data,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_COURSES, getCourses);
  yield takeLatest(Types.GET_DETAILS_COURSE, getDetailsCourse);
  yield takeLatest(Types.GET_MY_COURSES, getMyCourses);
  yield takeLatest(Types.RESET_COURSE, resetCourse);
  yield takeLatest(Types.POST_ANSWER, postAnswer);
  yield takeLatest(Types.FINALIZE_SUBMODULE, finalizeSubmodule);
  yield takeLatest(Types.FINALIZE_MODULE, finalizeModule);
  yield takeLatest(Types.GET_CERTIFICATE, getCertificate);
  yield takeLatest(Types.POST_VOUCHER, postVoucher);
}
