import React, { useState, useLayoutEffect } from "react";
import Skeleton from "react-loading-skeleton";

import Flex from "components/core/Grid/Flex";

const CourseSkeleton = () => {
  const [items, setItems] = useState([]);

  useLayoutEffect(() => {
    for (let i = 0; i < 5; i++) {
      setItems((oldState) => [
        ...oldState,
        <Flex key={i} mb={2} pb={12} borderBottom="1px solid #ccd2e0">
          <Skeleton duration={0.8} width={526} height={40} />
        </Flex>,
      ]);
    }
  }, []);

  return (
    <Flex>
      <Flex flexDirection="column" mx="auto" width={526}>
        <Flex mt={40}>
          <Skeleton duration={0.8} width={526} height={410} />
        </Flex>
        <Flex flexDirection="column" mt={6}>
          {items}
          <Flex mb={2} pb={12}>
            <Skeleton duration={0.8} width={526} height={40} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CourseSkeleton;
