/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import Carousel from "nuka-carousel";
import { v1 as uuidv1 } from "uuid";
import { ReactComponent as ArrowLeft } from "assets/img/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "assets/img/icons/arrow-right.svg";
import {
  ContainerCarousel,
  IncludesSlide,
  UlSlide,
  LiSlide,
  ItensLiSlide,
} from "./CauroselStyle";

const CarouselCore = (props) => {
  const renderDotControls = ({ currentSlide, slideCount }) => (
    <IncludesSlide>
      <UlSlide>
        {[...Array(slideCount)].map((_sc, i) => (
          <LiSlide key={uuidv1()} data-testid={`dot-control-${i}`}>
            <ItensLiSlide index={i} currentSlide={currentSlide} />
          </LiSlide>
        ))}
      </UlSlide>
    </IncludesSlide>
  );

  const { itensRender } = props;

  return (
    <ContainerCarousel showButtonNext={props.showButtonCarousel}>
      <Carousel
        defaultControlsConfig={{
          nextButtonText: <ArrowRight />,
          prevButtonText: <ArrowLeft />,
        }}
        renderBottomCenterControls={renderDotControls}
        {...props}
      >
        {itensRender}
      </Carousel>
    </ContainerCarousel>
  );
};

Carousel.propTypes = {
  defaultControlsConfig: PropTypes.objectOf(PropTypes.object),
};

export default CarouselCore;
