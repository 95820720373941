import styled from "styled-components";
import css from "@styled-system/css";

export const BodyGalleryDetails = styled("div")(
  css({
    width: "100%",
    height: "100%",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  }),
);

export const ContainerGalleryList = styled("div")(
  css({
    columnCount: "4",
    columnGap: "10px",
    padding: "0px 16px 16px",
  }),
);

export const BodyDetailsGallery = styled("figure")(
  css({
    margin: 0,
    display: "flex",
    cursor: "zoom-in",
    breakInside: "avoid",
    marginBottom: "10px",
    borderRadius: "9.6px",
    transition: "1s",
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)",
  }),
);

export const contentImage = styled("img")(
  css({
    width: "100%",
    borderRadius: "9.6px",
  }),
);

export const contentVideo = styled("video")(
  css({
    width: "100%",
    height: "auto",
    borderRadius: "9.6px",
  }),
);

export const ContentNotFound = styled("div")(
  css({
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  }),
);

export const ContainerShowLightBox = styled("div")(
  css({
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: "999",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  }),
);

export const ContentShowLightBox = styled("div")(
  css({
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }),
);

export const LightBoxContent = styled("div")(
  css({
    width: "auto",
    display: "flex",
    borderRadius: "8px",
    alignItems: "center",
    justifyContent: "center",
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)",
  }),
);

export const ButtonArrow = styled("div")(
  css({
    padding: "10px",
    display: "flex",
    margin: "0px 50px",
    alignItems: "center",
    borderRadius: "100px",
    border: `1px solid`,
    borderColor: "paleGrey",
    justifyContent: "center",
    cursor: "pointer",
    visibility: "visible",
    ":hover": {
      opacity: 0.7,
    },
  }),
  ({ lightBoxClick }) =>
    lightBoxClick &&
    css({
      visibility: "hidden",
    }),
);

export const ContainerOptionsLightBox = styled("div")(
  css({
    position: "fixed",
    top: 0,
    right: 0,
    padding: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    "#close_btn": {
      path: {
        stroke: "paleGrey",
      },
    },
    svg: {
      cursor: "pointer",
      ":hover": {
        opacity: 0.7,
      },
    },
  }),
);

export const LinkDownloadGallery = styled("a")(
  css({
    paddingRight: "16px",
    textDecoration: "none",
    svg: {
      g: {
        g: {
          stroke: "paleGrey",
        },
      },
    },
  }),
);

export const BoxButton = styled("div")(
  css({
    width: "100%",
    height: "70px",
    display: "flex",
    alignItems: "center",
    zIndex: "100",
    boxShadow: "0px 15px 10px 0px white",

    button: {
      margin: "0 10px",
    },
  }),
);
