import css from "@styled-system/css";
import styled from "styled-components";
import theme from "../../../styles/theme";

export const Container = styled("div")(
  css({
    width: "100%",
    padding: "10px 40px",
    color: `${theme.colors.blackLight}`,

    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      padding: "10px 0",

      img: {
        height: "50px",
        width: "auto",
      },
    },

    table: {
      width: "100%",
      th: {
        textTransform: "uppercase",
      },

      td: {
        borderBottom: `1px solid ${theme.colors.grayDefaultColor}`,

        "& + td": {
          borderLeft: `1px solid ${theme.colors.grayDefaultColor}`,
        },
      },

      "th, td": {
        padding: "0.5rem",
      },

      "& + table": {
        marginTop: "1rem",
      },
    },
  }),
);

export const Rubricas = styled("div")(
  css({
    width: "100%",
    margin: "3rem 0",

    table: {
      width: "100%",
      border: `1px solid ${theme.colors.grayDefaultColor}`,

      "& + table": {
        marginTop: "-1px",
      },

      "th, td": {
        padding: "0.5rem",
      },

      tr: {
        borderTop: `1px solid ${theme.colors.grayDefaultColor}`,
      },

      td: {
        border: `1px solid ${theme.colors.grayDefaultColor}`,
        textAlign: "center",

        span: {
          verticalAlign: "top",
          fontSize: "0.6rem",
          marginRight: "0.3rem",
        },
      },

      th: {
        textTransform: "uppercase",
        textAlign: "center",
        borderBottom: `1px solid ${theme.colors.grayDefaultColor}`,
      },
    },
  }),
);
