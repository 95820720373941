import styled from "styled-components";
import css from "@styled-system/css";
import theme from "styles/theme";
import Flex from "components/core/Grid/Flex";

export const GeneralContainer = styled(Flex)(
  css({
    height: "100%",
    width: "100%",
    marginBottom: "120px",
    padding: "50px",
    justifyItems: "center",
    display: "flex",
    flexWrap: "wrap",
    a: {
      ":hover": {
        textDecoration: "none",
      },
    },
    "@media (max-width: 768px)": {
      padding: "30px",
    },
  }),
);

export const ButtonContainer = styled("div")(
  css({
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    flexDirection: "column",
    marginRight: "50px",
  }),
);

export const ButtonLink = styled("button")(
  css({
    width: "90px",
    display: "flex",
    height: "90px",
    alignItems: "center",
    marginBottom: "15px",
    borderRadius: "6.9px",
    flexDirection: "column",
    justifyContent: "center",
    border: "none",
    backgroundColor: theme.colors.paleGrey,
    ":hover": {
      cursor: "pointer",
      background: `linear-gradient(
        215deg,
        ${theme.colors.primaryColor},
        ${theme.colors.secondaryColor} 100%
       )`,
      boxShadow: "0px 2.88158px 5.76316px rgba(0, 0, 0, 0.25)",

      svg: {
        path: {
          stroke: "white",
          fill: "transparent",
        },
      },

      img: {
        filter: "none",
      },
    },
    "@media (max-width: 320px)": {
      width: "120px",
      height: "120px",
    },
    img: {
      maxWidth: "35px",
      maxHeight: "100%",
      filter: theme.colors.filterConvertColorPrimary,
    },
    svg: {
      width: 30,
      height: 30,
      path: {
        stroke: theme.colors.primaryColor,
        fill: "transparent",
      },
    },
  }),
);

export const FolderName = styled("h3")(
  css({
    margin: "0 0 16px 0",
    fontSize: "13px",
    width: "90px",
    fontFamily: "text",
    lineHeight: "21px",
    textAlign: "center",
    color: theme.colors.primaryColor,
  }),
);
