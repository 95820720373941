import React from "react";

import * as S from "./PrivacyPolicyStyle";

import { style } from "style";
import { SecondLevelList } from "./SecondLevelLists";

const defaultApps = [
  {
    key: "ioasys",
    nameApp: "Ioasys Team",
  },
  {
    key: "youseed",
    nameApp: "Youseed",
  },
  {
    key: "somosmachado",
    nameApp: "SOMOS MACHADO",
  },
];

const nozVersionApps = [
  {
    key: "sample",
    nameApp: "SAMPLE",
    nameCompany: "EMPRESA"
  },
  {
    key: "noz",
    nameApp: "NOZ",
    nameCompany: "Conecta"
  },
]

function returnNameAppByKey() {
  return defaultApps.map(
    (appObject) => appObject.key === style.names.nameApp && appObject.nameApp,
  );
}

function returnNameAppByKeyNozVersion() {
  return nozVersionApps.map(
    (appObject) => appObject.key === style.names.nameApp && appObject.nameApp,
  );
}

function returnNameCompanyByKeyNozVersion() {
  return nozVersionApps.find((appObject) => appObject.key === style.names.nameApp).nameCompany;
}

const PrivacyPolicy = () => {
  if (style.names.nameApp === "sample" || style.names.nameApp === "noz") {
    return (
      <S.ContainerPrivacyPolicy>
        <h6>TERMOS E CONDIÇÕES DE USO DOS USUÁRIOS</h6>

        <span className="last-update">Atualizado pela última vez em 20 de Junho de 2023.</span>

        <span className="subtitle">
          Prezado Usuário, seja muito bem-vindo à Plataforma {returnNameAppByKeyNozVersion()}!
        </span>
        <span className="subtitle">
          Pedimos que leia cuidadosamente estes Termos de Uso antes de acessar
          ou usar qualquer funcionalidade da Plataforma {returnNameAppByKeyNozVersion()}, uma vez que o
          seu uso ou acesso implica aceitação e vinculação integral do Usuário a
          todas as condições, obrigações e demais questões previstas nestes
          Termos de Uso.
        </span>

        <ol>
          <li style={{ paddingTop: "1rem" }}>
            <strong>DEFINIÇÕES</strong>
            <SecondLevelList
              topListCount={1}
              currentListCount={1}
              text="Para facilitar o entendimento destes Termos de Uso, as
              expressões iniciadas com letra maiúscula deverão ser
              interpretadas considerando as seguintes definições"
              terms={[
                "Conta#: significa a conta pessoal do Usuário, que lhe dará acesso a Plataforma;",
                "Notificações#: significam todas as comunicações, avisos ou notificações relacionadas a estes Termos, que deverão ser realizados conforme a Cláusula 12;",
                "Plataforma#: significa o ambiente eletrônico na forma de aplicativo e site, no qual serão disponibilizadas as Ferramentas para interação entre os Usuários e entre os Usuários e a Empresa;",
                "Ferramentas#: significam as ferramentas, recursos e funcionalidades disponíveis para utilização do Usuário e disponibilizadas através da Plataforma;",
                "Termos de Uso#: significa estes “Termos e Condições de Uso dos Usuários;",
                "Usuário#: significa a pessoa física, funcionário ou parceiro da PetroRio, titular de uma Conta para acesso à Plataforma;",
                "Conecta#: significa a CONECTA INTERMEDIADORA DE NEGOCIOS LTDA., pessoa jurídica de direito privado, com sede na Rua dos Paraíba, 1352, sala 903, Savassi, na cidade de Belo Horizonte, Estado de Minas Gerais, inscrita no CNPJ sob o nº 32.170.616/0001-05.",
              ]}
            />
          </li>
          <li style={{ paddingTop: "1rem" }}>
            <strong>OBJETO</strong>
            <SecondLevelList
              topListCount={2}
              currentListCount={1}
              text="Estes Termos de Uso descrevem os termos e condições sob os quais o Usuário poderá
              criar uma Conta na Plataforma, bem como as regras de utilização da Plataforma, e também
              aos conteúdos e inscrições disponibilizados em nosso website."
              terms={[
                "O acesso e uso do Plataforma por parte do Usuário e utilização de qualquer Ferramenta estão sujeitos a estes Termos de Uso.",
              ]}
            />
          </li>
          <li style={{ paddingTop: "1rem" }}>
            <strong>ACEITAÇÃO DOS TERMOS DE USO</strong>
            <SecondLevelList
              topListCount={3}
              currentListCount={1}
              text="A UTILIZAÇÃO DA PLATAFORMA ESTÁ CONDICIONADA À ACEITAÇÃO E
              CONSENTIMENTO DO USUÁRIO ÀS REGRAS DESTES TERMOS DE USO."
            />
            <SecondLevelList
              topListCount={3}
              currentListCount={2}
              text="Ao clicar no botão “concordo”, após “você concorda com nossos termos de uso?”, o
              Usuário concorda e expressa sua vontade livre, consciente e informada de cumprir com e ser regido
              por estes Termos de Uso."
            />
            <SecondLevelList
              topListCount={3}
              currentListCount={3}
              text={`A ${returnNameCompanyByKeyNozVersion()} está à disposição para esclarecer aos Usuários acerca quaisquer pontos
              incompreensíveis destes Termos de Uso.`}
            />
          </li>
          <li style={{ paddingTop: "1rem" }}>
            <strong>USO DA PLATAFORMA E DISPONIBILIZAÇÃO DE FERRAMENTAS</strong>
            <SecondLevelList
              topListCount={4}
              currentListCount={1}
              text="O uso da Plataforma destina-se exclusivamente a pessoas autorizadas pela Conecta,
              com o objetivo de estimular a comunicação e o engajamento dos Usuários, através de
              Ferramentas de interação disponibilizadas na Plataforma."
            />
            <SecondLevelList
              topListCount={4}
              currentListCount={2}
              text="As Ferramentas disponibilizadas pela Conecta poderão consistir em"
              terms={[
                "ferramentas de comunicação e engajamento dos Usuários;",
                "publicação de conteúdos relacionados às atividades e iniciativas da Conecta, os quais poderão ser apresentados em diferentes formatos (fotos, carrossel, vídeos, textos e links);",
                "publicação de enquetes, formulários e sorteios;",
                "disponibilização de cursos e treinamentos;",
                "ferramentas de armazenamento de conteúdo do Usuário relacionado à Conecta;",
                "seção específica para troca de pontos eventualmente concedidos aos Usuários pela Conecta, os quais poderão ser trocados pelos itens disponíveis na Plataforma; e",
                "quaisquer outras ferramentas acrescentadas à Plataforma.",
              ]}
            />
          </li>
          <li style={{ paddingTop: "1rem" }}>
            <strong>ACESSO, CADASTRO E DADOS DO USUÁRIO</strong>
            <SecondLevelList
              topListCount={5}
              currentListCount={1}
              text="Para utilizar a Plataforma, ter acesso e utilizar as Ferramentas, o Usuário deverá
              manter uma conta pessoal na Plataforma (“Conta”) que será criada diretamente pela
              Conecta e cujos dados de acesso serão enviados diretamente ao Usuário."
              terms={[
                "O Usuário autoriza a Conecta a utilizar informações pessoais do Usuário que a Conecta tenha recebido em decorrência de outros contratos existentes entre o Usuário e a Conecta, para que tais informações sejam cadastradas na Plataforma e utilizadas na criação da Conta do Usuário.",
                "Sem prejuízo da utilização das informações pessoais do Usuário que já sejam de conhecimento da Conecta, a Conecta poderá solicitar o fornecimento de informações complementares ao Usuário para fins de cadastro e utilização da Plataforma, sendo o fornecimento de tais informações condição à utilização das Ferramentas disponibilizadas através da Plataforma.",
                "Informações pessoais refere-se a qualquer dado relacionado a um indivíduo que possa ser empregado para identificá-lo, tanto de forma singular quanto por meio de uma análise abrangente que englobe várias formas de informação. Exemplos típicos de informação pessoal são dados de identificação comumente utilizados no dia a dia, como designação, número de CPF, registro geral, matrícula, telefone, imagens, residência, gênero, entre outros, e inclusive dados de uso da Internet e registros digitais que permitem a identificação de uma pessoa, tais como endereço de Protocolo de Internet (IP), porta de origem lógica, informações sobre as configurações do dispositivo utilizado para acessar a rede, entre outros.",
              ]}
            />
            <SecondLevelList
              topListCount={5}
              currentListCount={2}
              text="O Usuário é responsável por todas as atividades, ações e movimentações realizadas
              na Plataforma através da sua Conta e deverá manter sempre a segurança e o sigilo do login
              e senha de acesso a sua Conta."
              terms={[
                "O Usuário deverá notificar imediatamente a Conecta sobre qualquer uso não autorizado de sua Conta e qualquer outra violação de segurança.",
                "O Usuário se compromete a não utilizar sua Conta e tampouco as Ferramentas para realização de qualquer atividade ilícita, indevida ou não autorizada.",
                "O usuário que por ventura deseje a exclusão de seus dados da plataforma, poderá solicitar através do e-mail contato@appnoz.com.br, ou no formulário do fale conosco em nosso site www.appnoz.com.br, ou através da plataforma."
              ]}
            />
            <SecondLevelList
              topListCount={5}
              currentListCount={3}
              text="A Conecta poderá suspender, temporária ou definitivamente, o acesso do Usuário à
              Plataforma, podendo tal suspensão decorrer de:"
              terms={[
                "descumprimento destes Termos de Uso; e/ou",
                "rescisão de outros contratos entre o Usuário e a Conecta, cuja rescisão torne o uso da Plataforma inadequado ao Usuário; e/ou",
                "medida de proteção à Plataforma, à Conecta, aos Usuários e a terceiros, caso tenha indícios de fraude, utilização inadequada, ilícita ou não autorizada da Plataforma; sem que nada seja devido pela Conecta ao Usuário, em decorrência de tais cancelamentos ou suspensão."
              ]}
            />
          </li>
          <li style={{ paddingTop: "1rem" }}>
            <strong>REGRAS DE UTILIZAÇÃO DA PLATAFORMA</strong>
            <SecondLevelList
              topListCount={6}
              currentListCount={1}
              text="Ao utilizar a Plataforma o Usuário terá mecanismos para realizar comentários em
              conteúdo disponibilizado pela Conecta, bem como poderá se expressar através de outras
              ferramentas da Plataforma. O Usuário será integralmente responsável por todo o conteúdo,
              manifestações e interações feitas por meio da Plataforma, tendo em vista que os
              comentários postados são opiniões e declarações pessoais do Usuário."
              terms={[
                "O Usuário se responsabiliza, perante a Conecta e aos demais Usuários, pelas opiniões, comentários e por todo e qualquer outro conteúdo publicado ou transmitido pelo Usuário através da Plataforma, ficando a Conecta isenta de qualquer responsabilidade relativa à veiculação de qualquer conteúdo ou manifestação dos Usuários através da Plataforma.",
                "Os Usuários autorizam a Conecta a excluir da Plataforma qualquer conteúdo ou manifestação, feita por qualquer Usuário, que à exclusivo critério da Conecta seja inadequado à Plataforma.",
              ]}
            />
            <SecondLevelList
              topListCount={6}
              currentListCount={2}
              text="A utilização da Plataforma pelos Usuários também está sujeita à observância das
              regras, obrigações e restrições previstas em quaisquer outros contratos existentes entre o
              Usuário e a Conecta, bem como em eventuais políticas e manuais da Conecta."
              terms={[
                "O descumprimento destes Termos de Uso poderá consistir em violação das demais regras e obrigações entre o Usuário e a Conecta.",
              ]}
            />
          </li>
          <li style={{ paddingTop: "1rem" }}>
            <strong>LIMITAÇÃO DE RESPONSABILIDADE</strong>
            <SecondLevelList
              topListCount={7}
              currentListCount={1}
              text="A atuação da Conecta limita-se à disponibilização de Ferramentas através da
              Plataforma para utilização dos Usuários, de modo que a Conecta não se responsabiliza e
              deve ser mantida isenta e indene de quaisquer danos direto relacionados a qualquer
              aspecto de uso da Plataforma pelos Usuários."
              terms={[
                "O Usuário compromete-se a buscar quaisquer reparações diretamente do Usuário que houver descumprido estes Termos de Uso, em caso de utilização indevida ou ilícita da Plataforma, por reconhecer a ausência de qualquer responsabilidade da Conecta com relação à forma de utilização da Plataforma pelos Usuários.",
              ]}
            />
            <SecondLevelList
              topListCount={7}
              currentListCount={2}
              text="A Conecta envidará seus melhores esforços e recursos para manter a Plataforma em
              pleno funcionamento para todos os Usuários. A Conecta não poderá ser responsabilizada,
              em nenhuma circunstância, por eventual dificuldade de acesso à Plataforma, bem como por
              quaisquer danos, de qualquer forma causados ao Usuário, por falhas na internet e por
              quaisquer vírus, cavalos de troia, worms ou outras rotinas de programação de computador
              que possam danificar, interferir ou possam ser infiltrados nos equipamentos do Usuário em
              decorrência do acesso à Plataforma ou como consequência da transferência de dados,
              arquivos, imagens, textos ou qualquer outro conteúdo contidos nos aparelhos utilizados pelo
              Usuário para acessar a Plataforma."
            />
            <SecondLevelList
              topListCount={7}
              currentListCount={3}
              text="A Conecta não tem controle e não assume responsabilidade pelo conteúdo, políticas de
              privacidade ou práticas de quaisquer sites de terceiros, cujos links ou propaganda sejam
              eventualmente disponibilizados na Plataforma."
            />
            <SecondLevelList
              topListCount={7}
              currentListCount={4}
              text="Quaisquer dados ou conteúdo publicamente postados ou transmitidos privativamente
              através da Plataforma ou em ligação com as Ferramentas nela disponibilizadas são de
              exclusiva responsabilidade da pessoa que tenha originado tal conteúdo, e o seu acesso a
              todos esses dados e conteúdo é em seu próprio risco. A Conecta não se responsabiliza por
              quaisquer erros ou omissões nos referidos dados ou conteúdos, nem tampouco por
              quaisquer perdas ou danos que qualquer Usuário eventualmente venha a sofrer em
              conexão com tais dados ou conteúdo."
            />
          </li>
          <li style={{ paddingTop: "1rem" }}>
            <strong>PROPRIEDADE INTELECTUAL</strong>
            <SecondLevelList
              topListCount={8}
              currentListCount={1}
              text="Todos os textos, ilustrações, imagens, vídeos, ícones, tecnologias, links e demais
              conteúdos audiovisuais ou sonoros, incluindo o software da Plataforma, desenhos gráficos e
              códigos fonte, são de propriedade exclusiva da Conecta ou de terceiros que tenham
              autorizado sua utilização na Plataforma, estando protegidos pelas leis, regulamentações e
              tratados internacionais, vedada sua cópia, reprodução, modificação, descodificação,
              transmissão ou qualquer outro tipo de utilização sem prévia autorização da Conecta."
            />
            <SecondLevelList
              topListCount={8}
              currentListCount={2}
              text="O Usuário declara-se ciente de que qualquer utilização indevida do espaço e das
              Ferramentas disponibilizadas na Plataforma poderá representar, além da violação de
              direitos de propriedade intelectual da Conecta, violação dos direitos de propriedade
              intelectual de terceiros, sendo de responsabilidade do Usuário a reparação de danos e
              despesas incorridas nesse sentido pela Conecta e/ou por quaisquer terceiros."
            />
          </li>
          <li style={{ paddingTop: "1rem" }}>
            <strong>ALTERAÇÃO DOS TERMOS DE USO</strong>
            <SecondLevelList
              topListCount={9}
              currentListCount={1}
              text="Estes Termos de Uso poderão ser alterados pela Conecta a qualquer momento,
              devendo o Usuário ser notificado, através da Plataforma, acerca das alterações que serão
              realizadas, com uma antecedência mínima de 15 (quinze) dias da data de sua publicação
              na Plataforma e início de sua vigência."
              terms={[
                "As alterações realizadas entrarão em vigor no momento de sua publicação na Plataforma.",
                "O uso continuado ou acesso à Plataforma após a publicação das alterações nos Termos de Uso constituem aceitação tácita das alterações e dos novos Termos de Uso.",
                "Caso o Usuário deseje verificar qual a versão dos Termos de Uso vigente no momento de sua consulta, basta verificar tal informação no box “Atualizado pela última vez em” localizado na parte superior destes Termos de Uso.",
              ]}
            />
            <SecondLevelList
              topListCount={9}
              currentListCount={2}
              text="A Conecta poderá realizar quaisquer alterações na Plataforma SEM A NECESSIDADE
              DE COMUNICAÇÃO PRÉVIA AO USUÁRIO, desde que não implique alteração destes
              Termos de Uso. Qualquer modificação da Plataforma que implique alteração destes Termos
              de Uso será previamente comunicada ao Usuário, nos termos desta Cláusula 9."
            />
          </li>
          <li style={{ paddingTop: "1rem" }}>
            <strong>CESSÃO</strong>
            <SecondLevelList
              topListCount={10}
              currentListCount={1}
              text="O Usuário não poderá ceder e, de nenhuma forma, transferir, total ou parcialmente, os
              direitos e obrigações constantes destes termos de uso sem o prévio consentimento por
              escrito da Conecta. A Conecta poderá transferir os direitos e obrigações decorrentes destes
              Termos de Uso em casos de venda parcial ou total dos seus ativos, alteração de controle,
              reestruturação societária e outras formas de fusão, cisão ou incorporação."
            />
          </li>
          <li style={{ paddingTop: "1rem" }}>
            <strong>NOTIFICAÇÕES</strong>
            <SecondLevelList
              topListCount={11}
              currentListCount={1}
              text="Todas as comunicações, avisos ou notificações relacionadas a estes Termos de Uso
              (“Notificações”) deverão ser enviadas por escrito, por qualquer dos seguintes meios:
              (i) por meio de mensagens através da Plataforma;
              (ii) por meio de correspondência eletrônica (e-mail); ou
              (iii) por meio de mensagens através do aplicativo WhatsApp."
              terms={[
                "A Conecta utilizará os dados do Usuário cadastrados em sua Conta, para fins de envio das Notificações, nos termos desta Cláusula 11.",
                "O Usuário deverá utilizar os endereços destacados abaixo para envio de Notificações à Conecta nos termos desta Cláusula 11: Endereço: Rua Paraíba nº 1352, sala 903, Bairro Savassi, Belo Horizonte/MG E-mail: contato@appnoz.com.br",
                "Em caso de alteração dos endereços ou e-mail para os quais a Conecta deva enviar as Notificações, o Usuário deverá atualizar os dados da sua Conta.",
                "Caso o Usuário não comunique as alterações de endereço realizadas, as Notificações enviadas para os endereços indicados nas cláusulas 11.1.1 e 11.1.2 serão consideradas efetuadas, ainda que o aviso ou a confirmação de recebimento não indiquem a efetiva entrega ao destinatário."
              ]}
            />
          </li>
          <li style={{ paddingTop: "1rem" }}>
            <strong>VIGÊNCIA</strong>
            <SecondLevelList
              topListCount={12}
              currentListCount={1}
              text="Estes Termos e Condições de Uso permanecerão vigentes enquanto o Usuário
              mantiver uma Conta de acesso à Plataforma."
            />
          </li>
          <li style={{ paddingTop: "1rem" }}>
            <strong>DISPOSIÇÕES GERAIS</strong>
            <SecondLevelList
              topListCount={13}
              currentListCount={1}
              text="Estes Termos de Uso constituem o acordo integral entre a Conecta e o Usuário com
              relação à utilização da Plataforma, e substituem todos os acordos anteriores, escritos ou
              verbais."
            />
            <SecondLevelList
              topListCount={13}
              currentListCount={2}
              text="Caso qualquer disposição contida nestes Termos de Uso seja considerada inválida,
              ilegal ou inexequível sob qualquer aspecto, a validade, a legalidade ou a exequibilidade das
              demais disposições contidas nestes Termos de Uso não serão de forma alguma afetadas ou
              prejudicadas em decorrência de tal fato. Nesse caso, as partes se comprometem a
              substituir a disposição inválida por outra disposição que atenda aos objetivos buscados por
              estes Termos de Uso, de modo a tornála válida, sem que se desvirtue o objetivo buscado
              pelas partes nestes Termos de Uso."
            />
            <SecondLevelList
              topListCount={13}
              currentListCount={3}
              text="O não exercício de qualquer direito previsto nestes Termos de Uso, na primeira
              ocasião em que seria possível fazê-lo, não implicará renúncia a tais direitos, nem sua
              preclusão, salvo se disposto de forma diversa nestes Termos de Uso. A eventual tolerância
              de qualquer infração a estes Termos de Uso não significará que qualquer infração posterior,
              ainda que da mesma natureza, será tolerada."
            />
            <SecondLevelList
              topListCount={13}
              currentListCount={4}
              text="Estes Termos de Uso obrigam a Conecta, o Usuário, seus herdeiros, sucessores e
              cessionários autorizados, independentemente do motivo que tenha ocasionado a sucessão."
            />
            <SecondLevelList
              topListCount={13}
              currentListCount={5}
              text="Salvo se expressamente disposto em contrário, nenhuma das disposições aqui
              implícitas ou explícitas devem ser consideradas ou interpretadas como se conferissem a
              terceiro, que não sejam a Conecta ou o Usuário, qualquer direito ou prerrogativa por força
              destes Termos de Uso."
            />
          </li>
          <li style={{ paddingTop: "1rem" }}>
            <strong>LEGISLAÇÃO APLICÁVEL E RESOLUÇÃO DE CONTROVÉRSIAS</strong>
            <SecondLevelList
              topListCount={14}
              currentListCount={1}
              text="Estes Termos de Uso serão regidos e interpretados de acordo com as leis da
              República Federativa do Brasil."
            />
            <SecondLevelList
              topListCount={14}
              currentListCount={2}
              text="Fica eleito o foro do domicílio do Usuário como o único competente para dirimir
              eventuais controvérsias referentes à interpretação e ao cumprimento destes Termos de Uso,
              com renúncia de todos os outros, por mais especiais ou privilegiados que sejam."
            />
          </li>
        </ol>
      </S.ContainerPrivacyPolicy>
    );
  }

  else if(style.names.nameApp === "somosmachado"){
    return (
      <S.ContainerPrivacyPolicy>
        <h6>TERMOS DE USO E POLÍTICA DE PRIVACIDADE</h6>
        <span className="subtitle">
          O funcionário ao criar a conta no aplicativo {returnNameAppByKey()}{" "}
          aceita os seguintes termos:
        </span>
        <ol className="list">
          <li>
            {returnNameAppByKey()} é uma ferramenta personalizada e customizada
            especificamente para os colaboradores da empresa. Trata-se de uma
            plataforma de engajamento corporativo, comunicação interna e
            fortalecimento de cultura organizacional, com objetivo único de gerar
            maior participação e agilidade da transmissão da informação as equipes
            e colaboradores da empresa. A ferramenta deve/irá agregar a rotina, a
            carreira e ao desenvolvimento pessoal dos colaboradores. O foco é
            gerar maior integração entre as equipes e promover maior
            interatividade e tecnologia a serviço do colaborador.
          </li>
          <li>
            O aplicativo visa a trabalhar o apoio ao colaborador dentro da empresa
            e o desenvolvimento da sua carreira no que se refere a comunicação
            corporativa, engajamento e cultura organizacional.
          </li>
          <li>
            Não é obrigatório o uso do aplicativo. Seu uso é recomendado para que
            o colaborador possa acompanhar o andamento e comunicados internos da
            empresa.
          </li>
          <li>
            O colaborador deve utilizar essa ferramenta de acordo com as normas e
            regimento interno da empresa, sendo que o uso é de sua inteira
            responsabilidade. O colaborador se declara ciente de que pode ser
            aplicadas medidas disciplinares pelo mau uso do aplicativo.
          </li>
          <li>
            A todo momento, a empresa busca o desenvolvimento, a satisfação e a
            retenção dos colaboradores através de iniciativas que aumentem o
            engajamento da relação empresa/funcionário.
          </li>
          <li>O colaborador concorda com os termos de uso da ferramenta.</li>
          <li>
            A Empresa reserva-se no direito de buscar alternativas e ferramentas
            externas para complementar a rotina e o trabalho dos colaboradores,
            respeitando as jornadas de trabalho definidas em lei e os períodos
            destinados à férias e descanso. Reitera-se que o uso do aplicativo é
            opcional, não vinculando o empregado o seu uso obrigatório.
          </li>
          <li>
            O uso ou veiculação indevida da ferramenta que esteja em desacordo com
            as normas da empresa, o qual se declara ciente e de acordo,
            configura-se como transgressão e descumprimento às políticas e normas
            internas da empresa e poderá acarretar a aplicação de medida
            disciplinar ao empregado. Não é permitido o compartilhamento do
            conteúdo veiculado no aplicativo em outras redes sociais ou pessoas
            que não façam parte da empresa. Caso haja o compartilhamento indevido
            de informações privativas da empresa veiculada no aplicativo, o
            empregado poderá ser responsabilizado nas esferas civil e criminal,
            tendo de arcar com os danos e prejuízos causados à empresa.
          </li>
          <li>
            A Plataforma disponibilizada atende a várias empresas, sem no entanto,
            configurar qualquer relação entre elas.
          </li>
          <li>
            A demissão/desligamento do colaborador da empresa resulta no bloqueio
            do uso da plataforma ou divulgação desta.
          </li>
        </ol>
      </S.ContainerPrivacyPolicy>
    );
  }

  return(
    <></>
  )
};

export default PrivacyPolicy;
