import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { style } from "style";
import { v1 as uuidv1 } from "uuid";

import Flex from "components/core/Grid/Flex";
import { PageTitle } from "components/core/Typography/Typography";
import { history } from "helpers/sharedHelpers";
import urls from "utils/constants/urls";

import Skeleton from "react-loading-skeleton";
import { Creators as AcademyActions } from "store/ducks/academy";
import MyCoursesSkeleton from "./MyCoursesSkeleton";

import * as S from "./MyCoursesStyle";
import {
  CourseProgress,
  CourseProgressPointer,
  CourseProgressCount,
} from "../SidebarAcademyStyle";

const MyCourses = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { myCourses, isLoadingMyCourses } = useSelector(
    ({ academy }) => academy,
  );

  const startCourseProgress = 138;

  useEffect(() => {
    dispatch(AcademyActions.getMyCourses());
  }, [dispatch]);

  return (
    <Flex flex={3} flexDirection="column" width="100%">
      <PageTitle title={intl.messages["academy.sidebar.title"]} fontSize={3}>
        {isLoadingMyCourses ? (
          <Skeleton duration={0.8} width={66} height={20} />
        ) : (
          <S.TitleAmountCourse>
            {!style.variables.child_profiles?.length > 0 && myCourses.length > 1
              ? `${myCourses.length} Cursos`
              : `${myCourses.length} Curso`}
          </S.TitleAmountCourse>
        )}
      </PageTitle>
      {isLoadingMyCourses ? (
        <MyCoursesSkeleton />
      ) : (
        <S.MyCourses flexDirection="column" p={3} spaceChildren={20}>
          {myCourses?.map(({ id, title, user_status: userStatus }) => (
            <S.SidebarCourse
              key={uuidv1()}
              onClick={() => history.push(`${urls.ROUTES.ACADEMY}/${id}`)}
              completed={userStatus?.status === "finished"}
            >
              <Flex flexDirection="column" justifyContent="space-between">
                <S.SidebarCourseTitle>{title}</S.SidebarCourseTitle>
              </Flex>
              <CourseProgress>
                <CourseProgressPointer
                  progress={
                    startCourseProgress -
                    (startCourseProgress * userStatus?.progress_rate) / 100
                  }
                >
                  <circle cx={22} cy={22} r={22} />
                  <circle cx={22} cy={22} r={22} />
                </CourseProgressPointer>
                <CourseProgressCount>
                  {parseInt(userStatus?.progress_rate, 10)}%
                </CourseProgressCount>
              </CourseProgress>
            </S.SidebarCourse>
          ))}
        </S.MyCourses>
      )}
    </Flex>
  );
};

export default MyCourses;
