import styled from "styled-components";
import theme from "styles/theme";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const Title = styled.p`
  color: ${theme.colors.backText};
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
`;

export const Subtitle = styled.p`
  color: #ee7202;
  font-size: ${(props) => (props.small ? "16px" : "24px")};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
`;

export const Introduction = styled.div`
  color: ${theme.colors.gray};
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  max-width: 606px;

  margin: 25px 0;
`;

export const Accordion = styled.div`
  margin-bottom: 10px;
  max-width: 880px;
  width: 100%;
  border-radius: ${(props) => (props.active ? "5px 5px 20px 20px" : "5px")};
  background: ${theme.colors.paleGrey};
`;

export const AccordionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 26px 12px 16px;
  cursor: pointer;

  span {
    color: ${theme.colors.backText};
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  img {
    transform: ${(props) => (props.active ? "rotate(180deg)" : "rotate(0)")};
    margin-left: 5px;
  }
`;

export const AccordionContent = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
  border-radius: 0px 0px 20px 20px;
  border: 0.5px solid ${theme.colors.grayDefaultColor};
  background: #fff;
  padding: 16px;

  color: ${theme.colors.gray};
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;

  a {
    color: ${theme.colors.textConfig};
    font-weight: 500;
    text-decoration: underline;
  }
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 25px;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

export const Button = styled.button`
  width: 211px;
  height: 46px;
  flex-shrink: 0;
  border-radius: 24px;
  background: linear-gradient(45deg, #fd9334 0%, #ee7202 100%);
  color: #fff;
  text-align: center;
  /* font-family: Rubik; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  border: none;
  margin-top: 20px;
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 52px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  gap: 4px;

  span {
    color: #8c91a9;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
  }

  .container {
    max-width: 354px;
    padding: 0;
    margin: 0;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .control {
    background: ${theme.colors.paleGrey};
    border: none;
    border-radius: 8px;
    height: 48px;
    box-shadow: none;
    cursor: pointer;

    &:has(+ .menu) {
      border: 1px solid #ee7202;

      .singleValue {
        color: #8c91a9;
      }

      .dropdownIndicator {
        transform: rotate(180deg);
      }
    }

    .dropdownIndicator {
      padding: 18px;
    }
  }

  .control:hover {
    border: 1px solid #ee7202;
  }

  .singleValue {
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    font-size: 18px;
  }

  .ValueContainer {
    padding: 12px 16px;
  }

  .indicatorSeparator {
    display: none;
  }

  .menu {
    margin-top: 15px;
    border-radius: 8px;
    background: ${theme.colors.paleGrey};
    box-shadow: 0px 4px 4px 5px rgba(0, 0, 0, 0.25);
    padding: 10px 0 10px 0;
  }

  .option {
    background-color: ${theme.colors.paleGrey};
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: ${theme.colors.backText};
    cursor: pointer;
    margin: 2px 0;

    &[aria-selected="true"] {
      background-color: #cdcfd3;
    }
  }

  .option:hover {
    background-color: #cdcfd3;
  }
`;

export const Select = styled.select`
  max-width: 343px;
  height: 48px;
  border-radius: 8px;
  background: ${theme.colors.paleGrey};
  color: #8c91a9;
  padding: 0 18px 0 16px;
  font-family: "Rubik", sans-serif;
  border: none;

  &:focus {
    border: 1px solid #ee7202;
  }
`;
