/* eslint-disable react/prop-types */
import React, { useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Creators as ActionGroupPardini } from "store/ducks/groupPardini";

import Portal from "templates/Portal";
import { PageTitle } from "components/core/Typography/Typography";
import DetailsComponent from "components/presentation/GroupPardini/DetailsComponent";

import SkeletonSubMenuPardini from "../../GroupPardini/SubMenuPardini/SkeletonSubMenuPardini/SkeletonSubMenuPardini";

const SubAboutUs = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isLoading, detailsPageGroupPardini } = useSelector(
    (state) => state.groupPardini,
  );
  const { title, contents } = detailsPageGroupPardini;

  const dispatchGetPardiniDetails = useCallback(() => {
    dispatch(ActionGroupPardini.getPardiniDetails(2, id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatchGetPardiniDetails();
  }, [dispatchGetPardiniDetails]);

  return (
    <Portal>
      <PageTitle title={title} />
      {isLoading ? (
        <SkeletonSubMenuPardini />
      ) : (
        <DetailsComponent contentsList={contents} />
      )}
    </Portal>
  );
};

export default SubAboutUs;
