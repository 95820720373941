import styled from "styled-components";
import css from "@styled-system/css";
import Flex from "components/core/Grid/Flex";

export const ContainerCarousel = styled(Flex)(
  css({
    ".slider": {
      ".slider-control-centerleft, .slider-control-centerright": {
        button: {
          m: 3,
          width: 37,
          height: 37,
          p: "0 !important",
          alignItems: "center",
          borderRadius: "100%",
          justifyContent: "center",
        },
      },
      ".slider-control-bottomcenter": {
        position: "relative !important",
        display: "inline-block",
      },
    },
  }),
  ({ showButtonNext }) =>
    showButtonNext
      ? css({
          ".slider": {
            ".slider-control-centerleft, .slider-control-centerright": {
              visibility: "visible",
            },
          },
        })
      : css({
          ".slider": {
            ".slider-control-centerleft, .slider-control-centerright": {
              visibility: "hidden",
            },
          },
        }),
);

export const IncludesSlide = styled("div")(
  css({
    position: "absolute",
    bottom: "5px",
    transform: "translateX(-50%)",
  }),
);

export const ItensLiSlide = styled("div")(
  css({
    m: 0,
    width: 8,
    height: 8,
    borderRadius: "100%",
  }),
  ({ currentSlide, index }) =>
    currentSlide === index
      ? css({ backgroundColor: "black" })
      : css({ backgroundColor: "rgba(0, 0, 0, 0.2)" }),
);

export const LiSlide = styled("li")(
  css({
    m: "0 3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
);

export const UlSlide = styled("ul")(
  css({
    p: 1,
    height: "100%",
    display: "flex",
    borderRadius: 30,
  }),
);
