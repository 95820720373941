import React, { createContext, useContext, useState } from "react";

const CalendarContext = createContext({});

export const CalendarProvider = ({ children }) => {
  const [sideBarDetails, setSideBarDetails] = useState(false);
  return (
    <CalendarContext.Provider value={{ sideBarDetails, setSideBarDetails }}>
      {children}
    </CalendarContext.Provider>
  );
};

export const useCalendarContext = () => {
  const context = useContext(CalendarContext);

  return context;
};
