import styled from "styled-components";
import css from "@styled-system/css";
import { style } from "style";

export const ContainerWeekScore = styled("div")(
  css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    svg: {
      height: "90px",
      ".cls-2": {
        fill: style.colors.primaryColor,
        stroke: "none",
      },
    },
    img: {
      height: "80px",
    },
  }),
);
