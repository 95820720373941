import styled from "styled-components";
import css from "@styled-system/css";
import theme from "styles/theme";
import Flex from "components/core/Grid/Flex";

export const IconDay = styled("div")(
  css({
    width: 7,
    height: 7,
    borderRadius: "50%",
    margin: "10px 2px",
  }),
  ({ subtitle }) =>
    subtitle &&
    css({
      width: 10,
      height: 10,
      margin: "3px 4px",

      "@media (min-width: 281px)": {
        width: 15,
        height: 15,
        margin: "3px 6px",
      },
    }),
  ({ kind }) =>
    kind === "common" &&
    css({
      backgroundColor: "secondaryColor",
    }),
  ({ kind }) =>
    kind === "birthdate" &&
    css({
      backgroundColor: "yellow",
    }),
  ({ kind }) =>
    kind === "holiday" &&
    css({
      backgroundColor: "red",
    }),
);

export const DateEvent = styled("h4")(
  css({
    fontSize: 12,
    color: "#8C91A9",
    marginBottom: "4px",
  }),
);

export const ContainerCalendar = styled(Flex)(
  css({
    display: "none",
    "@media (max-width: 768px)": {
      padding: 4,
      display: "block",
      borderRadius: "16px 16px 0 0",
      margin: "28px 16px 0 16px",
      backgroundColor: "paleGrey",
      h4: {
        textTransform: "uppercase",
      },
      h3: {
        fontSize: 18,
        color: "backText",
      },
    },
  }),
);

export const EventToday = styled("div")(
  css({
    mt: 16,
    paddingTop: 16,
    borderTop: "0.5px solid",
    borderColor: "grayMedium",
    h2: {
      fontSize: 18,
      fontFamily: "text",
      textAlign: "center",
      color: "grayDefaultColor",
    },
  }),
);

export const SideContainerLeft = styled(Flex)(
  css({
    paddingLeft: 8,
    marginBottom: 4,
    borderLeft: "5px solid",
    flexDirection: "column",
    borderColor: "secondaryColor",
    h3: {
      m: 0,
      fontSize: 18,
      color: "secondaryColor",
    },
  }),
);

export const SubtitleContainer = styled("div")(
  css({
    marginLeft: 2,
    alignContent: "row",
    display: "flex",
    maxWidth: "95vw",
    flexWrap: "wrap",
  }),
);

export const Subtitle = styled("h3")(
  css({
    fontFamily: "text",
    fontSize: 10,
    marginRight: 10,

    "@media (min-width: 268px)": {
      fontSize: 16,
      marginRight: 20,
    },
  }),
  ({ black }) => css({ color: black ? "backText" : "grayDark" }),
);

export const StyleCalender = styled("div")(
  css({
    marginTop: 16,
    ".react-calendar": {
      width: "100%",
      border: "none",
      "abbr[data-original-title], abbr[title]": {
        textDecoration: "none",
        cursor: "initial",
      },
      ".react-calendar__navigation": {
        fontSize: "18px",
        fontWeight: "600",
        lineHeight: "24px",
        fontStyle: "normal",
        fontFamily: "'RubikMedium', sans-serif",
        ".react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button": {
          display: "none",
        },
        button: {
          minWidth: 25,
          color: "gray",
          ":hover, :focus": {
            opacity: "0.8",
            color: "secondaryColor",
            backgroundColor: "initial",
          },
        },
        ".react-calendar__navigation__label": {
          color: "secondaryColor",
          flexGrow: "0 !important",
          textTransform: "uppercase",
        },
        ".react-calendar__navigation__arrow": {
          margin: "4px",
          borderRadius: "8px",
          padding: "4px 14px",
          backgroundColor: "blueLight",
          ":hover, :focus": {
            margin: "4px",
            borderRadius: "8px",
            padding: "4px 14px",
            backgroundColor: "blueLight",
          },
        },
      },
      ".react-calendar__month-view, .react-calendar__tile": {
        color: "grayDark",
      },
      ".react-calendar__month-view__days__day": {
        width: "100px",
        height: "92px",
        border: "0.44px solid #efefef",
        textAlign: "end",
      },
      ".react-calendar__month-view__days__day--neighboringMonth": {
        backgroundColor: "grayLight",
      },
      ".react-calendar__tile--active": {
        background: "initial",
        abbr: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "auto",
          color: "white",
          background: `${theme.colors.primaryColor}`,
          borderRadius: "100%",
          width: "26px",
          height: "26px",
        },
      },
      ".react-calendar__tile--hasActive": {
        background: `${theme.colors.blueLight}`,
      },
    },
  }),
);
