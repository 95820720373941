import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import urls from "utils/constants/urls";
import { history } from "helpers/sharedHelpers";
import { Creators as UserActions } from "store/ducks/user";

import Portal from "templates/Portal";
import Flex from "components/core/Grid/Flex";
import { Input } from "components/core/Form/Form";
import Button from "components/core/Button/Button";
import { PageTitle } from "components/core/Typography/Typography";

import * as S from "./ChangePasswordStyle";

const ChangePassword = () => {
  const intl = useIntl();
  const [errorPass, setErrorPass] = useState({
    current: null,
    new: null,
  });
  const { isLoadingPassword, currentPassError } = useSelector(
    (state) => state.user,
  );
  const [password, setPassword] = useState({
    current: "",
    new: "",
    confirm: "",
  });

  const dispatch = useDispatch();
  const updatePassword = (e) => {
    e.preventDefault();
    if (password.current) {
      setErrorPass({ ...errorPass, current: null });
      if (password.new && password.confirm) {
        if (password.new === password.confirm) {
          setErrorPass({ ...errorPass, new: null });
          dispatch(UserActions.updatePassword(password));
        } else
          setErrorPass({
            new: intl.messages["settings.textPage.passwordsDoNotMatch"],
          });
      } else
        setErrorPass({
          new: intl.messages["settings.textPage.informPasswords"],
        });
    } else
      setErrorPass({
        ...errorPass,
        current: intl.messages["settings.textPage.informCurrentPassword"],
      });
  };

  return (
    <Portal
      contextSearch={{
        contextName: "Configurações",
        contextAction: () =>
          alert("Busca de configuração ainda não implementada"),
      }}
    >
      <S.UpdatePasswordContainer flexDirection="column" paddingBottom>
        <form onSubmit={updatePassword}>
          <PageTitle title={intl.messages["settings.textPage.updatePassword"]}>
            <Flex alignItems="center" spaceChildren={3}>
              <S.UpdatePasswordHeader>
                <Button
                  name={intl.messages["general.buttons.cancel"]}
                  variant={["transparent"]}
                  action={() => history.push(`${urls.ROUTES.CONFIGURATIONS}`)}
                />
                <Button
                  type="submit"
                  name={intl.messages["general.buttons.update"]}
                  isLoading={isLoadingPassword}
                />
              </S.UpdatePasswordHeader>
            </Flex>
          </PageTitle>
          <Flex maxWidth={480} p={3} flexDirection="column" spaceChildren={3}>
            <Input
              type="password"
              name="currentPassword"
              placeholder={`${intl.messages["settings.textPage.typeYourCurrentPassword"]}`}
              onChange={(e) =>
                setPassword({ ...password, current: e.target.value })
              }
              label={intl.messages["settings.currentPassword"]}
              error={
                currentPassError
                  ? intl.messages["settings.textPage.incorrectCurrentPassword"]
                  : errorPass.current
              }
            />

            <Input
              type="password"
              name="newPassword"
              placeholder={`${intl.messages["settings.textPage.typeYourNewPassword"]}`}
              label={intl.messages["settings.newPassword"]}
              onChange={(e) =>
                setPassword({ ...password, new: e.target.value })
              }
              error={errorPass.new}
            />

            <Input
              type="password"
              name="confirmPassword"
              placeholder={`${intl.messages["settings.textPage.typeYourConfirmPassword"]}`}
              label={intl.messages["settings.confirmPassword"]}
              onChange={(e) =>
                setPassword({ ...password, confirm: e.target.value })
              }
              error={errorPass.new}
            />
          </Flex>
        </form>
      </S.UpdatePasswordContainer>
    </Portal>
  );
};

export default ChangePassword;
