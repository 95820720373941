import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ModalProvider } from "styled-react-modal";
import Skeleton from "react-loading-skeleton";
import Pagination from "react-js-pagination";

import Avatar from "components/core/Avatar/Avatar";

import { Creators as PublicationReactionActions } from "store/ducks/reactions";

import { ReactComponent as IconClose } from "assets/img/icons/iconClose.svg";

import iconArrowDown from "assets/img/icons/iconArrowDown.svg";
import { reactionsList } from "../../../../utils/Reactions/reactions";

import * as AlertModalStyle from "../../../../components/core/AlertModal/AlertModalStyle";
import * as S from "./ReactionsViewModalStyles";

const UsersLikeLoadMoreSkeleton = () => (
  <div
    style={{
      width: "100%",
      padding: "20px 30px",
    }}
  >
    <div
      style={{
        width: "100%",
        paddingBottom: "20px ",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div style={{ width: "100%" }}>
        <Skeleton duration={0.8} width={150} height={30} />
      </div>
      <Skeleton duration={0.8} width={20} height={20} />
    </div>
    <Skeleton duration={0.8} height={1} />
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: "20px ",
      }}
    >
      <Skeleton duration={0.8} width={150} height={30} />
      <Skeleton duration={0.8} width={20} height={20} />
    </div>
    <Skeleton duration={0.8} height={1} />

    {Array.from({ length: 4 }).map((_, index) => (
      <>
        <div
          key={index}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "10px",
          }}
        >
          <div style={{ display: "flex", gap: "20px" }}>
            <Skeleton circle duration={0.8} width={50} height={50} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "baseline",
                justifyContent: "center",
              }}
            >
              <Skeleton duration={0.8} width={150} height={20} />
              <Skeleton duration={0.8} width={100} height={20} />
            </div>
          </div>
          <Skeleton circle duration={0.8} width={50} height={50} />
        </div>
        <Skeleton duration={0.8} height={1} />
      </>
    ))}
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: "10px",
      }}
    >
      <Skeleton duration={0.8} width={100} height={25} />
      <Skeleton duration={0.8} width={150} height={25} />
    </div>
  </div>
);

const ReactionsViewModal = ({
  id,
  setShouldSaveAllReactions,
  shouldSaveAllReactions,
  openReactionsModal,
  isReactionsLoading,
  setOpenReactionsModal,
  setTypeOfReaction,
  typeOfReaction,
  allReactions,
  reactions,
  isDesktop,
}) => {
  const dispatch = useDispatch();

  const [accordionActive, setAccordionActive] = useState(false);
  const [reactionSelected, setReactionSelected] = useState("");
  const isPaginationDisabled = reactions?.pagination?.total_count <= 5;

  const typeOfReactionsToShow = !!reactions?.canvas_likes
    ? reactions?.canvas_likes
    : reactions?.canvas_comment_reactions;

  const typeOfReactionsUnpaginatedToShow = !!allReactions?.canvas_likes
    ? allReactions?.canvas_likes
    : allReactions?.canvas_comment_reactions;

  const allReactionsPaginated =
    typeOfReactionsToShow &&
    typeOfReactionsToShow.reduce((acc, item) => {
      const { reaction, user } = item;
      acc[reaction] = [...(acc[reaction] || []), user];
      return acc;
    }, {});

  const allReactionsUnpaginated =
    typeOfReactionsUnpaginatedToShow &&
    typeOfReactionsUnpaginatedToShow.reduce((acc, item) => {
      const { reaction, user } = item;
      acc[reaction] = [...(acc[reaction] || []), user];
      return acc;
    }, {});

  const handleAllReactionsToShow = (payload) => {
    const { reaction, perPage } = payload;

    setAccordionActive(false);
    setReactionSelected(reaction);

    if (!!allReactions?.canvas_likes) {
      dispatch(
        PublicationReactionActions.getPublicationAllReactions({
          id,
          reaction,
          page: 1,
          per_page: perPage,
        }),
      );
    } else {
      dispatch(
        PublicationReactionActions.getPublicationAllCommentsReactions({
          id,
          reaction,
          page: 1,
          per_page: perPage,
        }),
      );
    }
  };

  const handleReactionsToShow = (payload) => {
    const { reaction, currentPage } = payload;

    setAccordionActive(false);
    setReactionSelected(reaction);

    if (!!reactions?.canvas_likes) {
      dispatch(
        PublicationReactionActions.getPublicationReactions({
          id,
          reaction,
          page: currentPage || 1,
          per_page: 5,
        }),
      );
    } else {
      dispatch(
        PublicationReactionActions.getPublicationCommentsReactions({
          id,
          reaction,
          page: currentPage || 1,
          per_page: 5,
        }),
      );
    }
  };

  return (
    <ModalProvider>
      <AlertModalStyle.StyledModal
        data-testid="modal-component"
        isOpen={openReactionsModal}
        type={false}
        style={{
          padding: "0px",
          justifyContent: "space-between",
          maxWidth: "711px",
          width: "100%",
        }}
      >
        <AlertModalStyle.ContainerModal
          style={{
            width: "100%",
            maxHeight: "45rem",
          }}
        >
          {isReactionsLoading ? (
            <UsersLikeLoadMoreSkeleton />
          ) : (
            <>
              <S.FistLineContainer>
                <S.IconAndTitleContainer>
                  <span>
                    Reações ao{" "}
                    {typeOfReaction === "post" ? "post" : "comentário"}
                  </span>
                </S.IconAndTitleContainer>
                <IconClose
                  onClick={() => {
                    setOpenReactionsModal(false);
                    setTypeOfReaction("");
                  }}
                />
              </S.FistLineContainer>

              <S.Accordion active={accordionActive}>
                {" "}
                <S.AccordionTitle
                  onClick={() => setAccordionActive(!accordionActive)}
                  active={accordionActive}
                >
                  {/* Título do Acordeon */}
                  <S.ContainerReactions>
                    {reactionSelected === "" && allReactionsUnpaginated
                      ? Object.keys(allReactionsUnpaginated).map(
                          (reaction, index, array) => (
                            <Avatar
                              url={reactionsList[reaction]?.icon}
                              size={!isDesktop ? "30px" : "40px"}
                              style={{
                                zIndex: array.length - index,
                              }}
                            />
                          ),
                        )
                      : Object.keys(allReactionsPaginated).map(
                          (reaction, index, array) => (
                            <Avatar
                              url={reactionsList[reaction]?.icon}
                              size={!isDesktop ? "30px" : "40px"}
                              style={{
                                zIndex: array.length - index,
                              }}
                            />
                          ),
                        )}
                  </S.ContainerReactions>
                  <S.ReactionsCountContainer active={accordionActive}>
                    <S.ReactionsCount isDesktop={isDesktop}>
                      <span style={{ fontWeight: "400" }}>
                        {reactionSelected === ""
                          ? "Todas"
                          : reactionsList[Object.keys(allReactionsPaginated)]
                              ?.title}
                      </span>
                      <span>{reactions?.pagination?.total_count}</span>
                    </S.ReactionsCount>
                    <img src={iconArrowDown} alt="Arrow" />
                  </S.ReactionsCountContainer>
                </S.AccordionTitle>
                <S.AccordionContent active={accordionActive}>
                  {/* Primeira opção do Acordeon que sempre vai ser "TODOS" */}
                  <S.ReactionsListIndividual
                    onClick={() => {
                      handleAllReactionsToShow({
                        reaction: "",
                        perPage: allReactions?.pagination?.total_count,
                      });
                      handleReactionsToShow({
                        reaction: "",
                        currentPage: 1,
                        perPage: 5,
                      });
                      setReactionSelected("");
                      setShouldSaveAllReactions(true);
                    }}
                  >
                    <S.ContainerReactions>
                      {allReactionsUnpaginated &&
                        Object.keys(allReactionsUnpaginated).map(
                          (reaction, index, array) => (
                            <Avatar
                              url={reactionsList[reaction]?.icon}
                              size={!isDesktop ? "30px" : "40px"}
                              style={{
                                zIndex: array.length - index,
                              }}
                            />
                          ),
                        )}
                    </S.ContainerReactions>
                    <S.ReactionsCount
                      style={{
                        width: " 100%",
                        justifyContent: "space-between",
                        marginLeft: "10px",
                      }}
                    >
                      <span>{allReactions?.pagination?.total_count}</span>
                      <span style={{ fontWeight: "400" }}>Todas</span>
                    </S.ReactionsCount>
                  </S.ReactionsListIndividual>
                  {/* Outras opções do Acordeon */}
                  {allReactionsUnpaginated &&
                    Object.keys(allReactionsUnpaginated).map(
                      (reaction, index, array) => (
                        <S.ReactionsListIndividual
                          onClick={() => {
                            handleReactionsToShow({ reaction });
                            setReactionSelected(reaction);
                            setShouldSaveAllReactions(false);
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              url={reactionsList[reaction]?.icon}
                              size={!isDesktop ? "30px" : "40px"}
                              style={{
                                zIndex: array.length - index,
                              }}
                            />
                            <span>
                              {allReactionsUnpaginated &&
                                allReactionsUnpaginated[reaction].length}
                            </span>
                          </div>
                          <span style={{ fontWeight: "400" }}>
                            {reactionsList[reaction].title}
                          </span>
                        </S.ReactionsListIndividual>
                      ),
                    )}
                </S.AccordionContent>
              </S.Accordion>

              <S.ReactionsList>
                <div>
                  {typeOfReactionsToShow?.map((reaction) => (
                    <S.ReactionsListContent>
                      <S.ReactionsListUsersInfo>
                        <Avatar
                          url={
                            typeOfReaction === "post"
                              ? reaction.user.user_image.url
                              : reaction.user.image.url
                          }
                          size={!isDesktop ? "50px" : "60px"}
                        />
                        <S.NameAndSectorContainer>
                          <h5>
                            {typeOfReaction === "post"
                              ? reaction.user.user_name
                              : reaction.user.name}
                          </h5>
                          <span>
                            {typeOfReaction === "post"
                              ? reaction.user.user_sector
                              : reaction.user.sector}
                          </span>
                        </S.NameAndSectorContainer>
                      </S.ReactionsListUsersInfo>
                      <Avatar
                        url={reactionsList[reaction.reaction].icon}
                        size={!isDesktop ? "30px" : "40px"}
                      />
                    </S.ReactionsListContent>
                  ))}
                </div>

                <S.Pagination isDesktop={isDesktop}>
                  <span>
                    {reactions?.pagination?.current_page * 5 - (5 - 1)} -{" "}
                    {reactions?.pagination?.current_page * 5 <=
                    reactions?.pagination?.total_count
                      ? reactions?.pagination?.current_page * 5
                      : reactions?.pagination?.total_count}{" "}
                    de {reactions?.pagination?.total_count}{" "}
                  </span>
                  <S.ContainerPaginationButtons
                    isDesktop={isDesktop}
                    isPaginationDisabled={isPaginationDisabled}
                  >
                    <Pagination
                      className="pagination"
                      activeClass="paginationActiveClass"
                      activePage={reactions?.pagination?.current_page}
                      itemClass="paginationItem"
                      linkClass="paginationLink"
                      itemsCountPerPage={5}
                      totalItemsCount={reactions?.pagination?.total_count}
                      pageRangeDisplayed={3}
                      onChange={(pageNumber) => {
                        handleReactionsToShow({
                          reaction: reactionSelected,
                          currentPage: pageNumber,
                        });
                        setShouldSaveAllReactions(false);
                      }}
                      prevPageText={"Anterior"}
                      nextPageText={"Próximo"}
                    />
                  </S.ContainerPaginationButtons>
                </S.Pagination>
              </S.ReactionsList>
            </>
          )}
        </AlertModalStyle.ContainerModal>
      </AlertModalStyle.StyledModal>
    </ModalProvider>
  );
};

export default ReactionsViewModal;
