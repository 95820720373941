import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { ModalProvider } from "styled-react-modal";
import Skeleton from "react-loading-skeleton";
import { v1 as uuidv1 } from "uuid";

import Avatar from "components/core/Avatar/Avatar";
import { Creators as PublicationActions } from "store/ducks/detailspublication";

import { ReactComponent as IconHeart } from "assets/img/icons/iconHeart.svg";
import { ReactComponent as IconClose } from "assets/img/icons/iconClose.svg";

import Flex from "components/core/Grid/Flex";

import * as AlertModalStyle from "./AlertModalStyle";
import * as S from "./LikesViewModalStyle";

const UsersLikeLoadMoreSkeleton = () => (
  <Flex
    px={38}
    flexDirection="column"
    spaceChildren={4}
    style={{ padding: "0 0.5rem" }}
  >
    <Flex
      spaceChildren={2}
      key={uuidv1()}
      style={{ justifyContent: "space-between" }}
    >
      <Flex spaceChildren={4} style={{ alignItems: "center" }}>
        <Skeleton circle duration={0.8} width={50} height={50} />
        <Flex
          flexDirection="column"
          mt={2}
          spaceChildren={2}
          style={{ alignItems: "flex-start" }}
        >
          <Skeleton duration={0.8} width={98} height={20} />
          <Skeleton duration={0.8} width={70} height={20} />
        </Flex>
      </Flex>
      {/* <Skeleton
        duration={0.8}
        width={40}
        height={20}
        style={{ alignItems: "center" }}
      /> */}
    </Flex>
  </Flex>
);

const LikesViewModal = ({ postId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    likesViewList,
    hasMoreLikesViewList,
    isLoadingLikesViewList,
    pageLikesView,
    publication: {
      likes,
    },
  } = useSelector(({ detailspublication }) => detailspublication);

  const [isOpen, setIsOpen] = useState(true);
  const [scrollValue, setScrollValue] = useState(
    sessionStorage.getItem("LikesViewModalScroll") || 0,
  );

  const toggleModal = () => {
    sessionStorage.setItem("LikesViewModalScroll", 0);
    setIsOpen(!isOpen);
  };

  const fetchMoreDataLikesView = () => {
    hasMoreLikesViewList &&
      !isLoadingLikesViewList &&
      dispatch(
        PublicationActions.getLikeUsersPublication({
          postId,
          page: pageLikesView + 1,
        }),
      );
  };

  const handleScroll = (e) => {
    setScrollValue(e.target.scrollTop);
    sessionStorage.setItem("LikesViewModalScroll", e.target.scrollTop);
  };

  useEffect(()=>{
    const scrollableDiv = document.getElementById("scrollableDiv");
    if(!!scrollableDiv){
      scrollableDiv.scrollTop = Number(scrollValue);
    }
  },[likesViewList])

  return (
    <ModalProvider>
      <AlertModalStyle.StyledModal
        data-testid="modal-component"
        isOpen={isOpen}
        type={false}
      >
        <AlertModalStyle.ContainerModal
          style={{ width: "100%", maxHeight: "30rem" }}
        >
          <S.FistLineContainer>
            <S.IconAndTitleContainer>
              <IconHeart />
              <span>
                {likes}{" "}
                {intl.messages["publication.textPage.likes"]}
              </span>
            </S.IconAndTitleContainer>
            <IconClose onClick={toggleModal} />
          </S.FistLineContainer>
          {likesViewList.length > 0 && (
            <div
              style={{
                overflowY: "auto",
                width: "95%",
                padding: "0 1rem 0 0",
                margin: "0 0 0 1.75rem",
              }}
              onScroll={handleScroll}
              id="scrollableDiv"
            >
              <InfiniteScroll
                pageStart={1}
                initialLoad={false}
                loadMore={fetchMoreDataLikesView}
                hasMore={hasMoreLikesViewList}
                useWindow={false}
                threshold={50}
                loader={<UsersLikeLoadMoreSkeleton />}
              >
                <Flex flexDirection="column" spaceChildren={4} pb={10}>
                  {likesViewList?.map((user) => (
                    <S.UserContainer key={user.id}>
                      <S.AvatarAndNameContainer>
                        <Avatar url={user?.image?.url} size={56} />
                        <S.NameAndSectorContainer>
                          <h5>{user.name}</h5>
                          <span>{user?.sector?.name}</span>
                        </S.NameAndSectorContainer>
                      </S.AvatarAndNameContainer>
                      {/* <S.HourContainer>Ontem <br/> às 12h00</S.HourContainer> */}
                    </S.UserContainer>
                  ))}
                </Flex>
              </InfiniteScroll>
            </div>
          )}
        </AlertModalStyle.ContainerModal>
      </AlertModalStyle.StyledModal>
    </ModalProvider>
  );
};

export default LikesViewModal;
