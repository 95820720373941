/* eslint-disable react/prop-types */
import React from "react";
import { ReactComponent as NotFoundGenetic } from "assets/img/newHasNoResults.svg";
import { ReactComponent as NotFoundStoreProducts } from "assets/img/hasNotStoreProducts.svg";

import * as S from "./NotFoundGenericStyle";

const NotFoundGeneric = ({ textPage }) => {
  return (
    <S.ContainerNotFilesFound>
      <NotFoundGenetic />
      <S.ContainerTitle>{textPage}</S.ContainerTitle>
    </S.ContainerNotFilesFound>
  );
};

export default NotFoundGeneric;

export const NotFoundStore = ({message, submessage}) => {
  return (
    <S.ContainerNotProductsFound>
      <NotFoundStoreProducts style={{marginBottom: "30px"}}/>
      <S.ContainerMessage>{message}</S.ContainerMessage>
      <S.ContainerMessage>{submessage}</S.ContainerMessage>
    </S.ContainerNotProductsFound>
  )
}
