import React from "react";
import Lottie from "react-lottie";
import loadingCircle from "assets/loading_circle.json";
import { authenticatedSamlLogin } from "utils/constants/authenticatedSaml";

import { BoxLoadingLoginSaml } from "./LoginSamlStyle";

const LoginSaml = () => {
  window.onload = () => {
    const response = new URLSearchParams(window.location.search).get(
      "response",
    );
    const decodeResponse = decodeURI(response);
    if (decodeResponse) {
      const data = JSON.parse(decodeResponse);
      authenticatedSamlLogin(data);
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingCircle,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <BoxLoadingLoginSaml>
      <Lottie options={defaultOptions} height={300} width={300} />
    </BoxLoadingLoginSaml>
  );
};

export default LoginSaml;
