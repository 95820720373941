import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getPublicationReactions: ["payload"],
  getPublicationReactionsSuccess: ["payload", "isLoading"],
  getPublicationReactionsFail: ["isLoading", "error"],

  getPublicationAllReactions: ["payload"],
  getPublicationAllReactionsSuccess: ["payload", "isLoading"],
  getPublicationAllReactionsFail: ["isLoading", "error"],

  getPublicationCommentsReactions: ["payload"],
  getPublicationCommentsReactionsSuccess: ["payload", "isLoading"],
  getPublicationCommentsReactionsFail: ["isLoading", "error"],

  getPublicationAllCommentsReactions: ["payload"],
  getPublicationAllCommentsReactionsSuccess: ["payload", "isLoading"],
  getPublicationAllCommentsReactionsFail: ["isLoading", "error"],

  postLikedPublication: ["payload"],
  postLikedPublicationSuccess: ["isLoading"],
  postLikedPublicationFail: ["isLoading", "error"],

  createPublicationCommentsReactions: ["payload"],
  createPublicationCommentsReactionsSuccess: ["payload", "isLoading"],
  createPublicationCommentsReactionsFail: ["isLoading", "error"],

  editPublicationCommentsReactions: ["payload"],
  editPublicationCommentsReactionsSuccess: ["payload", "isLoading"],
  editPublicationCommentsReactionsFail: ["isLoading", "error"],

  deletePublicationCommentsReactions: ["payload"],
  deletePublicationCommentsReactionsSuccess: ["payload", "isLoading"],
  deletePublicationCommentsReactionsFail: ["isLoading", "error"],
});

const INITIAL_STATE = {
  reactions: {},
  commentReactions: {},
  isLoading: false,
  error: [],
  liked: false,
  allReactions: {},
};

const getPublicationReactions = (state) => ({
  ...state,
  isLoading: true,
});

const getPublicationReactionsSuccess = (state, { reactions, liked }) => ({
  ...state,
  reactions,
  liked,
  isLoading: false,
});

const getPublicationReactionsFail = (state) => ({
  ...state,
  isLoading: false,
});

const getPublicationAllReactions = (state) => ({
  ...state,
  isLoading: true,
});

const getPublicationAllReactionsSuccess = (state, { allReactions }) => {
  return {
    ...state,
    allReactions,
    isLoading: false,
  };
};

const getPublicationAllReactionsFail = (state) => ({
  ...state,
  isLoading: false,
});

const getPublicationCommentsReactions = (state) => ({
  ...state,
  isLoading: true,
});

const getPublicationCommentsReactionsSuccess = (state, { reactions }) => {
  return {
    ...state,
    reactions,
    isLoading: false,
  };
};

const getPublicationCommentsReactionsFail = (state) => ({
  ...state,
  isLoading: false,
});

const getPublicationAllCommentsReactions = (state) => ({
  ...state,
  isLoading: true,
});

const getPublicationAllCommentsReactionsSuccess = (state, { allReactions }) => {
  return {
    ...state,
    allReactions,
    isLoading: false,
  };
};

const getPublicationAllCommentsReactionsFail = (state) => ({
  ...state,
  isLoading: false,
});

const postLikedPublication = (state) => ({ ...state });

const postLikedPublicationSuccess = (state) => ({
  ...state,
  isLoading: false,
});

const postLikedPublicationFail = (state) => ({
  ...state,
  isLoading: false,
});

const createPublicationCommentsReactions = (state) => ({
  ...state,
  isLoading: true,
});

const createPublicationCommentsReactionsSuccess = (state, { publication }) => ({
  ...state,
  publication,
  isLoading: false,
});

const createPublicationCommentsReactionsFail = (state) => ({
  ...state,
  isLoading: false,
});

const editPublicationCommentsReactions = (state) => ({
  ...state,
  isLoading: true,
});

const editPublicationCommentsReactionsSuccess = (state, { publication }) => ({
  ...state,
  publication,
  isLoading: false,
});

const editPublicationCommentsReactionsFail = (state) => ({
  ...state,
  isLoading: false,
});

const deletePublicationCommentsReactions = (state) => ({
  ...state,
  isLoading: true,
});

const deletePublicationCommentsReactionsSuccess = (state, { publication }) => ({
  ...state,
  publication,
  isLoading: false,
});

const deletePublicationCommentsReactionsFail = (state) => ({
  ...state,
  isLoading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_PUBLICATION_REACTIONS]: getPublicationReactions,
  [Types.GET_PUBLICATION_REACTIONS_SUCCESS]: getPublicationReactionsSuccess,
  [Types.GET_PUBLICATION_REACTIONS_FAIL]: getPublicationReactionsFail,

  [Types.GET_PUBLICATION_ALL_REACTIONS]: getPublicationAllReactions,
  [Types.GET_PUBLICATION_ALL_REACTIONS_SUCCESS]:
    getPublicationAllReactionsSuccess,
  [Types.GET_PUBLICATION_ALL_REACTIONS_FAIL]: getPublicationAllReactionsFail,

  [Types.GET_PUBLICATION_COMMENTS_REACTIONS]: getPublicationCommentsReactions,
  [Types.GET_PUBLICATION_COMMENTS_REACTIONS_SUCCESS]:
    getPublicationCommentsReactionsSuccess,
  [Types.GET_PUBLICATION_COMMENTS_REACTIONS_FAIL]:
    getPublicationCommentsReactionsFail,

  [Types.GET_PUBLICATION_ALL_COMMENTS_REACTIONS]:
    getPublicationAllCommentsReactions,
  [Types.GET_PUBLICATION_ALL_COMMENTS_REACTIONS_SUCCESS]:
    getPublicationAllCommentsReactionsSuccess,
  [Types.GET_PUBLICATION_ALL_COMMENTS_REACTIONS_FAIL]:
    getPublicationAllCommentsReactionsFail,

  [Types.POST_LIKED_PUBLICATION]: postLikedPublication,
  [Types.POST_LIKED_PUBLICATION_SUCCESS]: postLikedPublicationSuccess,
  [Types.POST_LIKED_PUBLICATION_FAIL]: postLikedPublicationFail,

  [Types.CREATE_PUBLICATION_COMMENTS_REACTIONS]:
    createPublicationCommentsReactions,
  [Types.CREATE_PUBLICATION_COMMENTS_REACTIONS_SUCCESS]:
    createPublicationCommentsReactionsSuccess,
  [Types.CREATE_PUBLICATION_COMMENTS_REACTIONS_FAIL]:
    createPublicationCommentsReactionsFail,

  [Types.EDIT_PUBLICATION_COMMENTS_REACTIONS]: editPublicationCommentsReactions,
  [Types.EDIT_PUBLICATION_COMMENTS_REACTIONS_SUCCESS]:
    editPublicationCommentsReactionsSuccess,
  [Types.EDIT_PUBLICATION_COMMENTS_REACTIONS_FAIL]:
    editPublicationCommentsReactionsFail,

  [Types.DELETE_PUBLICATION_COMMENTS_REACTIONS]:
    deletePublicationCommentsReactions,
  [Types.DELETE_PUBLICATION_COMMENTS_REACTIONS_SUCCESS]:
    deletePublicationCommentsReactionsSuccess,
  [Types.DELETE_PUBLICATION_COMMENTS_REACTIONS_FAIL]:
    deletePublicationCommentsReactionsFail,
});
