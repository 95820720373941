import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import apps from "./apps";
import academy from "./academy";
import alert from "./modalAlert";
import auth from "./auth";
import birthdays from "./birthdays";
import calendar from "./calendar";
import detailspublication from "./detailspublication";
import files from "./files";
import holerite from "./holerite";
import mural from "./mural";
import notifications from "./notifications";
import scoreHistory from "./scoresHistory";
import sidebar from "./sidebar";
import store from "./store";
import team from "./team";
import user from "./user";
import gallery from "./gallery";
import toolsExternalLinks from "./toolsExternalLinks";
import groupPardini from "./groupPardini";
import teamCibra from "./teamCibra";
import guestAuth from "./guestAuth";
import postComments from "./postComments";
import customMenu from "./customMenu";
import reactions from "./reactions";

const appReducer = (history) => {
  const reducers = {
    router: connectRouter(history),
    apps,
    academy,
    alert,
    auth,
    birthdays,
    calendar,
    detailspublication,
    files,
    holerite,
    mural,
    notifications,
    scoreHistory,
    sidebar,
    store,
    team,
    user,
    gallery,
    toolsExternalLinks,
    groupPardini,
    teamCibra,
    guestAuth,
    postComments,
    customMenu,
    reactions,
  };

  return combineReducers(reducers);
};

const Reducers = (history) => appReducer(history);

export default Reducers;
