/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import Flex from "components/core/Grid/Flex";

import Carousel from "components/core/Carousel";
import { PageTitle } from "components/core/Typography/Typography";
import SkeletonToolsExternalLinks from "components/presentation/ToolsExternalLinks/SkeletonToolsExternalLinks";
import { Creators as ModalAlertActions } from "store/ducks/modalAlert";

import { ReactComponent as NoResults } from "assets/img/newHasNoResults.svg";
import { ReactComponent as DownloadIcon } from "assets/img/icons/downloadIcon.svg";
import { ReactComponent as ExternalLinkIcon } from "assets/img/icons/externalLink.svg";

import { useLocation } from "react-router";

import * as S from "./ToolsExternalLinksStyle";

const ToolsExternalLinks = ({ allToolsExternalLinks, isLoadingTools }) => {
  const intl = useIntl();
  const location = useLocation();
  const { pageTitle } = location.state || "";
  const dispatch = useDispatch();
  const [isDesktop, setDesktop] = useState(window.innerWidth > 767);
  const [isDesktopValue] = useState(
    window.innerWidth > 767 ? (window.innerWidth > 1500 ? 7 : 5) : 2,
  );
  const changeDataPosition = () => {
    setDesktop(window.innerWidth > 767);
  };
  const convertArrayCarrousel = (arraySector) => {
    let objectArray = [];
    const arrayConvert = [];
    arraySector.map((array, index) => {
      objectArray.push(array);
      if (
        objectArray.length === isDesktopValue ||
        arraySector.length === index + 1
      ) {
        arrayConvert.push(renderComponentsApps(objectArray, true));
        objectArray = [];
      }
      return null;
    });

    return arrayConvert;
  };

  useEffect(() => {
    window.addEventListener("resize", changeDataPosition);
    return () => window.removeEventListener("resize", changeDataPosition);
  });

  const renderComponentsApps = (allValuesSector, typeCarrousel = false) => (
    <S.ContainerAppCarrousel typeRender={typeCarrousel}>
      {allValuesSector.length > 0 ? (
        allValuesSector?.map(({ name, image, url, kind, file }) => (
          <S.App
            showColorBackground={!image?.url}
            onClick={() =>
              kind !== "file" &&
              dispatch(
                ModalAlertActions.setAlertsRequest({
                  title: intl.messages["modal.textPage.confirmApps"],
                  msg:
                    intl.messages[
                      "apps.textPage.youWillBeRedirectedToThisApplicationWebsite"
                    ],
                  buttons: [
                    {
                      title: intl.messages["general.buttons.cancel"],
                      variant: ["transparent"],
                    },
                    {
                      title: intl.messages["general.buttons.access"],
                      event: () => window.open(url, "_blank"),
                    },
                  ],
                }),
              )
            }
          >
            <S.ContainerLinkFolder>
              {image?.url && <S.AppImg src={image.url} />}
              {!image?.url && kind === "file" && (
                <S.LinkFile
                  href={`${file.url}`}
                  target="_blank"
                  download={name}
                >
                  <DownloadIcon id="icon-download" />
                </S.LinkFile>
              )}
              {!image?.url && kind === "url" && (
                <ExternalLinkIcon id="icon-external-Link" />
              )}
            </S.ContainerLinkFolder>
            <S.AppName>{name}</S.AppName>
          </S.App>
        ))
      ) : (
        <S.ContainerNotExternalLinks>
          <NoResults />
          <S.TitleNotExternalLinks>
            Não possui ferramentas <br /> nesse setor
          </S.TitleNotExternalLinks>
        </S.ContainerNotExternalLinks>
      )}
    </S.ContainerAppCarrousel>
  );

  return (
    <Flex flexDirection="column">
      <PageTitle title={pageTitle || intl.messages["menu.tools"]} />
      {isLoadingTools ? (
        <SkeletonToolsExternalLinks />
      ) : allToolsExternalLinks?.length > 0 ? (
        allToolsExternalLinks?.map(
          ({ id, external_links, name: nameSector }) => (
            <S.AppList showMobileDesktop={!isDesktop}>
              <S.TitleSector>{nameSector}</S.TitleSector>
              {external_links?.length > isDesktopValue ? (
                <Carousel
                  key={id}
                  showButtonCarousel={isDesktop}
                  itensRender={convertArrayCarrousel(external_links)}
                />
              ) : (
                renderComponentsApps(external_links)
              )}
            </S.AppList>
          ),
        )
      ) : (
        <S.ContainerNoResultsApp>
          <NoResults />
          <S.ContainerTitle>
            {intl.messages["publication.textPage.hasNoResults"]}
          </S.ContainerTitle>
        </S.ContainerNoResultsApp>
      )}
    </Flex>
  );
};

ToolsExternalLinks.defaultProps = {
  isLoadingTools: false,
  allToolsExternalLinks: [],
};

ToolsExternalLinks.propTypes = {
  isLoadingTools: PropTypes.bool,
  allToolsExternalLinks: PropTypes.arrayOf(PropTypes.object),
};

export default ToolsExternalLinks;
