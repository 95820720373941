import React from "react";
import Skeleton from "react-loading-skeleton";

import * as S from "./EventListSkeletonStyle";

export const SkeletonEventList = () => {
    return (
      <S.SkeletonEventListContainer>
        <Skeleton duration={0.8} width={5} height={58} />
        <S.SkeletonEventListDescription>
          <Skeleton duration={0.8} width={160} height={8} />
          <Skeleton duration={0.8} width={160} height={8} />
          <Skeleton duration={0.8} width={160} height={8} />
        </S.SkeletonEventListDescription>
      </S.SkeletonEventListContainer>
    );
  };