import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getNotifications: ["payload"],
  getNotificationsSuccess: ["payload", "isLoading"],
  getNotificationsFail: ["isLoading", "error"],

  markAsRead: ["payload"],
  markAsReadSuccess: ["payload", "isLoading"],
  markAsReadFail: ["isLoading", "error"],
});
const INITIAL_STATE = {
  notifications: [],
  isLoading: true,
  isLoadingNotifications: true,
  hasMore: true,
  page: 1,
};

const getNotifications = (state) => ({
  ...state,
  isLoadingNotifications: true,
});

const getNotificationsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
  isLoadingNotifications: false,
});
const getNotificationsFail = (state, error) => ({
  ...state,
  isLoading: false,
  isLoadingNotifications: false,
  ...error,
});

const markAsRead = (state) => ({
  ...state,
  isLoadingNotifications: true,
});

const markAsReadSuccess = (state, payload) => ({
  ...state,
  ...payload,
});
const markAsReadFail = (state, error) => ({
  ...state,
  ...error,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_NOTIFICATIONS]: getNotifications,
  [Types.GET_NOTIFICATIONS_SUCCESS]: getNotificationsSuccess,
  [Types.GET_NOTIFICATIONS_FAIL]: getNotificationsFail,

  [Types.MARK_AS_READ]: markAsRead,
  [Types.MARK_AS_READ_SUCCESS]: markAsReadSuccess,
  [Types.MARK_AS_READ_FAIL]: markAsReadFail,
});
