/* eslint-disable react/no-array-index-key */
import React from "react";
import Skeleton from "react-loading-skeleton";
import Flex from "components/core/Grid/Flex";
import { v1 as uuidv1 } from "uuid";

const SkeletonSidebarProduct = () => {
  return (
    <Flex px={38} flexDirection="column" spaceChildren={4}>
    <Flex spaceChildren={2} key={uuidv1()} alignItems="center">
      <Skeleton duration={0.8} width={80} height={60} />
      <Flex flexDirection="column" mt={2} spaceChildren={0}>
        <Skeleton duration={0.8} width={108} height={10} />
        <Skeleton duration={0.8} width={108} height={10} />
        <Skeleton duration={0.8} width={108} height={10} />
      </Flex>
    </Flex>
  </Flex>
  );
};

export default SkeletonSidebarProduct;
