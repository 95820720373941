import React, { useEffect, useCallback } from "react";
import Portal from "templates/Portal";
import urls from "utils/constants/urls";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { history, convertToSlug } from "helpers/sharedHelpers";

import { Creators as ActionGroupPardini } from "store/ducks/groupPardini";

import Flex from "components/core/Grid/Flex";
import AcademySkeleton from "containers/Academy/AcademySkeleton";
import { PageTitle } from "components/core/Typography/Typography";

import * as S from "./OurCompaniesPardiniStyle";

const OurCompaniesPardini = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isLoading, subMenuList } = useSelector((state) => state.groupPardini);
  const dispatchGetPardiniDetails = useCallback(() => {
    dispatch(ActionGroupPardini.getPardiniDetails(1, id, true));
  }, [dispatch, id]);

  useEffect(() => {
    dispatchGetPardiniDetails();
  }, [dispatchGetPardiniDetails]);

  return (
    <Portal>
      <PageTitle title={subMenuList.title} />
      {isLoading ? (
        <AcademySkeleton />
      ) : (
        <Flex flexDirection="column" p={4}>
          <S.OurCompaniesList>
            {subMenuList.child_pages?.map(
              ({
                title: titleDetails,
                image,
                caption,
                description,
                id: idDetails,
              }) => (
                <S.CompanyCard
                  onClick={() =>
                    history.push(
                      `${urls.ROUTES.OUR_COMPANIES_PUBLICATION}/${convertToSlug(
                        titleDetails,
                      )}/${id}/${idDetails}`,
                    )
                  }
                >
                  <img src={image?.url} alt={titleDetails} />
                  <S.CompanyInfo>
                    <h1>{titleDetails}</h1>
                    <h2>{caption}</h2>
                    <p>{description}</p>
                  </S.CompanyInfo>
                </S.CompanyCard>
              ),
            )}
          </S.OurCompaniesList>
        </Flex>
      )}
    </Portal>
  );
};

export default OurCompaniesPardini;
