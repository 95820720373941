/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React from "react";
import Skeleton from "react-loading-skeleton";
import { v1 as uuidv1 } from "uuid";
import { AppList, App } from "./AppsStyle";

const SkeletonApps = () => {
  const skeletonRepeat = 8;

  return (
    <AppList mt={2} spaceChildren={4} p={3}>
      {[...Array(skeletonRepeat)].map((_) => (
        <App key={uuidv1()}>
          <Skeleton duration={0.8} width={132} height={80} />
          <Skeleton duration={0.8} width={132} height={36} />
        </App>
      ))}
    </AppList>
  );
};

export default SkeletonApps;
