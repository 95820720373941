import React from "react";
import styled from "styled-components";
import css from "@styled-system/css";
import Skeleton from "react-loading-skeleton";

import Flex from "components/core/Grid/Flex";

export const PublicationContainer = styled("div")(
  css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "16px",
    ".post-header > span > span": {
      borderRadius: "12px 12px 0 0",
    },
  }),
);

const SkeletonPublication = () => (
  <PublicationContainer>
    <Flex flexDirection="column" width={590}>
      <Flex className="post-header" flexDirection="column" width={1}>
        <Skeleton duration={0.8} height={340} />
        <Flex
          bg="paleGrey"
          width={1}
          height={194}
          borderRadius="0 0 16px 16px"
        />
      </Flex>

      <Flex flexDirection="column" spaceChildren={4} mt={5}>
        <Skeleton duration={0.8} count={2} height={18} />

        <Skeleton duration={0.8} count={2} height={18} />

        <Skeleton duration={0.8} height={18} />

        <Skeleton duration={0.8} height={189} />
      </Flex>
    </Flex>
  </PublicationContainer>
);

export default SkeletonPublication;
