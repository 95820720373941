import styled from "styled-components";
import css from "@styled-system/css";
import Flex from "components/core/Grid/Flex";

export const CertificateContainer = styled("div")(
  css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
);
export const CertificateKind = styled(Flex)(
  css({
    width: "420px !important",
    alignItems: "center",
    flexDirection: "column",
    p: "96px 16px 16px 16px",

    "@media (max-width: 405px)": {
      maxWidth: "300px",
    },
    h1: {
      mt: 6,
      mb: 0,
      fontSize: 4,
      color: "greenDark",
      textAlign: "center",
      fontFamily: "heading",
    },
    h3: {
      fontSize: 3,
      color: "yellow",
      display: "flex",
      fontFamily: "text",
      alignItems: "center",
      svg: {
        ml: 2,
      },
    },
    h4: {
      mt: 12,
      width: 380,
      fontSize: 2,
      color: "gray",
      fontFamily: "text",
      textAlign: "center",
    },
  }),
);
