/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from "react";
import { useIntl } from "react-intl";
import { v1 as uuidv1 } from "uuid";
import { useDispatch } from "react-redux";
import Flex from "components/core/Grid/Flex";
import { PageTitle } from "components/core/Typography/Typography";
import { Creators as ModalAlertActions } from "store/ducks/modalAlert";
import imgAppNotfaund from "assets/img/icons/imgAppNotfaund.svg";
import { ReactComponent as NoResults } from "assets/img/newHasNoResults.svg";
import SkeletonApps from "./SkeletonApps";
import * as S from "./AppsStyle";

const Apps = ({ apps, isLoading }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <Flex flexDirection="column">
      <PageTitle title={intl.messages["menu.tools"]} />
      {isLoading ? (
        <SkeletonApps />
      ) : apps.length > 0 ? (
        <S.AppList mt={2} spaceChildren={4} p={3}>
          {apps.map(({ name, image, url }) => (
            <S.App
              key={uuidv1()}
              onClick={() =>
                dispatch(
                  ModalAlertActions.setAlertsRequest({
                    title: intl.messages["modal.textPage.confirmApps"],
                    msg:
                      intl.messages[
                        "apps.textPage.youWillBeRedirectedToThisApplicationWebsite"
                      ],
                    buttons: [
                      {
                        title: intl.messages["general.buttons.cancel"],
                        variant: ["transparent"],
                      },
                      {
                        title: intl.messages["general.buttons.access"],
                        event: () => window.open(url, "_blank"),
                      },
                    ],
                  }),
                )
              }
            >
              <S.AppImg src={image.url || imgAppNotfaund} />
              <S.AppName>{name}</S.AppName>
            </S.App>
          ))}
        </S.AppList>
      ) : (
        <S.ContainerNoResultsApp>
          <S.ContainerTitle>
            {intl.messages["publication.textPage.hasNoResults"]}
          </S.ContainerTitle>
          <NoResults />
        </S.ContainerNoResultsApp>
      )}
    </Flex>
  );
};

export default Apps;
