import styled from "styled-components";
import css from "@styled-system/css";
import theme from "styles/theme";
import Flex from "components/core/Grid/Flex";

export const App = styled(Flex)(
  css({
    width: "132px",
    padding: "4px",
    marginRight: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: 8,
    flexDirection: "column",
    ":hover": {
      cursor: "pointer",
      ">:nth-child(1)": {
        opacity: 0.8,
        boxShadow:
          "0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)",
        "#icon-external-Link": {
          path: {
            fill: "white",
          },
        },
        "#icon-download": {
          g: {
            g: {
              stroke: "white",
            },
          },
        },
      },
    },
  }),
  ({ showColorBackground }) =>
    showColorBackground &&
    css({
      ":hover": {
        ">:nth-child(1)": {
          backgroundColor: theme.colors.primaryColor,
        },
      },
    }),
);

export const AppImg = styled("img")(
  css({
    width: "100%",
    height: "100%",
    borderRadius: 8,
    objectFit: "cover",
    backgroundColor: "paleGrey",
  }),
);

export const AppList = styled("div")(
  css({
    width: "100%",
    display: "flex",
    padding: "0px 50px",
    flexDirection: "column",
    borderBottom: "1px solid",
    borderColor: "grayDefaultColor",

    "> div .slider": {
      marginBottom: "20px",
    },
    ">div .slider-control-bottomcenter": {
      margin: "0 !important",
      padding: "0 !important",
      bottom: "10px !important",
    },
  }),
  ({ showMobileDesktop }) =>
    showMobileDesktop &&
    css({
      padding: "0px 16px",
    }),
);

export const ContainerAppCarrousel = styled("div")(
  css({
    width: "100%",
    height: "auto",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "50px 0",
  }),
  ({ typeRender }) =>
    typeRender &&
    css({
      margin: "0",
      justifyContent: "center",
    }),
);

export const AppName = styled("h3")(
  css({
    margin: "0 0 16px 0",
    height: "70px",
    width: "120px",
    fontWeight: 500,
    fontSize: 2,
    fontFamily: "text",
    lineHeight: "24px",
    overflow: "hidden",
    display: "-webkit-box",
    textAlign: "center",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    color: "charcoalGrey",
  }),
);

export const ContainerNoResultsApp = styled("div")(
  css({
    width: "100%",
    height: "100%",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "50px",
    svg: {
      size: "240px",
      ".cls-1": {
        fill: theme.colors.primaryColor,
        stroke: "none",
      },
      ".cls-2": {
        fill: "#ffc466",
        stroke: "none",
      },
    },
  }),
);

export const ContainerTitle = styled("div")(
  css({
    fontSize: "30px",
    lineHeight: "24px",
    color: "textConfig",
    marginTop: "50px",
  }),
);

export const TitleSector = styled("h3")(
  css({
    fontWeight: 500,
    fontSize: "18px",
    color: "grayDark",
    marginTop: "24px",
    lineHeight: "24px",
    fontStyle: "normal",
  }),
);

export const ImageTeste = styled("img")(
  css({
    width: "150px",
    height: "150px",
    backgroundColor: "red",
  }),
);

export const ContainerLinkFolder = styled("div")(
  css({
    width: "125px",
    height: "125px",
    border: "none",
    display: "flex",
    transition: "0.5s",
    overflow: "hidden",
    alignItems: "center",
    marginBottom: "15px",
    borderRadius: "9.6px",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: theme.colors.paleGrey,
    "@media (max-width: 320px)": {
      width: "120px",
      height: "120px",
    },
    "#icon-external-Link": {
      width: "35px",
      height: "35px",
      path: {
        fill: theme.colors.primaryColor,
      },
    },
    "#icon-download": {
      width: "55px",
      height: "55px",
      g: {
        g: {
          stroke: theme.colors.primaryColor,
        },
      },
    },
  }),
);

export const LinkFile = styled("a")(
  css({
    textDecoration: "none",
  }),
);

export const ContainerNotExternalLinks = styled("div")(
  css({
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    "> svg": {
      size: "120px",
      ".cls-1": {
        fill: theme.colors.primaryColor,
        stroke: "none",
      },
      ".cls-2": {
        fill: "#ffc466",
        stroke: "none",
      },
    },
  }),
);

export const TitleNotExternalLinks = styled("h3")(
  css({
    fontWeight: 500,
    fontSize: "20px",
    marginTop: "8px",
    lineHeight: "25px",
    fontFamily: "text",
    color: "grayMedium",
    fontStyle: "normal",
    textAlign: "center",
  }),
);
