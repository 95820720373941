import styled, { css } from "styled-components";
import { display } from "styled-system";
import theme from "styles/theme";

export const FistLineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  background-color: #ffffff;

  width: 100%;
  font-size: ${(props) => (!props.isDesktop ? "18px" : "20px")};
  border-bottom: 1px solid #d0d3dd;

  > svg {
    width: 0.6rem;
    height: 0.6rem;
    path {
      stroke: #808080;
      stroke-width: 3;
    }

    cursor: pointer;
  }
`;

export const IconAndTitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 0.5rem;
  color: #4f576d;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    path {
      stroke: #808080;
    }
  }

  span {
    font-size: 1.25rem;
    font-family: "RubikMedium", sans-serif;
    line-height: 2rem;
  }
`;

export const UserContainer = styled.div`
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  /* margin-top: 0.5rem; */
  border-top: 1px solid #e4e4e5;

  margin-bottom: 0 !important;
  height: 5rem;
`;

export const AvatarAndNameContainer = styled.div`
  height: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const NameAndSectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #4f576d;

  > h5 {
    font-family: "RubikMedium", sans-serif;
    margin-bottom: 0 !important;
    font-size: ${(props) => (!props.isDesktop ? "16px" : "18px")};
  }

  > span {
    font-size: 14px;
    font-weight: 400;
    color: #98a0b4;
  }
`;

export const HourContainer = styled.span`
  height: 100%;
  width: 4.7rem;

  color: #808080;
  text-align: right;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
`;

export const ReactionsList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 30px;
  background-color: #f4f6fb;
  width: 100%;

  min-height: 450px;
`;

export const ReactionsListContent = styled.div`
  padding: 20px 0;

  :not(:last-child) {
    border-bottom: 1px solid #d0d3dd;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ReactionsListUsersInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ReactionsUsersNameAndSector = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const Accordion = styled.div`
  background-color: #ffffff;
  max-width: 711px;
  width: 100%;
  border-radius: ${(props) => (props.active ? "5px 5px 20px 20px" : "5px")};
`;

export const AccordionContent = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
  position: absolute;
  width: 100%;
  border-radius: 0px 0px 16px 16px;
  background: #fff;
  box-shadow: 0px 6px 10px 0px #0000004d;
  color: #4f576d;

  font-size: ${(props) => (!props.isDesktop ? "16px" : "18px")};
  font-style: normal;
  font-weight: 600;
`;

export const AccordionTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 0 30px;
  gap: 10px;
  cursor: pointer;
  height: 74px;
  border-bottom: 1px solid #d0d3dd;
`;

export const ReactionsCountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  img {
    width: 15px;
    margin-left: 5px;
    transform: ${(props) => (props.active ? "rotate(180deg)" : "rotate(0)")};
  }
`;

export const ContainerReactions = styled.div`
  display: flex;

  img {
    border: "1px solid";
    border-color: "white";
    &:not(:last-child) {
      margin-right: -10px;
    }
  }
`;

export const ReactionsCount = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  span {
    color: #4f576d;
    font-size: ${(props) => (!props.isDesktop ? "16px" : "18px")};
    line-height: 0;
    font-weight: 600;
  }

  /* &:first-child {
    font-weight: 400;
  } */
`;

export const ReactionsListIndividual = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  height: 74px;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: #f4f6fb;
  }
`;

export const Pagination = styled.div`
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #d0d3dd;
  flex-direction: ${(props) => (!props.isDesktop ? "column" : "row")};

  span {
    color: #4f576d;
    font-weight: 400;
    font-size: ${(props) => (!props.isDesktop ? "16px" : "18px")};
  }
`;

export const ContainerPaginationButtons = styled.div`
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 0;
  }

  .paginationActiveClass {
    text-decoration: ${(props) =>
      props.isPaginationDisabled ? "none" : "underline"};
  }

  .paginationItem {
    margin: 0 5px;
  }

  .paginationLink {
    font-size: ${(props) => (!props.isDesktop ? "16px" : "18px")};
    color: ${(props) => (props.isPaginationDisabled ? "#b3b8c8 " : "#4f576d")};
    cursor: ${(props) =>
      props.isPaginationDisabled ? "not-allowed" : "pointer"};
    pointer-events: ${(props) => props.isPaginationDisabled && "none"};
  }
`;
