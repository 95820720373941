import React from "react";
import styled from "styled-components";
import css from "@styled-system/css";
import Skeleton from "react-loading-skeleton";

import Flex from "components/core/Grid/Flex";

export const SubMenuCibraContainer = styled("div")(
  css({
    height: "100%",
    width: "100%",
    marginBottom: "120px",
    padding: "50px",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    a: {
      ":hover": {
        textDecoration: "none",
      },
    },
    "@media (max-width: 342px)": {
      paddingLeft: "80px",
    },

    "@media (min-width: 366px) and (max-width: 386px)": {
      paddingLeft: "40px",
    },
    "@media (min-width: 386px) and (max-width: 490px)": {
      paddingLeft: "60px",
    },
  }),
);

const SkeletonSubMenuCibra = () => (
  <SubMenuCibraContainer>
    <Flex flexDirection="column" width={590}>
      <Flex flexDirection="column" spaceChildren={4} mt={0}>
        <Skeleton duration={0.8} height={18} width={300} />

        <Skeleton duration={0.8} count={2} height={18} />

        <Skeleton duration={0.8} height={18} width={300} />

        <Skeleton duration={0.8} count={2} height={18} />

        <Skeleton duration={0.8} height={18} width={300} />

        <Skeleton duration={0.8} count={2} height={18} />

        <Skeleton duration={0.8} height={189} />
      </Flex>
    </Flex>
  </SubMenuCibraContainer>
);

export default SkeletonSubMenuCibra;
