import React from "react";
import Skeleton from "react-loading-skeleton";
import Flex from "components/core/Grid/Flex";
import {
  ProductContainer,
  ProductContent,
  ProductDescription,
  ProductName,
} from "./DetailsProductStyle";

const SkeletonDetailsProduct = ({ success }) => {
  return (
    <>
      {success ? (
        <ProductContainer>
          <ProductContent width={375}>
            <ProductName center>
              <Skeleton duration={0.8} height={32} />
            </ProductName>
            <ProductDescription center>
              <Skeleton duration={0.8} height={32} />
              <Skeleton duration={0.8} height={32} />
            </ProductDescription>
            <Flex mt={16} justifyContent="center">
              <Skeleton duration={0.8} width={268} height={268} />
            </Flex>
            <Flex mt={32} justifyContent="center">
              <Skeleton duration={0.8} width={116} height={36} />
            </Flex>
          </ProductContent>
        </ProductContainer>
      ) : (
        <ProductContainer>
          <ProductContent>
            <Skeleton duration={0.8} height={272} />
            <Flex justifyContent="space-between" mt={3}>
              <Skeleton duration={0.8} width={150} height={32} />
              <Skeleton duration={0.8} width={110} height={32} />
            </Flex>
            <Flex flexDirection="column">
              <Skeleton duration={0.8} height={32} />
              <Skeleton duration={0.8} height={32} />
              <Skeleton duration={0.8} height={32} />
              <Skeleton duration={0.8} height={32} />
            </Flex>
            <Flex justifyContent="center">
              <Skeleton duration={0.8} width={116} height={36} />
            </Flex>
          </ProductContent>
        </ProductContainer>
      )}
    </>
  );
};

export default SkeletonDetailsProduct;
