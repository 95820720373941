import { takeLatest, put, select } from "redux-saga/effects";
import { GET } from "utils/constants/verbs";
import { requestAPI } from "helpers/requestHelpers";
import { CALENDAR_EVENTS } from "utils/constants/endpoints";
import { startAndEndDateOfTheMonth } from "helpers/sharedHelpers";
import moment from "moment";
import { Types } from "../ducks/calendar";
import { API_V2 } from "../../utils/API/API";

const getNextEventsV2Actual = (state) => state.calendar.nextEventsV2;

function* getCalendarEvents({ payload }) {
  let dataStartAndEndDateOfTheMonth = {};
  const data = new Date();
  dataStartAndEndDateOfTheMonth = yield startAndEndDateOfTheMonth(data);
  try {
    if (!payload.firstDayMonth) throw new Error("");
    else {
      const { events } = yield requestAPI({
        verb: GET,
        endPoint: `${CALENDAR_EVENTS}?dateini=${
          payload !== undefined && payload.firstDayMonth
          // ? payload.firstDayMonth
          // : dataStartAndEndDateOfTheMonth.firstDayMonth
        }&dateend=${
          payload !== undefined && payload.endDayMonth
          // ? payload.endDayMonth
          // : dataStartAndEndDateOfTheMonth.endDayMonth
        }`,
      });
      yield put({
        events,
        type: Types.GET_CALENDAR_EVENTS_SUCCESS,
      });
    }
  } catch (error) {
    const errorResponse = error?.response?.data || error;
    yield put({
      type: Types.GET_CALENDAR_EVENTS_FAIL,
      ...errorResponse
    });
  }
}

function* getNextEvents() {
  const currentDay = moment().format("YYYY-MM-DD");
  const finalDay = moment().add("1", "year").format("YYYY-MM-DD");
  const replaceDate = (evt) =>
    evt.hour_event
      .replace("2000-01-01", evt.date_event)
      .replace(":00.000Z", "");

  try {
    const { events } = yield requestAPI({
      verb: GET,
      endPoint: `${CALENDAR_EVENTS}?dateini=${currentDay}&dateend=${finalDay}`,
    });

    const dateOrderedEvents = yield events?.sort((a, b) => {
      const current = moment(replaceDate(a));
      const next = moment(replaceDate(b));
      return current - next;
    });

    const filteredEvents = dateOrderedEvents.filter((event) => {
      const currentHour = moment().format("YYYY-MM-DDTHH:mm");
      return replaceDate(event) > currentHour;
    });

    const nextEvents = filteredEvents;

    yield put({
      nextEvents,
      type: Types.GET_NEXT_EVENTS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_NEXT_EVENTS_FAIL,
      ...error.response.data,
    });
  }
}

function* getNextEventsV2({ payload }) {
  const { page } = payload;

  const currentDay = moment().format("YYYY-MM-DD");
  const finalDay = moment().add("1", "year").format("YYYY-MM-DD");
  const replaceDate = (evt) =>
    evt.hour_event
      .replace("2000-01-01", evt.date_event)
      .replace(":00.000Z", "");

  try {
    const {
      data: { calendars: events, pagination },
    } = yield API_V2({
      url: `${CALENDAR_EVENTS}?page=${page}&dateini=${currentDay}&dateend=${finalDay}`,
      method: "GET",
    });

    const dateOrderedEvents = yield events?.sort((a, b) => {
      const current = moment(replaceDate(a));
      const next = moment(replaceDate(b));
      return current - next;
    });

    const filteredEvents = dateOrderedEvents.filter((event) => {
      const currentHour = moment().format("YYYY-MM-DDTHH:mm");
      return replaceDate(event) > currentHour;
    });

    const nextEvents = filteredEvents;

    const actualList =
      // eslint-disable-next-line no-nested-ternary
      page === 1 ? [] : yield select(getNextEventsV2Actual);
    const newList = actualList.concat(nextEvents);
    const hasMore = pagination.next_page !== null;

    yield put({
      nextEventsV2: newList,
      hasMoreNextEventsV2: hasMore,
      pageNextEventsV2: page,
      isLoadingNextEventsV2: false,
      type: Types.GET_NEXT_EVENTS_V2_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_NEXT_EVENTS_V2_FAIL,
      ...error?.response?.data,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.CALENDAR_EVENTS, getCalendarEvents);
  yield takeLatest(Types.GET_NEXT_EVENTS, getNextEvents);
  yield takeLatest(Types.GET_NEXT_EVENTS_V2, getNextEventsV2);
}
