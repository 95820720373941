import styled from "styled-components";
import css from "@styled-system/css";
import Flex from "components/core/Grid/Flex";

export const GeneralContainer = styled(Flex)(
  css({
    width: "100%",
    marginBottom: "120px",
    padding: "30px 0px",
    paddingLeft: "80px",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    a: {
      ":hover": {
        textDecoration: "none",
      },
    },

    "@media (max-width: 449px)": {
      paddingLeft: "20px",
    },
    "@media (min-width: 450px) and (max-width: 1024px)": {
      paddingLeft: "30px",
    },
  }),
);

export const TextContent = styled("div")(
  css({
    maxWidth: "600px",
    marginBottom: "40px",
  }),
);

export const ImgContainer = styled("img")(
  css({
    maxWidth: "150px",
    height: "50px",
    marginBottom: "40px",
  }),
);

export const Text = styled("p")(
  css({
    color: "gray",
    fontSize: "18px",
    fontFamily: "text",
    marginBottom: "30px",
  }),
  ({ title }) =>
    title &&
    css({
      fontSize: "20px",
      fontWeight: "600",
      marginTop: "0px",
      marginBottom: "10px",
    }),
);

export const ContentDetails = styled("div")(
  css({
    width: "100%",
    height: "100%",
    display: "flex",
    paddingTop: "16px",
    position: "relative",
    alignItems: "flex-start",
    flexDirection: "column",
  }),
);
