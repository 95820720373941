import styled from "styled-components";
import css from "@styled-system/css";
import Flex from "components/core/Grid/Flex";

export const UpdatePasswordContainer = styled(Flex)(
  css({
    height: "100vh",
  }),
);

export const UpdatePasswordHeader = styled("div")(
  css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 411px)": {
      flexDirection: "column",
    },
  }),
);
