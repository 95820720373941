import styled from "styled-components";
import theme from "styles/theme";

export const ContainerPrivacyPolicy = styled.div`
  width: 100%;
  max-height: 100%;

  display: flex;
  flex-direction: column;

  padding: 5rem 10rem;

  h6 {
    font-weight: bold;
    margin: 0 auto;
  }

  span.last-update{
    margin-left: auto;
    font-style: italic;
    margin-top: 2rem;
  }

  span.subtitle {
    margin: 1.5rem 0 0.5rem -1rem;
  }

  ol.list {
    li {
      margin: 1rem 0;
    }
  }
`;

export const ContainerSecondLevelList = styled.div`
  margin-left: -1rem;

 >div#main{
  padding: 1rem 0;
 }
`

export const TermThirdLevel = styled.div`
  padding-bottom: 1rem;
  margin-left: 3rem;
`
