/* eslint-disable react/no-array-index-key */
import React from "react";
import Skeleton from "react-loading-skeleton";
import {
  Notification,
  NotificationDate,
  NotificationTitle,
} from "./NotificationsStyle";

const SkeletonNotifications = () => {
  const skeletonRepeat = 7;

  return [...Array(skeletonRepeat)].map((_value, index) => (
    <Notification key={index} height={71} flexDirection="column">
      <NotificationTitle>
        <Skeleton duration={0.8} width={300} height={24} />
      </NotificationTitle>
      <NotificationDate>
        <Skeleton duration={0.8} width={256} height={14} />
      </NotificationDate>
    </Notification>
  ));
};

export const SkeletonNotification = () => {

  return (
    <Notification height={71} flexDirection="column">
      <NotificationTitle>
        <Skeleton duration={0.8} width={300} height={24} />
      </NotificationTitle>
      <NotificationDate>
        <Skeleton duration={0.8} width={256} height={14} />
      </NotificationDate>
    </Notification>
  );
};

export default SkeletonNotifications;
