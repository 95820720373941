/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import urls from "utils/constants/urls";
import moment from "moment";
import Flex from "components/core/Grid/Flex";
import Avatar from "components/core/Avatar/Avatar";
import { PageTitle } from "components/core/Typography/Typography";
import { Creators as BirthdaysActions } from "store/ducks/birthdays";
import { ReactComponent as NoNextBirthdays } from "assets/newBirthdays.svg";
import { SkeletonUserNameAndSector } from "containers/Birthdays/BirthdaysSkeleton";

import { useAnalytics } from "utils/hooks";
import { MsgDoesNotExist, Overflow, Text } from "../SidebarStyle";
import {
  DateBdays,
  MemberBday,
  SectorUser,
  ContainerNoNextBirthdays,
} from "./BirthdaysListStyle";

useAnalytics("screen_birthday_year");

const BirthdaysList = ({ dateClick }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const date = !!dateClick ? dateClick : moment();
  const {
    bdaysTodayCount,
    bdaysTodayUsers,
    hasMoreBdaysTodayUsers,
    pageBdaysTodayUsers,
    isLoadingUsers,
    dateBdayToday,
  } = useSelector((state) => state.birthdays);

  const [overflowHeight, setOverflowHeight] = useState();
  const [overflowHeightEvents, setOverflowHeightEvents] = useState();

  const dispatchGetBdaysTodayUsers = useCallback(
    () =>
      dispatch(
        BirthdaysActions.getBirthdaysUsers({ bdayToday: date, page: 1 }),
      ),
    [dispatch],
  );

  useEffect(() => {
    setOverflowHeightEvents(
      document.body.getBoundingClientRect().height / 2 - 20,
    );
  }, []);
  useEffect(() => {
    setOverflowHeight(document.body.getBoundingClientRect().height / 3 - 88);
  }, []);
  useEffect(() => {
    if (
      !!dateBdayToday &&
      dateBdayToday === moment(date).format("YYYY-MM-DD")
    ) {
      return;
    } else if (!dateClick) {
      dispatchGetBdaysTodayUsers();
    }
  }, [dateClick, dispatchGetBdaysTodayUsers]);

  const fetchMoreUsersData = () => {
    hasMoreBdaysTodayUsers &&
      !isLoadingUsers &&
      dispatch(
        BirthdaysActions.getBirthdaysUsers({
          bdayToday: date,
          page: pageBdaysTodayUsers + 1,
        }),
      );
  };

  return (
    <Flex id="sectionBirthdays" flex={3} flexDirection="column" width="100%">
      <PageTitle
        title={intl.messages["calendar.textPage.birthdaysOfTheDay"]}
        fontSize={3}
      >
        <DateBdays>{moment(date).utc().format("DD/MM/YYYY")}</DateBdays>
      </PageTitle>
      <Overflow
        flexDirection="column"
        spaceChildren={3}
        height={`${(dateClick && overflowHeightEvents) || overflowHeight}px`}
      >
        {isLoadingUsers && !hasMoreBdaysTodayUsers ? (
          <SkeletonUserNameAndSector />
        ) : (
          <InfiniteScroll
            pageStart={1}
            initialLoad={false}
            loadMore={fetchMoreUsersData}
            hasMore={hasMoreBdaysTodayUsers}
            useWindow={false}
            threshold={50}
            loader={<SkeletonUserNameAndSector />}
          >
            {bdaysTodayCount > 0 ? (
              bdaysTodayUsers.map((birthdayUser) => {
                const { id, name, image, sector } = birthdayUser;
                return (
                  <MemberBday to={`${urls.ROUTES.PROFILE}/${id}`} key={id}>
                    <Avatar url={image.url} />
                    <Flex spaceChildren={0} flexDirection="column">
                      <Text>{name}</Text>
                      <SectorUser>{sector?.name}</SectorUser>
                    </Flex>
                  </MemberBday>
                );
              })
            ) : (
              <Flex p={2} alignItems="center">
                <ContainerNoNextBirthdays>
                  <NoNextBirthdays />
                  <MsgDoesNotExist>
                    {intl.messages["calendar.textPage.noBirthdayForThatDay"]}.
                  </MsgDoesNotExist>
                </ContainerNoNextBirthdays>
              </Flex>
            )}
          </InfiniteScroll>
        )}
      </Overflow>
    </Flex>
  );
};

BirthdaysList.propTypes = {
  dateClick: PropTypes.string,
};

export default BirthdaysList;
