import { takeLatest, put, select } from "redux-saga/effects";
import { GET } from "utils/constants/verbs";
import { requestAPI } from "helpers/requestHelpers";
import { API_V2 } from "utils/API/API";
import { REPOSITORIES, REPOSITORIES_FOLDER } from "utils/constants/endpoints";
import { Types } from "../ducks/files";

const getFoldersActual = (state) => state.files.folders;
const pageActual = (state) => state.files.page;

function* getAllFiles() {
  const page = yield select(pageActual);
  try {
    const {
      data: { pagination, repositories },
    } = yield API_V2({
      method: "GET",
      url: `${REPOSITORIES}?per_page=25&page=${page}&origin=web`,
    });
    const actualList = page === 1 ? [] : yield select(getFoldersActual);
    const newList = actualList.concat(repositories);
    const hasMore = repositories.length === 25;
    const nextpage = page + 1;
    yield put({
      folders: newList,
      hasMore,
      page: nextpage,
      type: Types.GET_FILES_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_FILES_FAIL,
      ...error.response.data,
      isLoading: false,
    });
  }
}

function* getFilesFolder({ id }) {
  try {
    const repository = yield requestAPI({
      verb: GET,
      endPoint: REPOSITORIES_FOLDER,
      id,
    });
    yield put({
      repository,
      type: Types.GET_FILES_FOLDER_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_FILES_FOLDER_FAIL,
      ...error.response.data,
      isLoading: false,
    });
  }
}

function* searchFiles({ payload }) {
  try {
    const { files, repositories } = yield requestAPI({
      verb: GET,
      endPoint: payload
        ? `${REPOSITORIES}?filter=folder_or_file&folder_or_file_name=${payload}`
        : REPOSITORIES,
      data: null,
    });
    yield put({
      type: Types.SEARCH_FILES_SUCCESS,
      files,
      folders: repositories,
    });
  } catch (error) {
    yield put({
      type: Types.SEARCH_FILES_FAILURE,
      ...error.response.data,
      isLoading: false,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_FILES, getAllFiles);
  yield takeLatest(Types.GET_FILES_FOLDER, getFilesFolder);
  yield takeLatest(Types.SEARCH_FILES, searchFiles);
}
