import styled from "styled-components";
import css from "@styled-system/css";
import Flex from "components/core/Grid/Flex";

export const ContainerModule = styled(Flex)(
  css({
    flex: 1,
    maxWidth: 720,
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "80px",
    p: "16px 40px 16px 100px",
    "@media(max-width: 768px)": {
      p: "16px 16px 30px 20px",
    },
  }),
);

export const QuizzLength = styled("h4")(
  css({
    fontSize: 0,
    color: "#192DA6",
    fontFamily: "heading",
  }),
);

export const SidebarModule = styled(Flex)(
  css({
    flexBasis: "313px",
    flexDirection: "column",
    border: "1.25px solid #CCD2E0",
    "@media(max-width: 768px)": {
      display: "none",
    },
  }),
);
