import React, { useEffect, useRef, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import InfiniteScroll from "react-infinite-scroller";
import moment from "moment";
import urls from "utils/constants/urls";
import { Creators as BirthdaysActions } from "store/ducks/birthdays";
import Portal from "templates/Portal";
import Flex from "components/core/Grid/Flex";
import Avatar from "components/core/Avatar/Avatar";
import { PageTitle } from "components/core/Typography/Typography";
import { ReactComponent as Birthday } from "assets/birthdays.svg";
import { smartGet, smartFormatterDate } from "helpers/sharedHelpers";
import { useAnalytics } from "utils/hooks";
import BirthdaysTodaySkeleton from "./BirthdaysTodaySkeleton";
import {SkeletonUser} from "containers/Birthdays/BirthdaysSkeleton";
import { useIsScrollable } from "../../../utils/hooks/useIsScrollable";

import {
  BirthdaysCount,
  BirthdayDate,
  BirthdayToday,
  MembersList,
  MemberItem,
  MemberName,
  BirthdayTodayBox
} from "../BirthdaysStyle";

useAnalytics("screen_birthday_day");

const BirthdaysToday = () => {
  const [scrollParentRef, setScrollParentRef] = useState();
  const intl = useIntl();
  const memberListRef = useRef();
  const dispatch = useDispatch();
  const { isLoading, bdaysTodayCount, bdaysTodayUsers, hasMoreBdaysTodayUsers, 
    pageBdaysTodayUsers, isLoadingUsers } = useSelector((state) => state.birthdays);
  const dispatchGetBdaysTodayUsers = useCallback(
    () => dispatch(BirthdaysActions.getBirthdaysUsers({bdayToday: new Date(), page: 1})),
    [dispatch],
  );

  useEffect(() => {
    dispatchGetBdaysTodayUsers();
  }, [dispatchGetBdaysTodayUsers]);

  const renderName = (name) => {
    const first = name[0];
    const last = name.slice(-1)[0];

    return first === last ? first : `${first} ${last}`;
  };

  const birthdaysToday = () => {
    return (
      bdaysTodayCount > 0 && (
        <>
          <BirthdayDate>
            <BirthdaysCount>
              <BirthdayToday>{`${smartFormatterDate(
                moment(),
                "LL",
              )}`}</BirthdayToday>
            </BirthdaysCount>
          </BirthdayDate>
          <Flex>
            <Birthday width={32} height={32} />
            <BirthdaysCount>
              <BirthdayToday>{`${intl.messages["birthdays.textPage.todayWeHave"]} `}</BirthdayToday>
              {bdaysTodayCount === 1
                ? `${bdaysTodayCount} ${intl.messages["birthdays.textPage.birthday"]}`
                : `${bdaysTodayCount} ${intl.messages["birthdays.textPage.birthdayPlural"]}`}
            </BirthdaysCount>
          </Flex>
        </>
      )
    );
  };

  const renderTitleMember = ({ sex, name }) => {
    let genres;
    switch (sex) {
      case "M":
        genres = intl.messages["general.textPage.genres.male"];
        break;
      case "F":
        genres = intl.messages["general.textPage.genres.feminine"];
        break;
      default:
        genres = intl.messages["general.textPage.genres.ungenerous"];
    }
    return `${intl.messages["general.textPage.clickOfAcessProfile"]} ${genres} ${name}`;
  };

  const fetchMoreUsersData = () => {
    hasMoreBdaysTodayUsers && !isLoadingUsers && 
    dispatch(BirthdaysActions.getBirthdaysUsers({bdayToday: new Date(), page: pageBdaysTodayUsers + 1}));
  };

  const [isScrollable, ref, node] = useIsScrollable(bdaysTodayUsers);

  useEffect(() => {
    if (!node || isLoadingUsers) return;

    if (!isScrollable && hasMoreBdaysTodayUsers) {
      fetchMoreUsersData();
    }
  }, [isLoadingUsers, isScrollable, hasMoreBdaysTodayUsers, node]);

  return (
    <Portal
      setRef={setScrollParentRef}
    >
      <BirthdayTodayBox flexDirection="column">
        <PageTitle title={intl.messages["menu.birthdays"]}>
          {isLoading ? (
            <>
              <BirthdayDate>
                <Skeleton duration={0.8} width={180} height={28} />
              </BirthdayDate>
              <Skeleton duration={0.8} width={396} height={28} />
            </>
          ) : (
            birthdaysToday()
          )}
        </PageTitle>
        {isLoadingUsers && !hasMoreBdaysTodayUsers? (
          <BirthdaysTodaySkeleton />
        ) : (
          bdaysTodayUsers.length > 0 && (
            <Flex
              id="scrollableDiv"
              ref={ref}
            >
              <InfiniteScroll
                pageStart={1}
                initialLoad={false}
                loadMore={fetchMoreUsersData}
                hasMore={hasMoreBdaysTodayUsers}
                useWindow={false}
                threshold={50}
                getScrollParent={() => scrollParentRef}
                loader={<SkeletonUser/>}
                scrollableTarget="scrollableDiv"
              >
                <MembersList ref={memberListRef}>
                  {bdaysTodayUsers
                    .map(({ id, name, image, sex }) => {
                      const arrayName = name.toLowerCase().split(" ");

                      return (
                        <MemberItem
                          title={renderTitleMember({ sex, name })}
                          to={`${urls.ROUTES.PROFILE}/${id}`}
                          key={id}
                        >
                          <Avatar url={image?.url} size="120px" />
                          <MemberName title={renderName(arrayName)}>
                            {renderName(arrayName)}
                          </MemberName>
                        </MemberItem>
                      );
                    })}
                
                </MembersList>
              </InfiniteScroll>
            </Flex>
          )
        )}
      </BirthdayTodayBox>
    </Portal>
  );
};

export default BirthdaysToday;
