import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Portal from "templates/Portal";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { PageTitle } from "components/core/Typography/Typography";
import { Creators as HoleriteActions } from "store/ducks/holerite";
import { Creators as GuestAuthActions } from "store/ducks/guestAuth";
import moment from "moment";
import Button from "../../components/core/Button";
import { Form, Error, ButtonOutlined, Footer } from "./HoleriteStyles";
import { Payroll } from "./Payroll/Payroll";

const HoleriteCibra = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();

  const {
    isLoading,
    isLoadingPdf,
    isLoadingTotem,
    isError,
    payroll,
    error,
  } = useSelector((state) => state.holerite);

  const [dateCurrent] = useState(new Date());
  const [monthCurrent] = useState(
    (moment(dateCurrent).month() + 1).toString().padStart(2, "0"),
  );
  const [yearCurrent] = useState(moment(dateCurrent).year());
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [selectedKind, setSelectedKind] = useState("");

  // eslint-disable-next-line no-unused-vars
  const onSubmit = ({ period, kind }) => {
    setSelectedPeriod(period);
    setSelectedKind(kind);

    dispatch(HoleriteActions.getPayroll({ period, kind }));
  };

  const downloadPdf = () => {
    dispatch(
      HoleriteActions.getPayrollPdf({
        period: selectedPeriod,
        kind: selectedKind,
      }),
    );
  };

  const downloadTotem = () => {
    dispatch(
      HoleriteActions.getPayrollTotem({
        period: selectedPeriod,
        kind: selectedKind,
      }),
    );
  };

  const accessTime = useCallback(() => {
    if (localStorage.getItem("roles") === "guest") {
      setTimeout(() => {
        localStorage.removeItem("headers");
        dispatch(GuestAuthActions.guestAuthInvalidate());
      }, 30000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    accessTime();

    const period = `${yearCurrent}-${monthCurrent}`;
    const kind = "";

    onSubmit({ period, kind });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const payrolls = [
    { id: "", name: "Mensal" },
    { id: "1313", name: "13º salário " },
    { id: "ADIA", name: "Adiantamento" },
    { id: "FERI", name: "Férias" },
    { id: "PPRA", name: "Adiantamento PPR" },
    { id: "PPRP", name: "PPR Pagamento Final" },
  ];

  return (
    <Portal>
      <PageTitle title={intl.messages["menu.holerite"]}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <select {...register("kind")}>
            {payrolls.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </select>
          <input
            type="month"
            defaultValue={`${yearCurrent}-${monthCurrent}`}
            {...register("period")}
          />

          <Button type="submit" name="Carregar" isLoading={isLoading} />
        </Form>
      </PageTitle>
      {isError ? (
        <Error>
          <h3>{error.message}</h3>
        </Error>
      ) : (
        !isLoading && <Payroll data={payroll} />
      )}
      {!isError && !isLoading && (
        <Footer>
          {localStorage.getItem("roles") === "guest" ? (
            <ButtonOutlined onClick={() => downloadTotem()}>
              {isLoadingTotem ? "Carregando" : "Imprimir totem"}
            </ButtonOutlined>
          ) : (
            <Button
              name="Imprimir"
              onClick={downloadPdf}
              isLoading={isLoadingPdf}
            />
          )}
        </Footer>
      )}
    </Portal>
  );
};

export default HoleriteCibra;
