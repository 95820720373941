import styled from "styled-components";
import css from "@styled-system/css";
import theme from "styles/theme";
import Flex from "components/core/Grid/Flex";
import { style } from "../../../style";

export const PublicationContainer = styled("div")(
  css({
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    marginTop: "16px",
  }),
  ({ showComments }) =>
    showComments &&
    css({
      width: "100%",
    }),
);

export const PublicationContent = styled("div")(
  css({
    display: "flex",
    flexDirection: "column",
    width: "270px",
    paddingBottom: "90px",

    "@media (max-width: 539px)": {
      width: "95%",
      margin: "0 auto",
    },

    "@media (min-width: 540px) and (max-width: 766px)": {
      width: "500px",
    },

    "@media (min-width: 767px)": {
      width: "590px",
    },
  }),
);

export const GoBackButton = styled("button")(
  css({
    width: "38px",
    height: "38px",
    border: "none",
    cursor: "pointer",
    backgroundColor: "transparent",
    alignItems: "center",
    borderRadius: "100%",
    margin: "10px 0 10px 10px",

    "@media (min-width: 365px)": {
      margin: "10px 0 10px 0px",
    },
    "@media (min-width: 1390px)": {
      position: "absolute",
      left: 3,
    },

    svg: {
      width: "38px",
      height: "38px",
      strokeWidth: "0",
    },
    "svg path": {
      fill: "black",
      fillOpacity: "0.035",
      stroke: "secondaryColor",
    },
    ":hover, :focus": {
      opacity: "0.8",
      "svg path": {
        stroke: "secondaryColor",
      },
    },
  }),

  ({ isStorePage }) =>
    isStorePage
      ? css({
          margin: "20px 0 10px 10px",
          "@media (min-width: 1024px)": {
            margin: "0px 0 0px 10px",
            position: "absolute",
            left: 3,
          },
        })
      : css({
          margin: "10px 0 10px 10px",
          "@media (min-width: 365px)": {
            margin: "10px 0 10px 0px",
          },
          "@media (min-width: 1390px)": {
            position: "absolute",
            left: 3,
          },
        }),
  ({ isProfilePage }) =>
    isProfilePage &&
    css({
      "@media (min-width: 365px)": {
        margin: "10px 0 0px 10px",
      },
      "@media (min-width: 1390px)": {
        position: "static",
        margin: "15px 0 0px 10px",
      },
    }),
  ({ isCursesPage }) =>
    isCursesPage &&
    css({
      margin: "0px 0px 15px 0px",
      "@media (min-width: 1024px)": {
        margin: "20px 10px 0px 10px",
      },
      "@media (min-width: 1390px)": {
        position: "absolute",
        left: 2,
      },
    }),
  ({ isSubMenuPardiniPage }) =>
    isSubMenuPardiniPage &&
    css({
      margin: "0px 0px 15px 0px",
      "@media (min-width: 1024px)": {
        margin: "0px 0px 20px 0px",
      },
      "@media (min-width: 1390px)": {
        position: "initial",
        left: 0,
        margin: "0px 0px 20px 0px",
      },
    }),
  ({ isPardiniPage }) =>
    isPardiniPage &&
    css({
      margin: "0px 0px 15px 0px",
      "@media (min-width: 1024px)": {
        margin: "0px 0px 0px 0px",
      },
      "@media (min-width: 1390px)": {
        position: "absolute",
        left: 2,
      },
    }),
);

export const Card = styled("div")(
  css({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: 5,
    video: {
      borderRadius: "12px 12px 0 0",
    },
  }),
);

export const Image = styled("img")(
  css({
    width: "270px",
    borderRadius: "12px",

    "@media (min-width: 359px) and (max-width: 539px)": {
      width: "100%",
    },

    "@media (min-width: 540px) and (max-width: 766px)": {
      width: "500px",
    },

    "@media (min-width: 767px)": {
      width: "590px",
    },
  }),
);

export const CoverImage = styled(Image)(
  css({
    borderRadius: "12px 12px 0 0",
    maxHeight: "750px",
    objectFit: "cover",
  }),
  ({ showComments }) =>
    showComments &&
    css({
      maxWidth: "80px",
      maxHeight: "80px",
      objectFit: "cover",
      borderRadius: "10px",
    }),
);

export const Video = styled("video")(
  css({
    width: "590px",
    height: "340px",
    borderRadius: "12px",
    "@media(max-width: 400px)": {
      width: "100%",
      height: "auto",
    },
  }),
  ({ showComments }) =>
    showComments &&
    css({
      maxWidth: "80px",
      maxHeight: "80px",
      objectFit: "cover",
      borderRadius: "10px",
    }),
);

export const Header = styled("div")(
  css({
    bg: "paleGrey",
    display: "flex",
    flexDirection: "column",
    padding: "22px 20px 5px 10px",
    borderRadius: "0 0 16px 16px",
    width: "100%",
  }),
);

export const PubDate = styled("h3")(
  css({
    color: "gray",
    fontFamily: "text",
    lineHeight: "20px",
    fontSize: 2,
    margin: "15px 0 0 2px",
  }),
);

export const PubTitleContainer = styled("div")(
  css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  }),
);

export const PubTitle = styled("h1")(
  css({
    display: "flex",
    maxWidth: "480px",
    color: "backText",
    fontFamily: "heading",
    lineHeight: "26px",
    fontWeight: "600",
    fontSize: 4,
    margin: "8px 0 0 0",
  }),
);

export const FavoritePost = styled("button")(
  css({
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    border: "0px",
    outline: "0px",
    backgroundColor: "transparent",
    margin: "8px 0 0 0",

    svg: {
      width: 19,
      height: 22,
    },

    "svg path": {
      fill: "secondaryColor",
    },
  }),
);

export const Author = styled("h2")(
  css({
    color: "secondaryColor",
    fontFamily: "text",
    lineHeight: "21px",
    fontSize: "18px",
    margin: 0,
    textTransform: "capitalize",
  }),
);

export const TextContainer = styled(Flex)(
  css({
    padding: "0px 20px 10px 20px",
    maxWidth: "465px",

    "@media (min-width: 767px)": {
      maxWidth: "100%",
    },
    pre: {
      marginBottom: 0,
    },
  }),
);

export const Text = styled("p")(
  css({
    color: "gray",
    whiteSpace: "pre-line",
    fontFamily: "text",
    lineHeight: "28px",
    fontSize: "18px",
    marginBottom: 0,
    wordWrap: "break-word",
    "ul, ol": {
      li: {
        marginLeft: "33px",
      },
    },
  }),
);

export const Link = styled("a")(
  css({
    bg: "paleGrey",
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 24px",
    borderRadius: "8px",
    marginBottom: 5,
    transition: "all .3s ease-out",
    wordWrap: "break-word",
    p: {
      textDecoration: "none",
      color: "secondaryColor",
      fontSize: "18px",
      lineHeight: "24px",
      margin: 0,
    },
    "svg path": {
      stroke: theme.colors.gray,
    },
    "&:hover": {
      textDecoration: "none",
      bg: "secondaryColor",
      p: {
        color: "white",
      },
      "svg path": {
        stroke: "white",
      },
    },
  }),
);

export const FormSection = styled(Flex)(
  css({
    padding: "0px 20px 10px 10px",
    maxWidth: "465px",

    "@media (min-width: 767px)": {
      maxWidth: "100%",
    },

    bg: "paleGrey",
    borderRadius: "20px",

    label: {
      color: "backText",
    },
    "input[type='text']": {
      bg: "white",
      padding: "12px 16px",
      fontSize: "18px",
      borderRadius: "8px",
      border: "none",
    },
  }),
);

export const FormTitle = styled(Flex)(
  css({
    color: "charcoalGrey",
    fontFamily: "heading",
    fontWeight: "600",
    lineHeight: "32px",
    fontSize: 3,
    margin: 0,
  }),
);

export const ButtonsContainer = styled("div")(
  css({
    px: 16,
    py: 16,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    marginBottom: "20px",
    borderBottom: "1px solid",
    borderColor: "grayDefaultColor",
    background: style.colors.primaryColor,
    borderRadius: "8px",
  }),
);

export const CommentsContainer = styled("div")(
  css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    position: "absolute",
    height: "100%",
    transition: "1s ease-in",
  }),
  ({ showComments }) =>
    showComments
      ? css({
          width: "100%",
        })
      : css({
          width: "0%",
        }),
);

export const HeaderComments = styled("div")(
  css({
    marginLeft: "16px",
    marginBottom: "12px",
    backgroundColor: "white",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  }),
);

export const TitleCommentsContainer = styled("div")(
  css({
    marginLeft: "15px",
    maxWidth: "210px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  }),
);

export const TitleComments = styled("h2")(
  css({
    fontFamily: "heading",
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "25px",
    color: "backText",
    marginTop: "10px",
  }),
);

export const AuthorCommentsContainer = styled("div")(
  css({
    display: "flex",
    flexDirection: "row",
  }),
);

export const AuthorComments = styled("h4")(
  css({
    padding: "5px 0 0 7px",
    fontFamily: "text",
    fontSize: "14px",
    lineHeight: "17px",
    color: "secondaryColor",
  }),
);

export const CoverComments = styled("div")(
  css({
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    paddingRight: "20px",
  }),
);

export const ContainerConfetti = styled("div")(
  css({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    pointerEvents: "none",
    visibility: "hidden",
  }),
  ({ showContainer }) =>
    showContainer &&
    css({
      visibility: "visible",
    }),
);

export const ContainerCard = styled("div")(
  css({
    width: "100%",
    display: "flex",
    justifyContent: "center",
  }),
);

export const ModalCardCongratulation = styled("div")(
  css({
    display: "flex",
    marginTop: "24px",
    borderRadius: "8px",
    visibility: "hidden",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.24)",
  }),
  ({ showCardModal }) =>
    showCardModal &&
    css({
      animation: "showCardModal 3.5s",
      animationDelay: "2s",
      "@keyframes showCardModal": {
        "0%": {
          width: 0,
          height: 0,
        },

        "5%": {
          width: 330,
          height: 130,
          visibility: "visible",
        },

        "10%": {
          width: 300,
          height: 100,
        },

        "15%": {
          width: 320,
          height: 110,
        },

        "20%": {
          width: 300,
          height: 100,
        },

        "95%": {
          width: 300,
          height: 100,
        },

        "100%": {
          width: 0,
          height: 0,
        },
      },
    }),
);

export const TextCard = styled("h3")(
  css({
    fontSize: 4,
    fontWeight: "600",
    color: "backText",
    lineHeight: "32px",
    textAlign: "center",
    fontFamily: "heading",
    visibility: "hidden",
  }),
  ({ showTextCard }) =>
    showTextCard &&
    css({
      animation: "showTextCard 3.2s",
      animationDelay: "2.1s",
      "@keyframes showTextCard": {
        "0%": {
          visibility: "visible",
        },

        "95%": {
          opacity: 1,
        },

        "100%": {
          opacity: 0,
          visibility: "hidden",
        },
      },
    }),
);
