import styled, { css } from "styled-components";
import theme from "styles/theme";

export const FistLineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 90%;

  font-size: 1rem;

  >svg{
      width: 0.6rem;
      height: 0.6rem;
      path {
          stroke: #808080;
          stroke-width: 3;
      }

      cursor: pointer;
  }

  padding-bottom: 1rem;
`

export const IconAndTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #808080;

  svg{
      width: 1.5rem;
      height: 1.5rem;
      path {
          stroke: #808080;
      }
  }

  span{
    font-size: 1.25rem;
    font-family: 'RubikMedium', sans-serif;
    line-height: 2rem;
    text-transform: lowercase;
  }
`

export const UserContainer = styled.div`
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  /* margin-top: 0.5rem; */
  border-top: 1px solid #E4E4E5;

  margin-bottom: 0 !important;
  height: 5rem;

`

export const AvatarAndNameContainer = styled.div`
  height: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;
`

export const NameAndSectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #808080;

  >h5{
    font-family: 'RubikMedium', sans-serif;
    margin-bottom: 0 !important;
  }

  >span{
    font-size: 1rem;
  }
`

export const HourContainer = styled.span`
  height: 100%;
  width: 4.7rem;

  color: #808080;
  text-align: right;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
`
