import React, { useState, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { Input } from "components/core/Form/Form";

const OpenQuestion = ({ contentId, question, handleOpenQuestion,endDate,showLimitDateAlert}) => {
  const intl = useIntl();
  const idUser = localStorage.getItem("idUser");
  const date = useState(new Date());
  const { id: questionId, open_responses: openResponses } = question;
  const [questionAnswer, setQuestionAnswer] = useState({
    canvas_content_id: contentId,
    open_question_id: questionId,
    response: null,
  });

  const myAnswer = openResponses?.find((question) => question.user_id === Number(idUser));
  const openQuestionExpired = useMemo(() => Date.parse(endDate) < Date.parse(date[0]), [
    endDate,
    date,
  ]);

  useEffect(() => {
    questionAnswer.response !== null && handleOpenQuestion(questionAnswer);
    // eslint-disable-next-line
  }, [questionAnswer]);

  return (
    <div onClick={openQuestionExpired ?  showLimitDateAlert : ()=>{} }>
      <Input
        autocomplete={openQuestionExpired ? "off" : "on"}
        disabled={!!myAnswer}
        value={myAnswer?.response || questionAnswer?.response}
        name={`open-question${questionId}`}
        placeholder={intl.messages["publication.textPage.typeYourAnswer"]}
        onChange={({ target: { value } }) =>
          setQuestionAnswer({ ...questionAnswer, response: value })
        }
      />
    </div>
    
  );
};

OpenQuestion.propTypes = {
  contentId: PropTypes.number.isRequired,
  questionId: PropTypes.number.isRequired,
  handleOpenQuestion: PropTypes.func.isRequired,
};

export default OpenQuestion;
