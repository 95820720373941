/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import InfiniteScroll from "react-infinite-scroller";

import Portal from "templates/Portal";
import Flex from "components/core/Grid/Flex";
import MuralComponent from "components/presentation/Mural";
import Sidebar from "components/presentation/Sidebar";

import { Creators as MuralActions } from "store/ducks/mural";

import { useAnalytics } from "utils/hooks";
import { history } from "helpers/sharedHelpers";
import urls from "utils/constants/urls";
import { style } from "style";

import NotFoundGeneric from "../../components/presentation/NotFoundGeneric/NotFoundGeneric";
import { CircleSearched, WordSearched, HeaderInSideBar } from "./MuralStyle";
import MuralLoadMoreSkeleton from "./MuralLoadMoreSkeleton";
import MuralSkeleton from "./MuralSkeleton";

useAnalytics("screen_feed");

const Mural = ({ isFavorite }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [scrollParentRef, setScrollParentRef] = useState();
  const {
    publications,
    favoritePublications,
    searchingFor,
    isLoading,
    isLoadingFavorites,
    page,
    pageFavorites,
    hasMore,
    hasMoreFavorites,
    selectTags,
  } = useSelector(({ mural }) => mural);

  const { user, isLoading: isLoadingUser } = useSelector((state) => state.user);

  useEffect(() => {
    style.names.nameApp === "Pintou Parceria Suvinil" &&
      !isLoadingUser &&
      user?.auto_declared === null &&
      history.push(`${urls.ROUTES.RESEARCH_SUVINIL}`);
  }, [user, isLoadingUser]);

  const contextSearchRequest = useCallback(
    ({ valueText, tags }) => {
      if (valueText === "") {
        dispatch(MuralActions.clean());
      } else {
        dispatch(
          MuralActions.publicationsRequest({
            search: valueText,
            page: 1,
            favorite: isFavorite,
            selectTags: tags,
          }),
        );
      }
    },
    [dispatch, isFavorite],
  );

  const dispatchPublicationsRequest = useCallback(
    () =>
      dispatch(
        MuralActions.publicationsRequest({
          search: "",
          page: 1,
          favorite: isFavorite,
          selectTags: [],
        }),
      ),
    [dispatch, isFavorite],
  );

  useEffect(() => {
    if (((!publications.length && hasMore) || isFavorite) && !searchingFor) {
      dispatchPublicationsRequest();
    }
  }, [dispatchPublicationsRequest, isFavorite, publications, searchingFor]);

  const fetchMoreData = () => {
    hasMore &&
      dispatch(
        MuralActions.publicationsRequest({
          search: searchingFor,
          page: isFavorite ? pageFavorites + 1 : page + 1,
          favorite: isFavorite,
          selectTags,
        }),
      );
  };

  return (
    <Portal
      contextSearch={{
        contextName: "Mural",
        contextAction: contextSearchRequest,
      }}
      sidebarContext={<Sidebar eventList birthdaysList weekScore />}
      setRef={setScrollParentRef}
      scrollRef={scrollParentRef}
      textBtnInSideBar="Próximos Eventos"
    >
      <Flex flexDirection="column" p={3}>
        {searchingFor && (
          <WordSearched>
            <CircleSearched />
            {`${intl.messages["general.textPage.resultsFor"]} “${searchingFor}” ${intl.messages["general.textPage.in"]}`}{" "}
            <b>{intl.messages["general.textPage.search"]}</b>{" "}
            {!!selectTags.length && (
              <>
                {selectTags.length > 1 ? "nas tags" : "na tag"}{" "}
                {selectTags.map((tag, i) => [
                  i > 0 && ", ",
                  <b key={tag}>{tag}</b>,
                ])}
              </>
            )}
          </WordSearched>
        )}
        {(!isFavorite && isLoading) || (isFavorite && isLoadingFavorites) ? (
          <MuralSkeleton />
        ) : (!isFavorite && publications.length > 0) ||
          (isFavorite && favoritePublications.length > 0) ? (
          <InfiniteScroll
            pageStart={1}
            initialLoad={false}
            loadMore={fetchMoreData}
            hasMore={isFavorite ? hasMoreFavorites : hasMore}
            useWindow={false}
            threshold={50}
            getScrollParent={() => scrollParentRef}
            loader={<MuralLoadMoreSkeleton />}
          >
            <Flex
              flexDirection="column"
              alignItems="center"
              marginBottom="50px"
            >
              <HeaderInSideBar />
              <MuralComponent
                publications={isFavorite ? favoritePublications : publications}
                isLoading={isLoading || isLoadingFavorites}
              />
            </Flex>
          </InfiniteScroll>
        ) : (
          <NotFoundGeneric
            textPage={intl.messages["publication.textPage.hasNoResults"]}
          />
        )}
      </Flex>
    </Portal>
  );
};

export default Mural;
