export const teamsActivities = {
  "Pintou Parceria Suvinil": [
    { id: "consumer", value: "consumer", text: "Consumidor" },
    { id: "painter", value: "painter", text: "Pintor" },
    {
      id: "become_painter",
      value: "become_painter",
      text: "Quero me formar pintor",
    },
    { id: "clerk", value: "clerk", text: "Balconista" },
    { id: "architec", value: "architec", text: "Arquiteto / Decorador" },
  ],
  Vedacit: [],
  mestreconstrutor: [],
};
