import styled from "styled-components";
import css from "@styled-system/css";
import Flex from "components/core/Grid/Flex";
import theme from "styles/theme";

export const AuthWrapper = styled(Flex)(
  css({
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    background: `linear-gradient(
      35deg,
      ${theme.colors.primaryColor},
      ${theme.colors.secondaryColor} 100%
      )`,
  }),
);

export const AuthContainer = styled(Flex)(
  css({
    display: "flex",
    width: "40%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
    padding: "42px",
    borderRadius: "8px",
  }),
);

export const LogoCompany = styled("img")(
  css({ width: "34%", marginBottom: "24px" }),
);

export const AuthParagraph = styled("p")(
  css({
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    marginBottom: "24px",
    color: `${theme.colors.textConfig}`,
  }),
);

export const AuthLink = styled("a")(
  css({
    color: `${theme.colors.secondaryColor}`,
    ":hover": {
      color: `${theme.colors.primaryColor}`,
    },
  }),
);

export const AuthForm = styled("form")(
  css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    padding: "0 24px",
  }),
);
