import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";

import { Creators as ActionHolerite } from "store/ducks/holerite";

import Portal from "templates/Portal";
import { PageTitle } from "components/core/Typography/Typography";

const ServicesMachado = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { isLoadingMachado, linkHoleriteMachado } = useSelector(
    (state) => state.holerite,
  );

  const dispatchGetHoleriteMachado = useCallback(() => {
    dispatch(ActionHolerite.getHoleriteMachado());
  }, [dispatch]);

  useEffect(() => {
    dispatchGetHoleriteMachado();
  }, [dispatchGetHoleriteMachado]);

  return (
    <Portal>
      <PageTitle title={intl.messages["menu.services"]} />
      {isLoadingMachado ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <Skeleton duration={0.8} width={1000} height={480} />
        </div>
      ) : (
        !!linkHoleriteMachado && (
          <iframe
            src={linkHoleriteMachado}
            title="Serviços"
            style={{
              height: "80%",
              width: "95%",
              margin: "auto",
              borderColor: "transparent",
            }}
            sandbox="allow-same-origin allow-scripts"
          />
        )
      )}
    </Portal>
  );
};

export default ServicesMachado;
