import { createActions, createReducer } from "reduxsauce";

export const { Creators, Types } = createActions({
  setAlertsRequest: ["messages"],
  setAlertsSuccess: ["alerts"],
  setAlertsFail: ["error"],
});

const INITIAL_STATE = {
  alerts: {},
};

const setAlertsRequest = () => ({
  ...INITIAL_STATE,
});

const setAlertsSuccess = (state, alerts) => ({
  ...state,
  ...alerts,
});

const setAlertsFail = (state, error) => ({
  ...state,
  error,
  alerts: [],
});

export default createReducer(INITIAL_STATE, {
  [Types.SET_ALERTS_REQUEST]: setAlertsRequest,
  [Types.SET_ALERTS_SUCCESS]: setAlertsSuccess,
  [Types.SET_ALERTS_FAIL]: setAlertsFail,
});
