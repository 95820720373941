import styled from "styled-components";
import css from "@styled-system/css";
import Flex from "components/core/Grid/Flex";
import theme from "styles/theme";

export const Content = styled(Flex)(
  css({
    // overflow: "auto", //removing repeated scrollbar 
    flexDirection: "column",
    div: {
      mb: 3,
    },
    // "@media(min-width: 769px)": {
    //   minHeight: 345,
    //   maxHeight: 496,
    // },
  }),
);

export const TextKind = styled("p")(
  css({
    fontSize: 2,
    color: "gray",
    fontFamily: "text",
    whiteSpace: "pre-line",
    paddingLeft: "30px !important",
  }),
);

export const ImageKind = styled("img")(
  css({
    width: "100%",
    objectFit: "contain",
  }),
);

export const QuizzContainer = styled("label")(
  css({
    width: 232,
    fontSize: 18,
    borderRadius: 8,
    bg: "focusInput",
    color: "#3a3a3a",
    fontFamily: "text",
    textAlign: "center",
    span: {
      mt: -24,
      p: "8px 24px",
      display: "block",
      cursor: "pointer",
      position: "relative",
      wordWrap: "break-word",
    },
  }),
  ({ selected }) =>
    selected === true
      ? css({
        bg: "green",
        color: "white",
        fontWeight: "bold",
      })
      : selected === false &&
      css({
        bg: "red",
        color: "white",
        fontWeight: "bold",
      }),
);

export const QuizzOptions = styled("input")(
  css({
    visibility: "hidden",
    ":hover": {
      cursor: "pointer",
    },
    "&:checked + span": {
      bg: "blue",
      height: "100%",
      color: "white",
      borderRadius: 8,
      fontWeight: "bold",
    },
  }),
  ({ verify }) =>
    verify === true
      ? css({
        "&:checked + span": {
          bg: "green",
        },
      })
      : verify === false &&
      css({
        "&:checked + span": {
          bg: "red",
        },
      }),
);

export const TitleContent = styled("h2")(
  css({
    marginTop: "30px",
    mb: 4,
    fontSize: 4,
    color: "backText",
    fontFamily: "heading",
  }),
);

export const Link = styled("a")(
  css({
    bg: "paleGrey",
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 24px",
    borderRadius: "8px",
    marginBottom: 5,
    transition: "all .3s ease-out",
    wordWrap: "break-word",
    p: {
      textDecoration: "none",
      color: "secondaryColor",
      fontSize: "18px",
      lineHeight: "24px",
      margin: 0,
    },
    "svg path": {
      stroke: theme.colors.gray,
    },
    "&:hover": {
      textDecoration: "none",
      bg: "secondaryColor",
      p: {
        color: "white",
      },
      "svg path": {
        stroke: "white",
      },
    },
  }),
);

export const ItemFolder = styled(Flex)(
  css({
    justifyItems: "left",
    borderBottom: `1px solid ${theme.colors.paleGrey}`,
  }),
);

export const LinkFile = styled("a")(
  css({
    textDecoration: "none !important",
    "#Rectangle_Copy_5": {
      fill: theme.colors.primaryColor,
    },
  }),
);

export const SubTitle = styled("h2")(
  css({
    fontSize: 16,
    color: "backText",
    fontFamily: "textLight",
    wordBreak: "break-all",
  }),
);

export const NameDetails = styled(SubTitle)(
  css({
    m: 0,
    fontSize: 1,
    color: "gray",
  }),
);

export const NameFile = styled("div")(
  css({
    width: "100%",
    paddingTop: "0.5rem",
  }),
);