import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import InfiniteScroll from "react-infinite-scroller";
import Flex from "components/core/Grid/Flex";
import { PageTitle } from "components/core/Typography/Typography";

import { Creators as StoreActions } from "store/ducks/store";

import { smartFormatterDate } from "helpers/sharedHelpers";
import { ReactComponent as IconCoins } from "assets/img/icons/iconCoins.svg";
import { ReactComponent as ArrowLeftIcon } from "assets/img/icons/arrow-left.svg";

import { Overflow } from "components/presentation/Sidebar/SidebarStyle";
import { ImgProduct, NameProduct, ValuePrice } from "../StoreStyle";
import { ContainerStore, RequestDate, MoreInformations, DefaultMessage } from "./SidebarStoreStyle";
import SkeletonSidebarProduct from "./SkeletonSidebarProduct";

const SidebarStore = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { 
          productsDespatched, hasMoreDespatched, pageDespatched,
          productsNotDespatched, hasMoreNotDespatched, pageNotDespatched,
        } = useSelector((state) => state.store);

  const [overflowHeight, setOverflowHeight] = useState();

  const [isDesktop, setDesktop] = useState(window.innerWidth > 1024);
  const changeDataPosition = () => {
    setDesktop(window.innerWidth > 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", changeDataPosition);
    return () => window.removeEventListener("resize", changeDataPosition);
  });

  useEffect(() => {
    setOverflowHeight(document.body.getBoundingClientRect().height / 2 - 101);
  }, []);

  const fetchMoreDespatched = () => {
    hasMoreDespatched &&
      dispatch(
        StoreActions.getRescueDespatched({
          page: pageDespatched + 1,
        }),
      );
  };

  const fetchMoreNotDespatched = () => {
    hasMoreNotDespatched &&
      dispatch(
        StoreActions.getRescueNotDespatched({
          page: pageNotDespatched + 1,
        }),
      );
  };

  return (
    <Flex flex={1} flexDirection="column">
      {isDesktop && (
        <Flex id="sectionAwait" flex={3} flexDirection="column" width="100%">
          <PageTitle title={intl.messages["store.requestedProducts"]} fontSize={3} />
          <Overflow
            flexDirection="column"
            spaceChildren={3}
            height={`${overflowHeight}px`}
            style={{"overflow-x":"hidden"}}
          > 
            {productsNotDespatched?.length > 0 &&
              <InfiniteScroll
                pageStart={1}
                initialLoad={false}
                loadMore={fetchMoreNotDespatched}
                hasMore={hasMoreNotDespatched}
                useWindow={false}
                threshold={50}
                loader={<SkeletonSidebarProduct/>}
              >
                <Flex flexDirection="column" spaceChildren={3} pb={20} height={`auto`}>
                  {productsNotDespatched.map((product) => {
                    if (product.product) {
                      const { image, name, valuescore, default_message_to_send } = product.product;
                      const id = product.cart_id;
                      const date = smartFormatterDate(
                        product.created_at,
                        "L",
                      );

                      return (
                        <ContainerStore key={id}>
                          <ImgProduct src={image.url} />
                          <Flex width="100%" flexDirection="column">
                            <Flex justifyContent="space-between">
                              <NameProduct>{name}</NameProduct>
                            </Flex>
                            <Flex mb="2px" alignItems="center" spaceChildren={2}>
                              <IconCoins width={18} height={18} />
                              <ValuePrice sidebar>{valuescore}</ValuePrice>
                            </Flex>
                            <RequestDate>
                              {intl.messages["store.textPage.requested"]}: {date}
                            </RequestDate>

                            <MoreInformations
                              onClick={() =>
                                document
                                  .querySelector(`#purchasehistory-${id}`)
                                  .classList.toggle("uncollapsed")
                              }
                              key={`purchasehistory-${id}`}
                              id={`purchasehistory-${id}`}
                              collapse
                            >
                              <Flex>
                                <ArrowLeftIcon className="collapse-trigger"/>
                                <Flex width="100%" flexDirection="column">
                                  {intl.messages["store.textPage.moreDetails"]}
                                  <DefaultMessage className="collapse-element">
                                    {default_message_to_send || intl.messages[
                                        "store.textPage.allRightContactHRToRedeemYourProduct"
                                    ]}
                                  </DefaultMessage>
                                </Flex>
                              </Flex>
                            </MoreInformations>
                          </Flex>
                        </ContainerStore>
                      );
                    }
                    return null;
                  })}
                </Flex>
              </InfiniteScroll>
              }
          </Overflow>
        </Flex>
      )}

      <Flex id="sectionAwait" flex={3} flexDirection="column" width="100%">
        <PageTitle
          title={intl.messages["store.despatchedProducts"]}
          fontSize={3}
        />
        <Overflow
          flexDirection="column"
          spaceChildren={3}
          height={`${overflowHeight}px`}
          style={{"overflow-x":"hidden"}}
        >
          {productsDespatched?.length > 0 &&
            <InfiniteScroll
              pageStart={1}
              initialLoad={false}
              loadMore={fetchMoreDespatched}
              hasMore={hasMoreDespatched}
              useWindow={false}
              threshold={50}
              loader={<SkeletonSidebarProduct/>}
            >
              <Flex flexDirection="column" spaceChildren={3} pb={20} height={`auto`}>
                {productsDespatched.map((product) => {
                  const { image, name, valuescore, default_message_to_send } = product.product;
                  const  id  = product.cart_id;
                  const date = smartFormatterDate(
                    product.updated_at,
                    "L",
                  );

                  return (
                    <ContainerStore spaceChildren={3} key={id}>
                      <ImgProduct src={image.url} sidebar />
                      <Flex width="100%" flexDirection="column">
                        <Flex justifyContent="space-between">
                          <NameProduct sidebar>{name}</NameProduct>
                        </Flex>
                        <Flex mb="2px" alignItems="center" spaceChildren={2}>
                          <IconCoins width={18} height={18} />
                          <ValuePrice sidebar>{valuescore}</ValuePrice>
                        </Flex>
                        <RequestDate>
                          {intl.messages["store.textPage.despatched"]}: {date}
                        </RequestDate>

                        <MoreInformations
                          onClick={() =>
                            document
                              .querySelector(`#purchasehistory-${id}`)
                              .classList.toggle("uncollapsed")
                          }
                          key={`purchasehistory-${id}`}
                          id={`purchasehistory-${id}`}
                          collapse
                        >
                          <Flex>
                            <ArrowLeftIcon className="collapse-trigger"/>
                            <Flex width="100%" flexDirection="column">
                              {intl.messages["store.textPage.moreDetails"]}
                              <DefaultMessage className="collapse-element">
                                {default_message_to_send || intl.messages[
                                    "store.textPage.allRightContactHRToRedeemYourProduct"
                                ]}
                              </DefaultMessage>
                            </Flex>
                          </Flex>
                        </MoreInformations>
                      </Flex>
                    </ContainerStore>
                  );
                })}
              </Flex>
            </InfiniteScroll>
          }
        </Overflow>
      </Flex>
    </Flex>
  );
};

export default SidebarStore;
