import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Creators as ActionTeamCibra } from "store/ducks/teamCibra";

import Portal from "templates/Portal";
import { PageTitle } from "components/core/Typography/Typography";
import SkeletonApps from "components/presentation/Apps/SkeletonApps";
import MenuComponent from "components/presentation/GroupPardini/MenuComponent";

const SocialResponsibility = () => {
  const dispatch = useDispatch();
  const { isLoading, listMenuCibra } = useSelector(
    (state) => state.teamCibra,
  );

  const dispatchGetCibraMenu = useCallback(() => {
    dispatch(ActionTeamCibra.getCibraMenu(1));
  }, [dispatch]);

  useEffect(() => {
    dispatchGetCibraMenu();
  }, [dispatchGetCibraMenu]);

  return (
    <Portal>
      <PageTitle title={listMenuCibra.title} />
      {isLoading ? (
        <SkeletonApps />
      ) : (
        <MenuComponent listMenu={listMenuCibra.root_pages} type="wayCibraOfBeing"/>
      )}
    </Portal>
  );
};

export default SocialResponsibility;
