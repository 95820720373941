/* eslint-disable react/no-array-index-key */
import React from "react";
import Skeleton from "react-loading-skeleton";
import Flex from "components/core/Grid/Flex";
import { ProductInfo, OutOfStock } from "./StoreStyle";

const SkeletonStore = () => {
  const skeletonRepeat = 6;

  return (
    <Flex mt={2} flexDirection="column" spaceChildren={4} p={3}>
      <Flex flexWrap="wrap" spaceChildren={4}>
        {[...Array(skeletonRepeat)].map((_val, index) => (
          <div key={index}>
            <ProductInfo>
              <Skeleton duration={0.8} width={142} height={118} />
            </ProductInfo>
            <OutOfStock>
              <Skeleton duration={0.8} width={98} height={16} />
            </OutOfStock>
          </div>
        ))}
      </Flex>
    </Flex>
  );
};

export default SkeletonStore;
