import React from "react";

import * as S from "./PrivacyPolicyStyle";

export const SecondLevelList = ({children, text, topListCount, currentListCount, terms}) => {

    return (
        <S.ContainerSecondLevelList>
            <div id="main">
                <span >{topListCount}.{currentListCount}.{' '}</span>
                {text}
            </div>


            {terms && terms.length > 0 && terms.map((term, index) => (
                <S.TermThirdLevel>
                    <span >{topListCount}.{currentListCount}.{index+1}.{' '}</span>
                    {topListCount === 1 && currentListCount === 1 ? (
                        <>
                            <span>{term.split('#')[0]}</span>
                            {term.split('#')[1]}
                        </>
                    ):(
                        <>{term}</>
                    )}
                </S.TermThirdLevel>
            ))}
        </S.ContainerSecondLevelList>
    )
}
