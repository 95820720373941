import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { ModalProvider } from "styled-react-modal";
import { history } from "helpers/sharedHelpers";
import { ReactComponent as Error } from "assets/img/icons/error.svg";
import { ReactComponent as Success } from "assets/img/icons/success.svg";
import { ReactComponent as Alert } from "assets/img/icons/alertTriangle.svg";
import Flex from "../Grid/Flex";
import Button from "../Button/Button";
import SurveyMoodModal from "./SurveyMoodModal";
import LikesViewModal from "./LikesViewModal";

import * as S from "./AlertModalStyle";

const AlertModal = () => {
  const { alerts: dataAlerts } = useSelector((state) => state.alert);

  const FancyModalButton = () => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleModal = () => setIsOpen(!isOpen);
    return (
      <S.StyledModal
        data-testid="modal-component"
        isOpen={isOpen}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        type={dataAlerts.type && true}
      >
        {dataAlerts.type === "success" ? (
          <S.ContainerType type={dataAlerts.type}>
            <Success />
          </S.ContainerType>
        ) : (
          dataAlerts.type === "errors" && (
            <S.ContainerType type={dataAlerts.type}>
              <Error />
            </S.ContainerType>
          )
        )}
        <S.ContainerModal>
          {dataAlerts.title && <S.TitleModal>{dataAlerts.title}</S.TitleModal>}

          {Array.isArray(dataAlerts.msg) ? (
            dataAlerts.msg.map((msg) => <S.SubTitle>{msg}</S.SubTitle>)
          ) : (
            <S.SubTitle>{dataAlerts.msg}</S.SubTitle>
          )}
          {dataAlerts.buttons ? (
            <Flex spaceChildren={4}>
              {dataAlerts.buttons.map((bt) => {
                const { variant, title, event } = bt;
                return (
                  <Button
                    name={title}
                    variant={
                      dataAlerts.needToUpdateAddress && title === "Confirmar"
                        ? Array.isArray(variant)
                          ? variant.push("disabled")
                          : ["disabled"]
                        : variant
                    }
                    onClick={() => {
                      event && event();
                      toggleModal();
                    }}
                  />
                );
              })}
            </Flex>
          ) : (
            <Button onClick={toggleModal} name="OK" />
          )}

          {dataAlerts.needToUpdateAddress === true && (
            <>
              <S.AlertMessage>
                <Alert />
                para confirmar a compra,
                <S.ButtonAlertMessage
                  onClick={() => {
                    toggleModal();
                    history.push(`/editar-perfil`);
                  }}
                >
                  clique aqui
                </S.ButtonAlertMessage>
                e cadastre um endereço no seu perfil
              </S.AlertMessage>
            </>
          )}
        </S.ContainerModal>
      </S.StyledModal>
    );
  };

  return (
    <ModalProvider>
      {!!dataAlerts.isLikesView ? (
        <LikesViewModal postId={dataAlerts.postId} />
      ) : !!dataAlerts.isMoodSurvey ? (
        <SurveyMoodModal />
      ) : (
        Object.keys(dataAlerts).length > 0 && <FancyModalButton />
      )}
    </ModalProvider>
  );
};

AlertModal.propTypes = {
  dataAlerts: PropTypes.object,
};

export default AlertModal;
