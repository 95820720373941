import React from "react";
import Skeleton from "react-loading-skeleton";

import Flex from "components/core/Grid/Flex";

const SkeletonProfile = () => (
  <Flex p={4} height="100%" flexDirection="column" maxWidth="530px">
    <Flex alignItems="center" borderBottom="1px solid #D1D4E3" pb={3} mb={3}>
      <Skeleton duration={0.8} width={120} height={120} circle={true} />
      <Flex ml={3} flexDirection="column">
        <Skeleton duration={0.8} width={130} height={20} />
        <Skeleton duration={0.8} width={84} height={20} />
        <Flex spaceChildren={2}>
          <Skeleton duration={0.8} width={30} height={30} circle={true} />
          <Skeleton duration={0.8} width={30} height={30} circle={true} />
          <Skeleton duration={0.8} width={30} height={30} circle={true} />
          <Skeleton duration={0.8} width={30} height={30} circle={true} />
        </Flex>
      </Flex>
    </Flex>

    <Flex flexDirection="column" borderBottom="1px solid #D1D4E3" pb={3} mb={3}>
      <Skeleton duration={0.8} width={40} height={16} />
      <Skeleton count={2} duration={0.8} width={400} height={20} />
    </Flex>

    <Flex flexDirection="column" borderBottom="1px solid #D1D4E3" pb={3} mb={3}>
      <Skeleton duration={0.8} width={80} height={16} />
      <Skeleton duration={0.8} width={140} height={20} />
    </Flex>

    <Flex flexDirection="column" borderBottom="1px solid #D1D4E3" pb={3} mb={3}>
      <Skeleton duration={0.8} width={120} height={16} />
      <Skeleton duration={0.8} width={200} height={20} />
    </Flex>

    <Flex flexDirection="column" borderBottom="1px solid #D1D4E3" pb={3} mb={3}>
      <Skeleton duration={0.8} width={60} height={16} />
      <Skeleton duration={0.8} width={140} height={20} />
    </Flex>

    <Flex flexDirection="column" borderBottom="1px solid #D1D4E3" pb={3} mb={3}>
      <Skeleton duration={0.8} width={75} height={16} />
      <Skeleton duration={0.8} width={230} height={20} />
    </Flex>

    <Flex flexDirection="column">
      <Skeleton duration={0.8} width={140} height={16} />
      <Skeleton duration={0.8} width={480} height={20} />
    </Flex>
  </Flex>
);

export default SkeletonProfile;
