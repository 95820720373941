/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as IconClose } from "assets/img/icons/iconClose.svg";
import { ReactComponent as IconSearch } from "assets/img/icons/magnifierIcon.svg";

import * as S from "./SearchGenericStyle";

const SearchGeneric = ({ valueString, onChange, onClick, placeholder }) => (
  <S.ContainerSearch filled={valueString}>
    <IconSearch className="magnifier" />
    <S.InputSearch
      value={valueString}
      onChange={onChange}
      placeholder={placeholder}
      data-testid="search-element"
    />
    {valueString && (
      <S.ContainerInputIcons>
        <IconClose className="clearInput" onClick={onClick} />
      </S.ContainerInputIcons>
    )}
  </S.ContainerSearch>
);

SearchGeneric.propTypes = {
  valueString: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
};

SearchGeneric.defaultProps = {
  onChange: () => null,
  onClick: () => null,
  valueString: "",
  placeholder: "",
};

export default SearchGeneric;
