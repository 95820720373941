export const APP = "/";
export const LOGOUT = "/logout";
export const LOGIN = "/login";
export const MURAL = "/mural";
export const FAVORITE = "/favoritos";
export const DETAILS_PUBLICATION = "/detalhes-publicacao";
export const FILES = "/arquivos";
export const STORE = "/loja";
export const DETAILS_PRODUCT = "/produto";
export const MY_NOTIFICATIONS = "/minhas-notificacoes";
export const SUB_MENU_PARDINI = "/grupo-pardini";
export const OUR_COMPANIES_PARDINI = "/sub-menu";
export const OUR_COMPANIES_PUBLICATION = "/detalhes";
export const SUB_ABOUT_US = "/sobre-nos";

export const PRIVATE_ROUTERS = [
  MURAL,
  FAVORITE,
  DETAILS_PUBLICATION,
  FILES,
  STORE,
  DETAILS_PRODUCT,
  MY_NOTIFICATIONS,
  SUB_MENU_PARDINI,
  OUR_COMPANIES_PARDINI,
  OUR_COMPANIES_PUBLICATION,
  SUB_ABOUT_US,
];
