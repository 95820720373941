import styled from "styled-components";
import css from "@styled-system/css";
import { variant } from "styled-system";
import theme from "styles/theme";

import Flex from "components/core/Grid/Flex";

export const CourseContainer = styled(Flex)(
  css({
    p: 3,
    width: "100%",
    overflow: "auto",
    flexDirection: "column",
    height: "calc(100vh - 72px)",
  }),
);

export const ResponsiveCourseContainer = styled(Flex)(
  css({
    backgroundColor: "paleGrey",
    overflow: "auto",
    flexDirection: "row",
    height: "85px",
    alignItems: "center",
    paddingLeft: "16px",
    borderRadius: "5px",
  }),
);

export const ModuleTitle = styled("h2")(
  css({
    fontFamily: "text",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "-0.5px",
    color: "blue",
    padding: "16px",
  }),
);

export const CourseInfo = styled(Flex)(
  css({
    justifyContent: "space-between",
    alignItems: "center",
  }),
  ({ completed }) =>
    css({
      h1: {
        color: completed ? "greenDark" : "blue",
        "&::before": {
          bg: completed ? "greenDark" : "blue",
        },
      },
      h3: {
        color: completed ? "greenDark" : "blue",
      },
      svg: {
        stroke: completed ? "greenDark" : "blue",
      },
    }),
);

export const CourseModulesList = styled("ul")(
  css({
    mt: 24,
    padding: 0,
    listStyle: "none outside",
    hr: {
      width: 0,
      height: 16,
      m: "0 0 -3px 23px",
      transition: "all ease-out .3s",
      border: "1px solid rgba(125, 134, 161, 0.6)",
    },
  }),
);

export const CourseModuleFlex = styled(Flex)(
  css({
    display: "flex",
    cursor: "pointer",
  }),
  variant({
    variants: {
      completed: {
        color: "greenDark",
      },
      inProgress: {
        color: "blue",
      },
    },
  }),
);

export const CourseModule = styled("li")(
  css({
    height: 48,
    fontSize: 2,
    color: "gray",
    display: "flex",
    fontFamily: "text",
    lineHeight: "53px",
    overflow: "hidden",
    position: "relative",
    flexDirection: "column",
    transition: "all ease-out .3s",

    "> div > span": {
      fontSize: 0,
      ml: "-18px",
      width: "16px",
      fontWeight: "600",
    },
    "> div > svg": {
      ml: "auto",
      top: "15px",
      right: "15px",
      position: "absolute",
      transform: "rotate(-90deg)",
      path: {
        stroke: theme.colors.gray,
      },
    },
  }),
  variant({
    variants: {
      completed: {
        "&::before": {
          bg: "greenDark",
        },
      },
      inProgress: {
        "&::before": {
          bg: "#1B59B3",
        },
      },
    },
  }),
);

export const CourseModuleTitle = styled("h2")(
  css({
    fontSize: 2,
    maxWidth: 184,
    margin: "auto 5px",
    fontFamily: "text",
    lineHeight: "24px",
    overflow: "hidden",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
  }),
);

export const CourseTasksList = styled("ul")(
  css({
    padding: 0,
    paddingLeft: "60px",
    listStyle: "none outside",
    hr: {
      width: 0,
      height: 42,
      position: "absolute",
      m: "26px 0 0px -38px",
      transition: "all ease-out .3s",
      border: "1px solid rgba(125, 134, 161, 0.6)",
    },
  }),
);

export const CourseTask = styled("li")(
  css({
    display: "flex",
    lineHeight: "32px",
    paddingLeft: "24px",
    position: "relative",
    fontFamily: "textLight",

    "&::before": {
      bg: "gray",
      top: "13px",
      width: "6px",
      content: "''",
      left: "-16px",
      height: "6px",
      borderRadius: "50%",
      position: "absolute",
    },
    "div > span": {
      top: "1px",
      left: "0px",
      fontSize: 0,
      width: "20px",
      position: "absolute",
    },
  }),
  variant({
    variants: {
      completedS: {
        color: "greenDark",
        "&::before": {
          bg: "greenDark",
        },
      },
      inSProgress: {
        color: "#1B59B3",
        "&::before": {
          bg: "#1B59B3",
        },
      },
    },
  }),
);

export const CourseTaskLink = styled("div")(({ completed }) =>
  css({
    height: 64,
    maxWidth: 168,
    overflow: "hidden",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    cursor: completed ? "pointer" : "default",
    ":hover": {
      color: completed && "green",
    },
  }),
);

export const CourseTitle = styled("h1")(
  css({
    fontSize: 3,
    maxWidth: 240,
    fontWeight: 500,
    color: "blue",
    overflow: "hidden",
    fontFamily: "heading",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
  }),
);
