import React, { useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import Portal from "templates/Portal";
import { useDispatch, useSelector } from "react-redux";

import { Creators as ActionGroupPardini } from "store/ducks/groupPardini";

import { PageTitle } from "components/core/Typography/Typography";
import SkeletonApps from "components/presentation/Apps/SkeletonApps";
import MenuComponent from "components/presentation/GroupPardini/MenuComponent";

const AboutUs = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { isLoading, listMenuGroupPardini } = useSelector(
    (state) => state.groupPardini,
  );

  const dispatchGetPardiniMenu = useCallback(() => {
    dispatch(ActionGroupPardini.getPardiniMenu(2));
  }, [dispatch]);

  useEffect(() => {
    dispatchGetPardiniMenu();
  }, [dispatchGetPardiniMenu]);

  return (
    <Portal>
      <PageTitle title={intl.messages["menu.aboutUs"]} />
      {isLoading ? (
        <SkeletonApps />
      ) : (
        <MenuComponent
          listMenu={listMenuGroupPardini.root_pages}
          type="aboutUs"
        />
      )}
    </Portal>
  );
};

export default AboutUs;
