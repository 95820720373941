/* eslint-disable react/no-array-index-key */
import React from "react";
import Skeleton from "react-loading-skeleton";

import Flex from "components/core/Grid/Flex";

import * as S from "./BirthdaysStyle";

const BirthdaysSkeleton = () => {
  const skeletonRepeat = 8;

  return (
    <Flex
      p={4}
      width="fit-content"
      height="100%"
      flexDirection="column"
      spaceChildren={3}
      borderRight="1px solid #ccd2e0"
    >
      {[...Array(skeletonRepeat)].map((_val, index) => (
        <Skeleton key={index} duration={0.8} width={342} height={68} />
      ))}
    </Flex>
  );
};

export default BirthdaysSkeleton;

export const SkeletonUser = () => {
  return (
    <S.SkeletonUserContainer>
      <Skeleton duration={0.8} width={120} height={120} circle={true} />
      <Skeleton duration={0.8} width={120} height={120} circle={true} />
      <Skeleton duration={0.8} width={120} height={120} circle={true} />
      <Skeleton duration={0.8} width={120} height={120} circle={true} />
    </S.SkeletonUserContainer>
  );
};

export const SkeletonUserNameAndSector = () => {
  return (
    <S.SkeletonUserNameAndSectorContainer>
      <Skeleton duration={0.8} width={40} height={40} circle={true} />
      <S.SkeletonUserNameAndSectorDescription>
        <Skeleton duration={0.8} width={200} height={20} />
        <Skeleton duration={0.8} width={140} height={15} />
      </S.SkeletonUserNameAndSectorDescription>
    </S.SkeletonUserNameAndSectorContainer>
  );
};

