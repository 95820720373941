/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Portal from "templates/Portal";
import { style } from "style";
import { useHistory } from "react-router-dom";
import { Creators as UserActions } from "store/ducks/user";

import { PageTitle } from "components/core/Typography/Typography";
import { GoBackButton } from "containers/Mural/Publication/PublicationStyle";
import { ReactComponent as GoBackIcon } from "assets/img/icons/goBackIcon.svg";
import DownloadApple from "assets/img/downloadApple.png";
import DownloadAndroid from "assets/img/downloadAndroid.png";

import * as S from "./TutorialAppsStyle";

const TutorialApps = () => {
  const history = useHistory();
  const goBack = () => history.goBack();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);

  const isAppPublic = [
    "Pintou Parceria Suvinil",
    "Vedacit",
    "mestreconstrutor",
  ].includes(style.names.nameApp);

  const isLwart = style.names.nameApp === "Lwart";

  const dispatchGetTutorialToken = useCallback(
    () => dispatch(UserActions.getTutorialToken()),
    [dispatch],
  );

  useEffect(() => {
    if (style.variables.customClient === true && !isAppPublic)
      dispatchGetTutorialToken();
  }, [dispatchGetTutorialToken, isAppPublic]);

  return (
    <Portal>
      <PageTitle title="Como Baixar o App" />
      <S.GeneralContainer>
        <GoBackButton onClick={goBack}>
          <GoBackIcon />
        </GoBackButton>
        <S.ContentDetails>
          <S.TextContent>
            <S.Text title>iPhone:</S.Text>
            {style.variables.customClient === true && !isAppPublic ? (
              isLwart ? (
                <S.Text>
                  Para ter acesso ao Diz Aí! Lwart, entre na Apple Store, clique
                  em sua foto no canto superior direito, vá até "Resgatar Cartão
                  Presente ou Código", insira o código {token && `${token}`} e
                  clique em "Resgatar". Depois, só fazer o download e entrar
                  usando seu login e senha Senior.
                </S.Text>
              ) : (
                <S.Text>
                  Para baixar o {style.names.nameApp} em seu iPhone, abra a App
                  Store, clique em sua foto no canto superior direito, vá até
                  "Resgatar Cartão Presente ou Código", insira o código{" "}
                  {token && `${token}`} e clique em "Resgatar".
                </S.Text>
              )
            ) : (
              <S.Text>
                Para baixar o {style.names.nameApp} em seu Iphone, entre na
                Apple Store e faça o download do app.
              </S.Text>
            )}
            <S.ImgContainer src={DownloadApple} />
            <S.Text title>Android:</S.Text>
            {isLwart ? (
              <S.Text>
                Para ter acesso ao Diz Aí! Lwart, entre na Google Play e busque
                o aplicativo. Depois, só fazer o download e entrar usando seu
                login e senha Senior.
              </S.Text>
            ) : (
              <S.Text>
                Para baixar o {style.names.nameApp} em seu Android, entre na
                Google Play e faça o download do app.
              </S.Text>
            )}
            <S.ImgContainer src={DownloadAndroid} />
          </S.TextContent>
        </S.ContentDetails>
      </S.GeneralContainer>
    </Portal>
  );
};

export default TutorialApps;
