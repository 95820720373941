/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React from "react";
import Skeleton from "react-loading-skeleton";
import { v1 as uuidv1 } from "uuid";

import theme from "styles/theme";
import Flex from "components/core/Grid/Flex";

import { OverflowComments, CommentForm } from "./SidebarPublicationStyle";

const SkeletonPublication = () => {
  const skeletonRepeat = 7;
  return (
    <Flex flexDirection="column">
      <Flex
        px={16}
        py={12}
        justifyContent="space-between"
        borderBottom={`1px solid ${theme.colors.grayDefaultColor}`}
      >
        <Skeleton duration={0.8} width={158} height={44} />
        <Skeleton duration={0.8} width={108} height={44} />
      </Flex>

      <Flex flexDirection="column">
        <Flex px={38} py={30} justifyContent="space-between">
          <Skeleton duration={0.8} width={40} height={20} />
          <Skeleton duration={0.8} width={178} height={20} />
        </Flex>

        <OverflowComments>
          <Flex px={38} flexDirection="column" spaceChildren={4}>
            {[...Array(skeletonRepeat)].map((_val) => (
              <Flex spaceChildren={2} key={uuidv1()}>
                <Skeleton circle duration={0.8} width={35} height={35} />
                <Flex flexDirection="column" mt={2} spaceChildren={2}>
                  <Skeleton duration={0.8} width={98} height={20} />
                  <Skeleton duration={0.8} count={2} width={200} height={20} />
                </Flex>
              </Flex>
            ))}
          </Flex>
        </OverflowComments>

        <CommentForm>
          <Flex width={1} px={22} flexDirection="column" spaceChildren={2}>
            <Skeleton duration={0.8} height={60} />
            <Skeleton duration={0.8} height={52} />
          </Flex>
        </CommentForm>
      </Flex>
    </Flex>
  );
};

export default SkeletonPublication;
