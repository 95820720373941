import styled from "styled-components";
import css from "@styled-system/css";

export const SkeletonEventListContainer = styled("div")(
  css({
    display: "flex",
    alignItems: "center",
    paddingLeft: "1rem"
  }),
);

export const SkeletonEventListDescription = styled("div")(
  css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    marginLeft: "10px",
  }),
);
