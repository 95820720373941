import { takeLatest, put } from "redux-saga/effects";
import { GET_SCORE_HISTORY } from "utils/constants/endpoints";
import { Types } from "../ducks/scoresHistory";
import { API_V2 } from "../../utils/API/API";

function* getScoresUser() {
  try {
    const scoreUser = yield API_V2({
      method: "GET",
      url: GET_SCORE_HISTORY,
    });
    yield put({
      scoreUser: scoreUser.data,
      type: Types.GET_SCORES_HISTORY_USER_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_SCORES_HISTORY_USER_FAIL,
      ...error.response.data,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_SCORES_HISTORY_USER, getScoresUser);
}
