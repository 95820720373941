import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useIntl } from "react-intl";
import { Creators as CalendarEventsActions } from "store/ducks/calendar";
import Portal from "templates/Portal";
import Sidebar from "components/presentation/Sidebar";
import Flex from "components/core/Grid/Flex";
import CalendarComponent from "components/presentation/Calendar";
import { startAndEndDateOfTheMonth } from "helpers/sharedHelpers";
import { useAnalytics } from "utils/hooks";
import { PageTitle } from "components/core/Typography/Typography";
import CalendarSkeleton from "./CalendarSkeleton";

useAnalytics("screen_events");

const Calendar = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const [dateClick, setDateClick] = useState();
  const [dateHandleCalender, setDateHandleCalender] = useState({});
  const { events, isLoading } = useSelector((state) => state.calendar);
  const dispatchCalendarEvents = useCallback(
    () => dispatch(CalendarEventsActions.calendarEvents(dateHandleCalender)),
    [dispatch, dateHandleCalender],
  );
  const { pageTitle } = location.state || "" ;

  useEffect(() => {
    const data = new Date();
    const dataStartAndEndDateOfTheMonth = startAndEndDateOfTheMonth(data);
    setDateHandleCalender(dataStartAndEndDateOfTheMonth);
  }, []);
  useEffect(() => {
    dispatchCalendarEvents();
  }, [dispatchCalendarEvents]);

  // eslint-disable-next-line no-shadow
  const handleEventsDay = (dateClick) => {
    setDateClick(dateClick);
  };

  return (
    <Portal
      sidebarContext={<Sidebar eventList birthdaysList dateClick={dateClick || true} />}
      textBtnInSideBar="Ver Eventos"
    >
      <Flex flexDirection="column" paddingBottom>
        <PageTitle noBorder title={pageTitle || intl.messages["menu.calendar"]} />
        {isLoading ? (
          <CalendarSkeleton duration={0.8} height="100vh" />
        ) : (
          events && (
            <Flex flexDirection="column">
              <CalendarComponent
                setDateHandleCalender={setDateHandleCalender}
                handleEventsDay={handleEventsDay}
                dateClick={dateClick}
                events={events}
              />
            </Flex>
          )
        )}
      </Flex>
    </Portal>
  );
};

export default Calendar;
