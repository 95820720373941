import React from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import css from "@styled-system/css";

import * as S from "./MuralStyle";

export const Card = styled("div")(
  css({
    bg: "paleGrey",
    width: "590px",
    borderRadius: "18px",
    height: "156px",
  }),
  ({ radius }) => css({ borderRadius: radius || "18px" })
);

const SkeletonMural = () => (
  <S.Post>
    <Skeleton duration={0.8} height="450px" />
  </S.Post>
);

export default SkeletonMural;
