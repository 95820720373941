import styled from "styled-components";
import css from "@styled-system/css";
import Flex from "components/core/Grid/Flex";

export const MsgDoesNotExist = styled("div")(
  css({
    marginLeft: "25px",
    width: "155px",
  }),
);

export const Overflow = styled(Flex)(
  css({
    padding: 16,
    overflow: "auto",
  }),
);

export const Text = styled("h2")(
  css({
    fontFamily: "text",
    fontSize: 18,
    color: "backText",
    marginLeft: 12,
  }),
);

export const ContainerSidebar = styled("div")(
  css({
    display:"flex",
    flexDirection:"column",
    alignItems: "center",
    justifyContent: "space-around"
  }),
)
