import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getApps: ["payload"],
  getAppsSuccess: ["payload", "isLoading"],
  getAppsFail: ["isLoading", "error"],
});

const INITIAL_STATE = {
  apps: [],
  isLoading: true,
};

const getApps = (state) => ({
  ...state,
  isLoading: true,
});
const getAppsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getAppsFail = (state) => ({
  ...state,
  isLoading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_APPS]: getApps,
  [Types.GET_APPS_SUCCESS]: getAppsSuccess,
  [Types.GET_APPS_FAIL]: getAppsFail,
});
