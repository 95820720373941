import { PageTitle } from "components/core/Typography/Typography";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import Select, { components } from "react-select";
import Portal from "templates/Portal";
import iconArrowDown, {
  ReactComponent as IconArrow,
} from "../../assets/img/icons/iconArrowDown.svg";
import * as S from "./SacSuvinilStyles";

const SacSuvinil = () => {
  const sacData = [
    {
      question: "Como edito minhas informações de perfil?",
      answer:
        "Para editar seu perfil, clique em sua foto no canto esquerdo superior, e acesse a opção “Editar”.",
      category: "Register",
    },
    {
      question: "Como posso acessar os treinamentos do Pintou Parceria?",
      answer:
        "Para acessar os treinamentos, basta acessar o menu principal, e acessar a aba “Treinamentos”. Nesta aba estão todos os treinamentos disponíveis no Pintou Parceria.",
      category: "Courses",
    },
    {
      question: "Como retiro certificados?",
      answer:
        "Para retirar seus certificados, você precisa apenas completar o treinamento e conseguir a aprovação na avaliação. Com isso, você pode baixar seu certificado no seu dispositivo. Caso queira reemitir certificados já obtidos, basta acessar o treinamento em “Meus cursos” e clicar em “Certificado”.",
      category: "Courses",
    },
    {
      question: "Como posso ganhar prêmios no Pintou Parceria?",
      answer:
        "Para ganhar prêmios, você pode reunir pontos e acessar a opção de menu “Prêmios”, e selecionar o prêmio desejado de acordo com sua pontuação obtida. Você pode reunir pontos para serem trocados por prêmios ao interagir com publicações e fazer treinamentos na plataforma.",
      category: "Store",
    },
    {
      question: "Fiz um resgate de prêmio e ainda não recebi. E agora?",
      answer:
        "Fique atento ao prazo de entrega disposto no prêmio resgatado, pois eles podem variar. Em casos de dúvidas, ajustes de endereço ou atrasos na entrega, entre em contato com nosso atendimento pelo WhatsApp (11) 99764-1892.",
      category: "Store",
    },
    {
      question: "Como salvar uma publicação?",
      answer:
        "Você pode salvar suas publicações favoritas clicando no desenho de bandeira no canto direito inferior de publicações. Após isso, é só acessar a opção de menu “Favoritos”, que reúne suas publicações salvas.",
      category: "appUsability",
    },
    {
      question: "Estou com problemas na visualização de vídeos, o que fazer?",
      answer:
        "Verifique se a sua versão do aplicativo está atualizada. Se o aplicativo estiver atualizado e ainda assim o problema persistir, entre em contato com nosso SAC pelo número 0800 011 7558.",
      category: "appUsability",
    },
    {
      question: "O que é o “Encontre Seu Pintor” da Suvinil?",
      answer:
        "Encontre Seu Pintor é uma plataforma que aproxima profissionais certificados pela Suvinil e consumidores que precisam do serviço de pintura. Na plataforma, é possível solicitar orçamentos de profissionais da região em que o serviço é pretendido. Para isso, o consumidor precisa apenas acessar o Encontre Seu Pintor, preencher o CEP para a busca de profissionais, selecionar o profissional e solicitar um orçamento.",
      category: "ESP",
    },
    {
      question: "Como se inscrever no Encontre Seu Pintor?",
      answer:
        "Para se cadastrar no Encontre Seu Pintor, é necessário acessar https://esp.suvinil.com.br/cadastro, e fazer o cadastro. Além de informações de contato, endereço e raio de atendimento, você precisa cadastrar sua foto de perfil, fotos de trabalhos executados e os 3 certificados obrigatórios do aplicativo Pintou Parceria: Sistemas de Pintura, Patologias e Postura Profissional. Após finalizar o cadastro, ele é enviado para análise e receberá aprovação ou reprovação. Uma vez aprovado, você poderá começar a receber solicitações de orçamentos de clientes em sua região.",
      category: "ESP",
    },
    {
      question:
        "Existe algum tipo de cobrança dos profissionais ou consumidores para utilizar a plataforma Encontre Seu Pintor?",
      answer:
        "Não, a utilização da plataforma é gratuita, deste modo, os profissionais cadastrados ou consumidores que solicitam orçamentos não recebem nenhum tipo de cobrança pela sua utilização.",
      category: "ESP",
    },
  ];

  const categories = [
    {
      value: "All",
      label: "Todas",
    },
    {
      value: "Register",
      label: "Dúvidas sobre Cadastro",
    },
    {
      value: "Courses",
      label: "Dúvidas sobre Cursos",
    },
    {
      value: "Store",
      label: "Dúvidas sobre Loja",
    },
    {
      value: "appUsability",
      label: "Dúvidas sobre o Uso do App",
    },
    {
      value: "ESP",
      label: "Dúvidas sobre o Encontre seu Pintor",
    },
  ];

  const intl = useIntl();
  const [accordionActive, setAccordionActive] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const urlRegex = /\bhttps:\/\/([a-z0-9\-]+\.)+([a-z]{2,6})(\/[^\s,]+)?\b/g;

  const filteredObjects =
    selectedCategory === "All"
      ? sacData
      : sacData.filter((data) => data.category === selectedCategory);

  const toggleAccordion = (index) => {
    if (accordionActive === index) {
      setAccordionActive(null);
    } else {
      setAccordionActive(index);
    }
  };

  const handleRedirectToWhatsapp = () => {
    window.open("https://wa.me/5511997641892", "_blank");
  };

  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <IconArrow />
    </components.DropdownIndicator>
  );

  const joinAnswersTextAndLink = (answer) => {
    if (!urlRegex.test(answer)) return answer;

    const [start, end] = answer.replace(urlRegex, "**LINK**").split("**LINK**");
    const fullLink = answer.match(urlRegex);

    const linkComponent = (
      <a href={fullLink} target="_blank" rel="noreferrer">
        {fullLink}
      </a>
    );

    return [start, linkComponent, end];
  };

  return (
    <Portal fullHeight>
      <PageTitle title={intl.messages["menu.sac"]} sacSuvinil />
      <S.PageContainer>
        <S.Subtitle>{intl.messages["sacSuvinil.subtitle"]}</S.Subtitle>
        <main>
          <S.Introduction>
            {intl.messages["sacSuvinil.greetings"]} <br />{" "}
            {intl.messages["sacSuvinil.introduction"]}
          </S.Introduction>

          <S.SelectContainer>
            <span>{intl.messages["sacSuvinil.chooseCategory"]}</span>
            <Select
              options={categories}
              onChange={(e) => setSelectedCategory(e.value)}
              value={categories.find(
                (category) => category.value === selectedCategory,
              )}
              classNames={{
                container: () => "container",
                control: () => "control",
                singleValue: () => "singleValue",
                ValueContainer: () => "ValueContainer",
                indicatorSeparator: () => "indicatorSeparator",
                menu: () => "menu",
                option: () => "option",
                dropdownIndicator: () => "dropdownIndicator",
              }}
              components={{
                DropdownIndicator,
              }}
              noOptionsMessage={() => "Nenhuma categoria encontrada"}
            />
          </S.SelectContainer>

          {filteredObjects.map((data, index) => (
            <S.Accordion active={accordionActive === index}>
              {" "}
              <S.AccordionTitle
                onClick={() => toggleAccordion(index)}
                active={accordionActive === index}
              >
                <span>{data.question}</span>
                <img src={iconArrowDown} alt="Arrow" />
              </S.AccordionTitle>
              <S.AccordionContent active={accordionActive === index}>
                {joinAnswersTextAndLink(data.answer)}
              </S.AccordionContent>
            </S.Accordion>
          ))}
        </main>
        <S.ContactContainer>
          <S.Contact>
            <S.Title>{intl.messages["sacSuvinil.questions"]}</S.Title>
            <S.Subtitle small>{intl.messages["sacSuvinil.contact"]}</S.Subtitle>
          </S.Contact>
          <S.Button onClick={handleRedirectToWhatsapp}>
            {intl.messages["sacSuvinil.whatsapp"]}
          </S.Button>
        </S.ContactContainer>
      </S.PageContainer>
    </Portal>
  );
};

export default SacSuvinil;
