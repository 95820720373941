import styled from "styled-components";
import css from "@styled-system/css";
import Flex from "components/core/Grid/Flex";
import { Price } from "../StoreStyle";

export const PriceDetails = styled(Price)(
  css({
    margin: 0,
    position: "relative",
    height: 45,
    marginRight: "20px",
    minWidth: "90px",

    "@media (min-width: 360px) and (max-width: 519px)": {
      marginBottom: 1,
      marginTop: 0,
    },
  }),
);

export const ProductContainer = styled(Flex)(
  css({
    padding: 3,
    marginTop: 2,
    alignItems: "center",
    flexDirection: "column",
    overflowX: "hidden",
    "@media (max-width: 760px)": {
      padding: 1,
    },
  }),
);

export const TitleContainer = styled(Flex)(
  css({
    marginTop: "4px",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    display: "flex",
    width: "100%",
    "@media (min-width: 520px)": {
      flexDirection: "row",
      marginLeft: 20,
    },
  }),
);

export const ProductContent = styled(Flex)(
  css({
    flexDirection: "column",
    paddingLeft: 140,
    paddingRight: 140,
    "@media (min-width: 370px)": {
      paddingLeft: 90,
      paddingRight: 90,
    },

    "@media (min-width: 520px)": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  }),
  ({ width }) => css({ width: width || 524 }),
);

export const DescriptionContainer = styled(Flex)(
  css({
    marginLeft: 20,
    alignItems: "center",
    flexDirection: "row",
    display: "flex",
    "@media (max-width: 539px)": {
      justifyContent: "center",
      marginLeft: 0,
    },
  }),
);

export const ProductDescription = styled("p")(
  css({
    fontSize: 18,
    margin: "0 20px 0 0",
    color: "textColor",
    fontFamily: "text",
    textAlign: "justify",
    whiteSpace: "pre-line",
  }),
  ({ center }) => center && css({ textAlign: "center" }),
);

export const ProductKind = styled(ProductDescription)(
  css({
    margin: 0,
  }),
);

export const ProductImg = styled("img")(
  css({
    height: 272,
    width: "100%",
    maxWidth: 520,
    objectFit: "contain",
  }),
);

export const ProductName = styled("h4")(
  css({
    flex: 1,
    color: "backText",
    fontWeight: "600",
    lineHeight: "32px",
    fontFamily: "heading",
    marginLeft: 0,
    wordWrap: "break-word",
    maxWidth: "380px",
  }),
  ({ center }) => center && css({ textAlign: "center" }),
);

export const DescriptionDivider = styled("hr")(
  css({
    margin: "16px 20px",
  }),
);

export const ContainerConfetti = styled("div")(
  css({
    position: "absolute",
    top: "65px",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    pointerEvents: "none",
    visibility: "hidden",
  }),
  ({ showContainer }) =>
    showContainer &&
    css({
      visibility: "visible",
    }),
);
