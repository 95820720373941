import Axios from "axios";
import { put, takeLatest, select } from "redux-saga/effects";
import {
  baseURLV3,
  GET_POST_COMMENTS,
  GET_COMMENT,
  baseURLV2,
} from "utils/constants/endpoints";
import { style } from "../../style";
import { Types } from "../ducks/postComments";

const api = Axios.create({
  baseURL: baseURLV3,
  headers: {
    "Content-Type": "application/json",
    "access-token": `${localStorage.getItem("access-token")}`,
    uid: `${localStorage.getItem("uid")}`,
    client: `${localStorage.getItem("client")}`,
    "resource-type": `${localStorage.getItem("resource-type")}`,
    "host-header": `${style.api.hostHeader}`,
  },
});

const API_V2 = Axios.create({
  baseURL: baseURLV2,
  headers: {
    "Content-Type": "application/json",
    "access-token": `${localStorage.getItem("access-token")}`,
    uid: `${localStorage.getItem("uid")}`,
    client: `${localStorage.getItem("client")}`,
    "resource-type": `${localStorage.getItem("resource-type")}`,
    "host-header": `${style.api.hostHeader}`,
  },
});

const getCommentsActual = (state) => state.postComments.comments;

function* getPostComments({ payload }) {
  const { postId, page = 1, commentId = false, perPage = 10 } = payload;

  try {
    const initialList = [];

    // eslint-disable-next-line no-extra-boolean-cast
    if (!!commentId) {
      const { data: mentionedCommnet } = yield API_V2({
        url: GET_COMMENT(commentId),
        method: "GET",
      });

      initialList.push(mentionedCommnet);
    }

    const {
      data: { pagination, canvas_comments },
    } = yield api({
      url: GET_POST_COMMENTS(postId, page, perPage, commentId),
      method: "GET",
    });

    const actualList =
      // eslint-disable-next-line no-nested-ternary
      page === 1 ? initialList : yield select(getCommentsActual);
    const newList = actualList.concat(canvas_comments);
    const hasMore = canvas_comments.length === 10;

    const action = {
      type: Types.GET_POST_COMMENTS_SUCCESS,
      canvas_comments: newList,
      pagination,
      page,
      hasMore,
    };

    yield put({ ...action });
  } catch (error) {
    yield put({
      error,
      type: Types.GET_POST_COMMENTS_FAILURE,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_POST_COMMENTS, getPostComments);
}
