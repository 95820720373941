import styled from "styled-components";

export const ReactionsBar = styled.div`
  background-color: white;
  max-width: ${(props) =>
    props.type === "publication"
      ? "310px"
      : props.type === "comment"
      ? "302px"
      : props.type === "commentReply"
      ? "302px"
      : props.type === "publicationMobile"
      ? "380px"
      : props.type === "commentMobile"
      ? "357px"
      : "commentReplyMobile"
      ? "357px"
      : "302px"};
  height: 64px;
  border-radius: 100px;
  box-shadow: 0px 5px 16px 0px rgba(88, 143, 174, 0.25);
  transition: 0.3s;

  display: none;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  /* position: relative; */

  position: absolute;
  top: ${(props) =>
    props.type === "publication"
      ? "62px"
      : props.type === "publicationMobile"
      ? "none"
      : props.type === "commentMobile"
      ? "-80px"
      : props.type === "commentReplyMobile"
      ? "-80px"
      : "32px"};
  left: ${(props) =>
    props.type === "publication"
      ? "15px"
      : props.type === "publicationMobile"
      ? "10px"
      : props.type === "comment"
      ? "-15px"
      : props.type === "commentReply"
      ? "-35px"
      : props.type === "commentMobile"
      ? "-15px"
      : props.type === "commentReplyMobile"
      ? "-46px"
      : "0px"};

  bottom: ${(props) => props.type === "publicationMobile" && "218px"};

  z-index: 6;
  width: 100vw;

  &:hover {
    display: flex;
  }
`;

export const ReactionTitle = styled.span`
  display: none;
`;

export const ReactionsIcons = styled.img`
  width: ${(props) =>
    props.type === "publicationMobile"
      ? "44px"
      : props.type === "commentMobile"
      ? "40px"
      : props.type === "commentReplyMobile"
      ? "40px"
      : "32px"};
  transition: transform 0.3s ease-in-out;

  cursor: pointer;

  &:hover {
    width: 42px;

    & + ${ReactionTitle} {
      display: block;
      position: absolute;
      top: -50px;
      left: 50%;
      opacity: 10;
      transform: translateX(-50%);
      transition: opacity 0.3s ease-in-out;
      background-color: #f4f6fb;
      border: 1px solid #808080;
      padding: 8px;
      border-radius: 4px;
      box-shadow: 0px 2px 3px 0px rgba(36, 62, 123, 0.1),
        0px 6px 6px 0px rgba(36, 62, 123, 0.09),
        0px 14px 8px 0px rgba(36, 62, 123, 0.05),
        0px 24px 10px 0px rgba(36, 62, 123, 0.01),
        0px 38px 11px 0px rgba(36, 62, 123, 0);
      color: #808080;
      font-size: 10px;
      font-weight: 400;
    }
  }
`;
