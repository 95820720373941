/* eslint-disable no-plusplus */
/* eslint-disable radix */
import moment from "moment";
import classnames from "classnames";
import "moment/locale/pt-br";

import { formatDistanceToNow, addLocale } from "date-fns";
import { ptBR, enUS, es } from "date-fns/locale";

import urls from "utils/constants/urls";
import { createBrowserHistory } from "history";

export const smartFormatterDate = (value, format) => {
  if (value) return moment(value).utc().locale("pt-br").format(format);
  return " - ";
};

export const smartFormatterDateNotifications = (value, format) => {
  if (value) return moment(value).locale("pt-br").format(format);
  return " - ";
};

export const smartGet = (value, get) => {
  if (value) return moment(value).utc().get(get);
  return " - ";
};

export const startAndEndDateOfTheMonth = (value) => {
  const firstDayMonth = smartFormatterDate(
    moment(value).startOf("month"),
    "YYYY-MM-DD",
  );
  const endDayMonth = smartFormatterDate(
    moment(value).endOf("month"),
    "YYYY-MM-DD",
  );
  const array_date = { firstDayMonth, endDayMonth };
  return array_date;
};

const opts = { format: "%v", locale: "pt-BR", currency: "BRL" };
moment.locale(opts.locale);

export const handleCommentDateFns = (date) => {
  const convertedDate = new Date(date);
  return formatDistanceToNow(convertedDate, {
    locale: ptBR,
    addSuffix: true,
  }).replace(/cerca de |about /i, "");
};

export const history = createBrowserHistory();

export const convertToSlug = (text) => {
  const a = "àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;";
  const b = "aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------";
  const p = new RegExp(a.split("").join("|"), "g");
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(p, (c) => b.charAt(a.indexOf(c)))
    .replace(/&/g, "-and-")
    .replace(/[\s\W-]+/g, "-");
};

export const manageClasses = (...params) => classnames(...params).split(" ");

export const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const validateCPF = (value) => {
  let Soma;
  let i;
  let Resto;
  Soma = 0;
  const strCPF = value.replace(/\D/g, "");
  let n = 0;

  while (n < 10) {
    const str = n.toString();
    if (strCPF === str.repeat(11)) return false;

    n++;
  }

  for (i = 1; i <= 9; i++)
    Soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (i = 1; i <= 10; i++)
    Soma += parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
};

export const validateCNPJ = (value) => {
  const cnpj = value.replace(/[^\d]+/g, "");

  if (cnpj.length !== 14) return false;

  let totalLength = cnpj.length - 2;
  const cnpjWithoutDigits = cnpj.substring(0, totalLength);
  const verificationDigits = cnpj.substring(totalLength);
  let sum = 0;
  let pos = totalLength - 7;
  for (let i = totalLength; i >= 1; i--) {
    sum += cnpjWithoutDigits.charAt(totalLength - i) * pos--;
    if (pos < 2) pos = 9;
  }
  const result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(verificationDigits.charAt(0))) return false;

  totalLength += 1;
  const updatedCnpjWithoutDigits = cnpj.substring(0, totalLength);
  sum = 0;
  pos = totalLength - 7;
  for (let i = totalLength; i >= 1; i--) {
    sum += updatedCnpjWithoutDigits.charAt(totalLength - i) * pos--;
    if (pos < 2) pos = 9;
  }

  const newResult = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (newResult !== parseInt(verificationDigits.charAt(1))) return false;

  return true;
};

export const validateDate = (value) => {
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(value)) return false;

  // Parse the date parts to integers
  const parts = value.split("/");
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);

  const today = new Date();
  const todayYear = today.getFullYear();
  const todayMonth = today.getMonth() + 1;
  const todayDate = today.getDate() + 1;

  // Check the ranges of month and year
  if (
    year < 1900 ||
    year > todayYear ||
    month === 0 ||
    month > 12 ||
    (year === todayYear && month > todayMonth) ||
    (year === todayYear && month <= todayMonth && day >= todayDate)
  )
    return false;

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
    monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
};

export const linkNotification = ({
  kind,
  idelement,
  title,
  canvas_publication_id,
}) => {
  switch (kind) {
    case "canvas":
      return `${urls.ROUTES.DETAILS_PUBLICATION}/${convertToSlug(
        title,
      )}/${idelement}`;
    case "event":
      return `${urls.ROUTES.CALENDAR}`;
    case "product":
      return `${urls.ROUTES.DETAILS_PRODUCT}/${convertToSlug(
        title,
      )}/${idelement}`;
    case "course":
      return `${urls.ROUTES.ACADEMY}/${idelement}`;
    case "file":
      return `${urls.ROUTES.FILES}/${convertToSlug(title)}/${idelement}`;
    case "user_markup":
      return `${urls.ROUTES.DETAILS_PUBLICATION}/${convertToSlug(
        title,
      )}:${idelement}/${canvas_publication_id}`;
    default:
      return `${urls.ROUTES.MY_NOTIFICATIONS}`;
  }
};
