export const isAuthenticated = () => {
  const accessToken = localStorage.getItem("access-token");
  if (accessToken) return true;
  return false;
};

export const authenticatedUser = () => {
  const userId = localStorage.getItem("id");
  if (isAuthenticated()) return userId;
  return false;
};

export const logout = () => {
  localStorage.clear();
};
