import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";

import { Creators as ScoreHistoryActions } from "store/ducks/scoresHistory";

import Portal from "templates/Portal";
import Flex from "components/core/Grid/Flex";
import Avatar from "components/core/Avatar/Avatar";
import { PageTitle } from "components/core/Typography/Typography";

import { ReactComponent as IconCoins } from "assets/img/icons/iconCoins.svg";
import { ReactComponent as StoreIcon } from "assets/img/icons/storeIcons.svg";
import { ReactComponent as ArrowLeftIcon } from "assets/img/icons/arrow-left.svg";
import { smartGet, smartFormatterDate } from "helpers/sharedHelpers";
import { style } from "style";

import { useAnalytics } from "utils/hooks";
import {
  Header,
  History,
  HistoryItem,
  Title,
  RegisteredAt,
  Score,
  Justification,
  ContainerHistory,
} from "./ScoreHistoryStyle";
import ScoreHistorySkeleton from "./ScoreHistorySkeleton";

useAnalytics("screen_historic");

const ScoreHistory = () => {
  const intl = useIntl();
  const location = useLocation();
  const { pageTitle } = location.state || "";
  const dispatch = useDispatch();
  const { scoreUser, isLoading } = useSelector((state) => state.scoreHistory);
  const { user } = useSelector((state) => state.user);
  const dispatchGetScoresHistoryUser = useCallback(
    () => dispatch(ScoreHistoryActions.getScoresHistoryUser()),
    [dispatch],
  );
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1024);
  const changeDataPosition = () => {
    setDesktop(window.innerWidth > 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", changeDataPosition);
    return () => window.removeEventListener("resize", changeDataPosition);
  });

  useEffect(() => {
    dispatchGetScoresHistoryUser();
  }, [dispatchGetScoresHistoryUser]);

  const renderTime = (time) => {
    const day = smartGet(time, "date");
    const month = smartFormatterDate(time, "MMMM");
    return `${day} de ${month}`;
  };

  const renderHistoryItem = (item, i) => {
    switch (item.kind) {
      case "gift":
        return (
          <HistoryItem
            onClick={() =>
              document
                .querySelector(`#${item.kind}-${i}`)
                .classList.toggle("uncollapsed")
            }
            key={`${item.kind}-${i}`}
            id={`${item.kind}-${i}`}
            collapse
          >
            <IconCoins />
            <Flex flexDirection="column" flex="1 0 0">
              <Flex>
                <Title>
                  {style.names.nameApp === "Pintou Parceria Suvinil"
                    ? "Pontos por Merecimento"
                    : intl.messages["scoreHistory.meritMoney"]}
                </Title>
                {isDesktop ? (
                  <Score>
                    + {item.score} {style.names.nameScore}
                  </Score>
                ) : (
                  <RegisteredAt>{renderTime(item.registered_at)}</RegisteredAt>
                )}
                <ArrowLeftIcon className="collapse-trigger" />
              </Flex>
              {!isDesktop ? (
                <Score>
                  + {item.score} {style.names.nameScore}
                </Score>
              ) : (
                <RegisteredAt>{renderTime(item.registered_at)}</RegisteredAt>
              )}
              <Justification className="collapse-element">
                {item.justification}
              </Justification>
            </Flex>
          </HistoryItem>
        );
      case "removal":
        return (
          <HistoryItem
            onClick={() =>
              document
                .querySelector(`#${item.kind}-${i}`)
                .classList.toggle("uncollapsed")
            }
            key={`${item.kind}-${i}`}
            id={`${item.kind}-${i}`}
            collapse
          >
            <IconCoins />
            <Flex flexDirection="column" flex="1 0 0">
              <Flex>
                <Title>{intl.messages["scoreHistory.scoreRemoval"]}</Title>
                {isDesktop ? (
                  <Score variant={["negative"]}>
                    - {Math.abs(item.score)} {style.names.nameScore}
                  </Score>
                ) : (
                  <RegisteredAt>{renderTime(item.registered_at)}</RegisteredAt>
                )}
                <ArrowLeftIcon className="collapse-trigger" />
              </Flex>
              {!isDesktop ? (
                <Score variant={["negative"]}>
                  - {Math.abs(item.score)} {style.names.nameScore}
                </Score>
              ) : (
                <RegisteredAt>{renderTime(item.registered_at)}</RegisteredAt>
              )}
              <Justification className="collapse-element">
                {item.justification}
              </Justification>
            </Flex>
          </HistoryItem>
        );
      case "week_interaction_balance":
        return (
          <HistoryItem>
            <IconCoins />
            <Flex flexDirection="column">
              <Flex>
                <Title>{intl.messages["scoreHistory.weekInteration"]}</Title>
                {isDesktop ? (
                  <Score>
                    + {item.score_balance} {style.names.nameScore}
                  </Score>
                ) : (
                  <RegisteredAt>{renderTime(item.registered_at)}</RegisteredAt>
                )}
              </Flex>
              {!isDesktop ? (
                <Score>
                  + {item.score_balance} {style.names.nameScore}
                </Score>
              ) : (
                <RegisteredAt>{renderTime(item.registered_at)}</RegisteredAt>
              )}
            </Flex>
          </HistoryItem>
        );
      case "purchase":
        return (
          <HistoryItem
            onClick={() =>
              document
                .querySelector(`#${item.kind}-${i}`)
                .classList.toggle("uncollapsed")
            }
            key={`${item.kind}-${i}`}
            id={`${item.kind}-${i}`}
            collapse
          >
            <StoreIcon className="purchase" />
            <Flex flexDirection="column" flex="1 0 0">
              <Flex>
                <Title>{item.product_name}</Title>
                {isDesktop ? (
                  <Score variant={["negative"]}>
                    - {item.product_value} {style.names.nameScore}
                  </Score>
                ) : (
                  <RegisteredAt>{renderTime(item.registered_at)}</RegisteredAt>
                )}
                <ArrowLeftIcon className="collapse-trigger" />
              </Flex>
              {!isDesktop ? (
                <Score variant={["negative"]}>
                  - {item.product_value} {style.names.nameScore}
                </Score>
              ) : (
                <RegisteredAt>{renderTime(item.registered_at)}</RegisteredAt>
              )}
              <Justification className="collapse-element">
                {item.product_description}
              </Justification>
            </Flex>
          </HistoryItem>
        );
      case "course":
        return (
          <HistoryItem
            onClick={() =>
              document
                .querySelector(`#${item.kind}-${i}`)
                .classList.toggle("uncollapsed")
            }
            key={`${item.kind}-${i}`}
            id={`${item.kind}-${i}`}
            collapse
          >
            <IconCoins />
            <Flex flexDirection="column" flex="1 0 0">
              <Flex>
                <Title>{intl.messages["scoreHistory.courseConclusion"]}</Title>
                {isDesktop ? (
                  <Score>
                    + {item.score} {style.names.nameScore}
                  </Score>
                ) : (
                  <RegisteredAt>{renderTime(item.registered_at)}</RegisteredAt>
                )}
                <ArrowLeftIcon className="collapse-trigger" />
              </Flex>
              {!isDesktop ? (
                <Score>
                  + {item.score} {style.names.nameScore}
                </Score>
              ) : (
                <RegisteredAt>{renderTime(item.registered_at)}</RegisteredAt>
              )}
              <Justification className="collapse-element">
                {item.title}
              </Justification>
            </Flex>
          </HistoryItem>
        );
      case "speaker":
        return (
          <HistoryItem>
            <IconCoins />
            <Flex flexDirection="column">
              <Flex>
                <Title>
                  {intl.messages["scoreHistory.scorePerPublication"]}
                </Title>
                {isDesktop ? (
                  <Score>
                    + {item.score} {style.names.nameScore}
                  </Score>
                ) : (
                  <RegisteredAt>{renderTime(item.registered_at)}</RegisteredAt>
                )}
              </Flex>
              {!isDesktop ? (
                <Score>
                  + {item.score} {style.names.nameScore}
                </Score>
              ) : (
                <RegisteredAt>{renderTime(item.registered_at)}</RegisteredAt>
              )}
            </Flex>
          </HistoryItem>
        );
      default:
        return null;
    }
  };
  return (
    <Portal>
      <Flex flexDirection="column">
        <PageTitle title={pageTitle || intl.messages["menu.score_history"]} />
        {isLoading ? (
          <ScoreHistorySkeleton />
        ) : (
          <>
            <Header>
              <Avatar
                url={user?.image?.url}
                border="2px solid #ccd2e0"
                size="46px"
              />
              <Flex
                ml={3}
                flexDirection="column"
                justifyContent="space-between"
              >
                <h2>
                  {user?.name?.split(" ")[0]},{" "}
                  {intl.messages["scoreHistory.youHave"]}
                  <IconCoins />
                  <span>
                    {scoreUser[0]?.score} {style.names.nameScore}.
                  </span>
                </h2>
                <p>
                  {intl.messages["scoreHistory.youAccumulated"]}{" "}
                  <span>
                    {scoreUser[1]?.score} {style.names.nameScore}
                  </span>{" "}
                  {intl.messages["scoreHistory.onTheLastThirtyDays"]}
                </p>
              </Flex>
            </Header>
            {scoreUser && (
              <ContainerHistory>
                <History>
                  {scoreUser?.map((item, i) => renderHistoryItem(item, i))}
                </History>
              </ContainerHistory>
            )}
          </>
        )}
      </Flex>
    </Portal>
  );
};

export default ScoreHistory;
