import React, { useState, useRef, useLayoutEffect } from "react";
import Skeleton from "react-loading-skeleton";

import Flex from "components/core/Grid/Flex";

import { CoursesList, ShadowItem } from "./AcademyStyle";

const AcademySkeleton = () => {
  const listRef = useRef(null);
  const [items, setItems] = useState([]);

  useLayoutEffect(() => {
    const { width } = listRef.current.getBoundingClientRect();
    const perLine = Math.floor(width / 260);
    const itemsToRender = perLine - (18 % perLine);

    for (var i = 0; i < 5; i++) {
      setItems((oldState) => [...oldState, <Skeleton duration={0.8} width={228} height={237} />]);
    }
    for (var j = 0; j < itemsToRender; j++) {
      setItems((oldState) => [...oldState, <ShadowItem />]);
    }
  }, []);

  return (
    <Flex flexDirection="column" p={3}>
      <Flex mb={18}>
        <Skeleton duration={0.8} width={445} height={24} />
      </Flex>
      <CoursesList ref={listRef}>{items}</CoursesList>
    </Flex>
  );
};

export default AcademySkeleton;
