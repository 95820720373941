/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { useEffect, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { Creators as GalleryActions } from "store/ducks/gallery";

import urls from "utils/constants/urls";
import { history } from "helpers/sharedHelpers";
import { PageTitle } from "components/core/Typography/Typography";

import Portal from "templates/Portal";
import NotFoundGeneric from "components/presentation/NotFoundGeneric";

import { GoBackButton } from "containers/Mural/Publication/PublicationStyle";

import { ReactComponent as IconClose } from "assets/img/icons/iconClose.svg";
import { ReactComponent as GoBackIcon } from "assets/img/icons/goBackIcon.svg";
import { ReactComponent as ArrowLeftIcon } from "assets/img/icons/arrow-left.svg";
import { ReactComponent as DownloadIcon } from "assets/img/icons/downloadIcon.svg";
import { ReactComponent as ArrowRightIcon } from "assets/img/icons/arrow-right.svg";

import SkeletonGallery from "./SkeletonGallery";

import * as S from "./GalleryDetailsStyle";

const GalleryDetails = () => {
  const intl = useIntl();
  const [scrollParentRef, setScrollParentRef] = useState();
  const [hasMorePhotos, setHasMorePhotos] = useState(false);
  const [showLightBox, setShowLightBox] = useState(false);
  const [positionLightBox, setPositionLightBox] = useState(0);
  const { galleryDetails, isLoadingGallery, isLoadingPhotos } = useSelector(
    (state) => state.gallery,
  );
  const { id, name } = useParams();
  const dispatch = useDispatch();

  const dispatchGetGalleryFolder = useCallback(
    ({ id }) => {
      dispatch(GalleryActions.getGalleryFolder(id));
    },
    [dispatch],
  );

  const dispatchGetPhotos = () =>
    hasMorePhotos &&
    !isLoadingPhotos &&
    dispatch(GalleryActions.getGalleryPhotos(id));

  useEffect(() => {
    setHasMorePhotos(!!galleryDetails.pagination.next_page);
  }, [galleryDetails]);

  useEffect(() => {
    dispatchGetGalleryFolder({ id });
  }, [dispatchGetGalleryFolder, id]);

  const renderContentType = (kind, content) => {
    switch (kind) {
      case "image":
        return <S.contentImage src={content?.url} />;
      case "video":
        return (
          <S.contentVideo
            key={content.id}
            width="100%"
            autoPlay={false}
            height="340"
            preload
            loop
            muted
            controls
          >
            <source src={content?.url} type="video/mp4" />
          </S.contentVideo>
        );
      default:
        return null;
    }
  };

  const handlePositionLightBox = (type) => {
    if (type) {
      setPositionLightBox(positionLightBox + 1);
    } else {
      setPositionLightBox(positionLightBox - 1);
    }
  };

  const handleShowLightBox = (index) => {
    setPositionLightBox(index);
    setShowLightBox(true);
  };

  return (
    <Portal setRef={setScrollParentRef}>
      <PageTitle title={name} />
      {isLoadingGallery ? (
        <SkeletonGallery />
      ) : (
        <>
          <S.BoxButton>
            <GoBackButton
              isSubMenuPardiniPage
              onClick={() => history.push(`${urls.ROUTES.FILES}/galeria`)}
            >
              <GoBackIcon />
            </GoBackButton>
          </S.BoxButton>
          <InfiniteScroll
            pageStart={1}
            initialLoad={false}
            hasMore={hasMorePhotos}
            loadMore={dispatchGetPhotos}
            useWindow={false}
            threshold={10}
            getScrollParent={() => scrollParentRef}
          >
            <S.BodyGalleryDetails>
              {galleryDetails?.gallery_contents?.length > 0 ? (
                <>
                  <S.ContainerGalleryList>
                    {galleryDetails?.gallery_contents?.map(
                      ({ id, kind, content }, index) => (
                        <S.BodyDetailsGallery
                          key={id}
                          onClick={() => handleShowLightBox(index)}
                        >
                          {renderContentType(kind, content)}
                        </S.BodyDetailsGallery>
                      ),
                    )}
                  </S.ContainerGalleryList>
                  {hasMorePhotos && isLoadingPhotos && <SkeletonGallery />}
                  {showLightBox && (
                    <S.ContainerShowLightBox>
                      <S.ContentShowLightBox>
                        <S.ButtonArrow
                          onClick={() => handlePositionLightBox(false)}
                          lightBoxClick={positionLightBox === 0}
                        >
                          <ArrowLeftIcon />
                        </S.ButtonArrow>
                        <S.ContainerOptionsLightBox>
                          <S.LinkDownloadGallery
                            href={`${galleryDetails?.gallery_contents[positionLightBox].content.url}`}
                            target="_blank"
                            download
                          >
                            <DownloadIcon />
                          </S.LinkDownloadGallery>
                          <IconClose
                            id="close_btn"
                            onClick={() => setShowLightBox(false)}
                          />
                        </S.ContainerOptionsLightBox>
                        <S.LightBoxContent>
                          {renderContentType(
                            galleryDetails?.gallery_contents[positionLightBox]
                              .kind,
                            galleryDetails?.gallery_contents[positionLightBox]
                              .content,
                          )}
                        </S.LightBoxContent>
                        <S.ButtonArrow
                          onClick={() => handlePositionLightBox(true)}
                          lightBoxClick={
                            galleryDetails.gallery_contents.length - 1 ===
                            positionLightBox
                          }
                        >
                          <ArrowRightIcon />
                        </S.ButtonArrow>
                      </S.ContentShowLightBox>
                    </S.ContainerShowLightBox>
                  )}
                </>
              ) : (
                <S.ContentNotFound>
                  <NotFoundGeneric
                    textPage={
                      intl.messages["arquives.textPage.notGalleryFound"]
                    }
                  />
                </S.ContentNotFound>
              )}
            </S.BodyGalleryDetails>
          </InfiniteScroll>
        </>
      )}
    </Portal>
  );
};

export default GalleryDetails;
