import React, { useRef, useEffect } from "react";
import propType from "prop-types";
import { useVideoContext } from "../../../context/VideoContexts";
import { Media } from "./VideoStyle";

// eslint-disable-next-line react/prop-types
const Video = ({ url, onLoad, onPlay, ...rest }) => {
  const videoRef = useRef(null);
  const { setVideoIsFinished } = useVideoContext();

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
      typeof onLoad === "function" && onLoad();
    }
  }, [url]);

  const handleEnded = () => {
    setVideoIsFinished(true);
  };

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <Media
      ref={videoRef}
      {...rest}
      onEnded={handleEnded}
      data-testid="video-element"
      onLoad={onLoad}
      onPlay={onPlay}
    >
      <source src={url} type="video/mp4" />
    </Media>
  );
};

Video.defaultProps = {
  url: propType.string,
};

export { Video };
