import styled from "styled-components";
import css from "@styled-system/css";
import { style } from "../../../style";

const { nameApp } = style.names;

export const Media = styled("video")(
  css({
    "&::-webkit-media-controls-timeline": {
      display:
        nameApp === "somosmachado" ||
        nameApp === "Serquip mg" ||
        nameApp === "sample"
          ? "none"
          : "block",
    },
  }),
);
