import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import Portal from "templates/Portal";
import Flex from "components/core/Grid/Flex";
import ManagerFiles from "components/presentation/ManagerFiles";
import GalleryFiles from "components/presentation/GalleryFiles";
import { Creators as FilesActions } from "store/ducks/files";
import { Creators as GalleryActions } from "store/ducks/gallery";
import { useAnalytics } from "utils/hooks";
import { PageTitle } from "components/core/Typography/Typography";
import { fileMenuName } from "../../locale";
import { useIsScrollable } from "../../utils/hooks/useIsScrollable";

import { ReactComponent as RepeatIcon } from "assets/img/icons/repeatIcon.svg";

import { ContainerSelectedOption } from "./FilesStyle";

useAnalytics("screen_directories");

const Files = () => {
  const dispatch = useDispatch();
  const { type } = useParams();
  const intl = useIntl();
  const location = useLocation();
  const { pageTitle } = location.state || "";
  const [selecteShow, setSelecteShow] = useState(type === "galeria");
  const [scrollParentRef, setScrollParentRef] = useState();
  const {
    folders,
    repository,
    searchingFor,
    isLoading,
    hasMore,
    page,
  } = useSelector((state) => state.files);
  const {
    galleryList,
    isLoadingGallery,
    hasMoreGallery,
    pageGallery,
  } = useSelector((state) => state.gallery);
  const dispatchGetFiles = useCallback(
    () => dispatch(FilesActions.getFiles()),
    [dispatch],
  );

  const dispatchGetGallery = useCallback(
    () => dispatch(GalleryActions.getGallery()),
    [dispatch],
  );

  useEffect(() => {
    if (selecteShow) {
      hasMoreGallery && dispatchGetGallery();
    } else {
      hasMore && dispatchGetFiles();
    }
  }, [selecteShow, dispatchGetFiles, dispatchGetGallery]);

  //Gallery fetch functions

  const fetchMoreGalleryData = () => {
    hasMoreGallery && !isLoadingGallery && dispatch(GalleryActions.getGallery());
  };

  const [isScrollableGallery, refGallery, nodeGallery] = useIsScrollable(
    galleryList,
  );

  useEffect(() => {
    if (!nodeGallery || isLoadingGallery) return;

    if (!isScrollableGallery && hasMoreGallery) {
      fetchMoreGalleryData();
    }
  }, [isLoadingGallery, isScrollableGallery, hasMoreGallery, nodeGallery]);


  //Files fetch functions

  const fetchMoreData = () => {
    hasMore && !isLoading && dispatch(FilesActions.getFiles());
  };

  const [isScrollableFolders, refFolders, nodeFolders] = useIsScrollable(
    folders,
  );

  useEffect(() => {
    if (!nodeFolders || isLoading) return;

    if (!isScrollableFolders && hasMore) {
      fetchMoreData();
    }
  }, [isLoading, isScrollableFolders, hasMore, nodeFolders]);

  return (
    <Portal setRef={setScrollParentRef}>
      <Flex flexDirection="column" overflow="hidden">
        {searchingFor ? (
          <ManagerFiles repository={repository} />
        ) : (
          <>
            <PageTitle
              title={
                selecteShow
                  ? intl.messages["menu.gallery"]
                  : pageTitle || intl.messages["menu.files"]
              }
            >
              <ContainerSelectedOption
                onClick={() => setSelecteShow(!selecteShow)}
              >
                <RepeatIcon />
                {selecteShow ? fileMenuName() : "galeria"}
              </ContainerSelectedOption>
            </PageTitle>
            {selecteShow ? (
              <Flex
                id="scrollableDivGallery"
                ref={refGallery}
                style={{ overflowY: "auto" }}
              >
                <InfiniteScroll
                  pageStart={1}
                  initialLoad={false}
                  loadMore={fetchMoreGalleryData}
                  hasMore={hasMoreGallery}
                  useWindow={false}
                  threshold={1}
                  scrollableTarget="scrollableDivGallery"
                  style={{width: "100%"}}
                >
                  <GalleryFiles
                    galleryList={galleryList}
                    pageGallery={pageGallery}
                    isLoadingGallery={isLoadingGallery}
                  />
                </InfiniteScroll>
              </Flex>
            ) : (
              <Flex
                id="scrollableDiv"
                ref={refFolders}
                style={{ overflowY: "auto", justifyContent: "center" }}
              >
                <InfiniteScroll
                  pageStart={1}
                  initialLoad={false}
                  loadMore={fetchMoreData}
                  hasMore={hasMore}
                  useWindow={false}
                  threshold={1}
                  scrollableTarget="scrollableDiv"
                >
                  <ManagerFiles
                    folders={folders}
                    page={page}
                    isLoading={isLoading}
                  />
                </InfiniteScroll>
              </Flex>
            )}
          </>
        )}
      </Flex>
    </Portal>
  );
};

export default Files;
