import styled, { css } from "styled-components";
import theme from "styles/theme";

export const FistLineContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    font-size: 1rem;

    >svg{
        width: 0.5rem;
        height: 0.5rem;
        path {
            stroke: #808080;
            stroke-width: 3;
        }

        position: absolute;
        right: 0.5rem;
        top: -1rem;

        cursor: pointer;
    }
`

export const IconAndTitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: ${theme.colors.primaryColor};

    svg{
        width: 1rem;
        height: 1rem;
        path {
            stroke: ${theme.colors.primaryColor};
            stroke-width: 1.5;
        }
    }
`

export const TitleModal = styled.p`
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: bold;
    letter-spacing: 0.05rem;

    color: #4B4E5C;
    font-family: "RubikRegular", sans-serif;
    max-width: 26rem;
    text-align: left;
`

export const SubtitleModal = styled.span`
    font-size: 1rem;
    line-height: 2rem;
    color: #808080;
    font-family: "RubikRegular", sans-serif;
    text-align: left;

    margin-top: -0.5rem;
    width: 100%;
`

export const AnonymousContainer = styled.div`
    font-size: 1rem;
    line-height: 2rem;
    color: #808080;
    font-family: "RubikRegular", sans-serif;

    display: flex;
    gap: 1rem;
    margin: 0rem 0 2rem;
`

export const OptionSurveysContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1.4rem;

    margin: 1rem 0 1rem;
`

export const OptionSurvey = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    >img{
        width: 3rem;
        height: 3rem;

        border: 4px solid transparent;
        border-radius: 100px;
    }

    &:hover {
        >img{
          border-color: ${(props) => props.isActiveByFilter ? "" : "rgba(128, 128, 128, 0.50)"};
        }
    }

    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;

    &.isActiveByFilter{
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
      -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
      transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
    }
`
