import React from "react";
import PropTypes from "prop-types";
import urls from "utils/constants/urls";
import { history, convertToSlug } from "helpers/sharedHelpers";
import {style} from "style";

import { ReactComponent as GroupPardiniIcon } from "assets/img/icons/groupPardini.svg";
import { ReactComponent as CibraIcon} from "assets/img/icons/cibraIconGreen.svg";

import * as S from "./MenuComponentStyle";

function returnPath(type,id,title,kind){

  if(style.names.nameApp === "grupopardini"){
    return type !== "aboutUs"
    ? `${
        kind === "menu"
          ? urls.ROUTES.OUR_COMPANIES_PARDINI
          : urls.ROUTES.SUB_MENU_PARDINI
      }/${convertToSlug(title)}/${id}`
    : `${urls.ROUTES.SUB_ABOUT_US}/${id}`
  }else if(style.names.nameApp === "Time Cibra"){
    return `${
        type === "wayCibraOfBeing"
          ? urls.ROUTES.WAY_CIBRA_OF_BEING
          : urls.ROUTES.SOCIAL_RESPONSABILITY
      }/${convertToSlug(title)}/${id}`
  }

}

function returnIcon(){
  if(style.names.nameApp === "grupopardini"){
    return <GroupPardiniIcon/>
  }else if(style.names.nameApp === "Time Cibra"){
    return <CibraIcon/>
  }
  return <></>
}

const MenuComponent = ({ listMenu, type }) => (
  <S.GeneralContainer>
    {listMenu.map(({ id, title, kind, image }) => (
      <S.ButtonContainer>
        <S.ButtonLink
          onClick={() =>
            history.push(
              returnPath(type,id,title,kind)
            )
          }
        >
          {image?.url ? (
            <img src={image?.url} alt={title} />
          ) : (
            returnIcon()
          )}
        </S.ButtonLink>
        <S.FolderName>{title}</S.FolderName>
      </S.ButtonContainer>
    ))}
  </S.GeneralContainer>
);

MenuComponent.defaultProps = {
  listMenu: [],
  type: "Companies",
};

MenuComponent.propTypes = {
  type: PropTypes.string,
  listMenu: PropTypes.arrayOf(PropTypes.object),
};

export default MenuComponent;
