import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import InfiniteScroll from "react-infinite-scroller";
import urls from "utils/constants/urls";
import Portal from "templates/Portal";
import Flex from "components/core/Grid/Flex";
import { PageTitle } from "components/core/Typography/Typography";
import { Creators as StoreActions } from "store/ducks/store";
import notImgProductFound from "assets/img/icons/notFilesFound.svg";
import {NotFoundStore} from "components/presentation/NotFoundGeneric/NotFoundGeneric";
import { ReactComponent as IconCoins } from "assets/img/icons/iconCoins.svg";

import { convertToSlug } from "helpers/sharedHelpers";
import { useAnalytics } from "utils/hooks";
import Avatar from "components/core/Avatar/Avatar";
import { CoinsUser } from "components/core/Coins/Coins";
import SkeletonStore from "./SkeletonStore";
import SidebarStore from "./SidebarStore/SidebarStore";

import {
  ImgProduct,
  Price,
  ProductList,
  Product,
  ProductInfo,
  NameProduct,
  OutOfStock,
  ValuePrice,
  BoxConfig,
  AvatarContainer,
  TextContainer,
  UserName,
} from "./StoreStyle";

useAnalytics("screen_store_products");

const Store = () => {
  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();
  const [scrollParentRef, setScrollParentRef] = useState();
  const { products, hasMore, isLoading } = useSelector((state) => state.store);
  const dispatchGetProducts = useCallback(
    () => dispatch(StoreActions.getProducts()),
    [dispatch],
  );
  const { user } = useSelector((state) => state.user);
  const { pageTitle } = location.state || "" ;

  useEffect(() => {
    dispatchGetProducts();
  }, [dispatchGetProducts]);

  const fetchMoreData = () => {
    dispatch(StoreActions.getProducts());
  };

  const dispatchGetSidebarProductsDespatched = useCallback(() => {
    dispatch(
      StoreActions.getRescueDespatched({
        page: 1,
      }),
    );
  }, [dispatch]);

  const dispatchGetSidebarProductsNotDespatched = useCallback(() => {
    dispatch(
      StoreActions.getRescueNotDespatched({
        page: 1,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    dispatchGetSidebarProductsDespatched();
    dispatchGetSidebarProductsNotDespatched();
  }, [dispatchGetSidebarProductsDespatched,dispatchGetSidebarProductsNotDespatched]);

  return (
    <Portal
      sidebarContext={<SidebarStore />}
      setRef={setScrollParentRef}
      textBtnInSideBar="Resgatados"
    >
      <Flex flexDirection="column">
        <PageTitle title={pageTitle || intl.messages["menu.market"]} />
        {isLoading ? (
          <SkeletonStore amount={6} />
        ) : (
          <>
            {products?.length > 0 ? (
              <InfiniteScroll
                pageStart={1}
                initialLoad={false}
                loadMore={fetchMoreData}
                hasMore={hasMore}
                useWindow={false}
                threshold={10}
                getScrollParent={() => scrollParentRef}
                loader={<SkeletonStore amount={3} />}
              >
                <BoxConfig>
                  <Flex flexDirection="row" justifyContent="space-between">
                    <TextContainer>
                      <UserName>{user.name}, você tem</UserName>
                      <CoinsUser
                        coinsName="general.textPage.nameCoins"
                        score={user.score}
                      />
                    </TextContainer>
                    <AvatarContainer>
                      <Avatar size="52px" url={user?.image?.url} />
                    </AvatarContainer>
                  </Flex>
                </BoxConfig>
                <ProductList mt={2} spaceChildren={4} p={2}>
                  {products.map((product) => {
                    const { id, image, name, valuescore, amount } = product;
                    return (
                      <Product
                        outOfStock={amount === 0}
                        title={`${intl.messages["general.textPage.clickToShowMore"]} de ${name}`}
                        key={id}
                        to={
                          {
                            pathname: `${urls.ROUTES.DETAILS_PRODUCT}/${convertToSlug(name)}/${id}`,
                            state: { pageTitle },
                          }
                        }
                      >
                        <ProductInfo>
                          <ImgProduct src={image.url || notImgProductFound} loading="lazy" />
                          <NameProduct>{name}</NameProduct>
                        </ProductInfo>
                        {amount === 0 ? (
                          <OutOfStock>
                            <ValuePrice>
                              {intl.messages["store.textPage.outOfStock"]}
                            </ValuePrice>
                          </OutOfStock>
                        ) : (
                          <Price spaceChildren={2}>
                            <IconCoins width={18} height={18} />
                            <ValuePrice>{valuescore}</ValuePrice>
                          </Price>
                        )}
                      </Product>
                    );
                  })}
                </ProductList>
              </InfiniteScroll>
            ) : (
              <NotFoundStore
                message={intl.messages["store.textPage.notProductsInTheStore"]}
                submessage={intl.messages["store.textPage.keepAccumulatingPoints"]}
              />
            )}
          </>
        )}
      </Flex>
    </Portal>
  );
};

export default Store;
