// import { ReactComponent as IconLike } from "../../assets/img/icons/iconLike.svg";
import iconLike from "../../assets/img/icons/iconLike.svg";
import iconLoved from "../../assets/img/icons/iconLoved.svg";
import iconLaughter from "../../assets/img/icons/iconLaughter.svg";
import iconSurprised from "../../assets/img/icons/iconSurprised.svg";
import iconCongrats from "../../assets/img/icons/iconCongrats.svg";
import iconSupport from "../../assets/img/icons/iconSupport.svg";

export const reactionsList = {
  like: {
    icon: iconLike,
    title: "Curtir",
    value: "like",
  },
  loved_it: {
    icon: iconLoved,
    title: "Amei",
    value: "loved_it",
  },
  laughter: {
    icon: iconLaughter,
    title: "Risada",
    value: "laughter",
  },
  surprized: {
    icon: iconSurprised,
    title: "Surpreso",
    value: "surprized",
  },
  support: {
    icon: iconSupport,
    title: "Apoio",
    value: "support",
  },
  congrats: {
    icon: iconCongrats,
    title: "Parabéns",
    value: "congrats",
  },
};

export const reactionsListArray = [
  {
    icon: iconLike,
    title: "Curtir",
    value: "like",
  },
  {
    icon: iconLoved,
    title: "Amei",
    value: "loved_it",
  },
  {
    icon: iconLaughter,
    title: "Risada",
    value: "laughter",
  },
  {
    icon: iconSurprised,
    title: "Surpreso",
    value: "surprized",
  },
  {
    icon: iconSupport,
    title: "Apoio",
    value: "support",
  },
  {
    icon: iconCongrats,
    title: "Parabéns",
    value: "congrats",
  },
];
