import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getToolsExternalLinks: ["payload"],
  getToolsExternalLinksSuccess: ["payload", "isLoadingTools"],
  getToolsExternalLinksFail: ["isLoadingTools", "error"],

  getToolsExternalLinksFolder: ["id"],
  getToolsExternalLinksFolderSuccess: ["payload", "isLoadingTools"],
  getToolsExternalLinksFolderFail: ["isLoadingTools", "error"],
});

const INITIAL_STATE = {
  toolsExternalLinksAll: [],
  isLoadingTools: true,
  error: [],
};

const getToolsExternalLinks = (state) => ({
  ...state,
  isLoadingTools: true,
});
const getToolsExternalLinksSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoadingTools: false,
});
const getToolsExternalLinksFail = (state) => ({
  ...state,
  isLoadingTools: false,
});

const getToolsExternalLinksFolder = (id) => ({ ...id, isLoadingTools: true });
const getToolsExternalLinksFolderSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoadingTools: false,
});
const getToolsExternalLinksFolderFail = (state) => ({
  ...state,
  isLoadingTools: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_TOOLS_EXTERNAL_LINKS]: getToolsExternalLinks,
  [Types.GET_TOOLS_EXTERNAL_LINKS_SUCCESS]: getToolsExternalLinksSuccess,
  [Types.GET_TOOLS_EXTERNAL_LINKS_FAIL]: getToolsExternalLinksFail,

  [Types.GET_TOOLS_EXTERNAL_LINKS_FOLDER]: getToolsExternalLinksFolder,
  [Types.GET_TOOLS_EXTERNAL_LINKS_FOLDER_SUCCESS]: getToolsExternalLinksFolderSuccess,
  [Types.GET_TOOLS_EXTERNAL_LINKS_FOLDER_FAIL]: getToolsExternalLinksFolderFail,
});
