import { put, takeEvery, takeLatest } from "redux-saga/effects";
import { API_V2 } from "utils/API/API";
import { DELETE } from "utils/constants/verbs";
import { requestAPI } from "helpers/requestHelpers";
import { basePathV2 } from "../../utils/constants/endpoints";
import { Types } from "../ducks/reactions";
import { Types as DetailsPublicationsTypes } from "../ducks/detailspublication";
import { Types as CommentTypes } from "../ducks/postComments";

function* likedPublication({ payload }) {
  const {
    isLikeButton,
    reaction,
    idPublication,
    currentUserHasReacted,
    user_reaction,
  } = payload;

  try {
    if (isLikeButton && currentUserHasReacted) {
      yield requestAPI({
        verb: DELETE,
        endPoint: `canvas_publications/${idPublication}/canvas_likes`,
        id: user_reaction.reaction_id,
      });
    } else if (!currentUserHasReacted) {
      yield API_V2({
        method: "POST",
        url: `${basePathV2}/canvas_publications/${idPublication}/canvas_likes`,
        data: { reaction },
      });
    } else if (currentUserHasReacted && reaction !== user_reaction.reaction) {
      yield API_V2({
        method: "PATCH",
        url: `${basePathV2}/canvas_publications/${idPublication}/canvas_likes/${user_reaction.reaction_id}`,
        data: { reaction },
      });
    }

    yield put({
      type: DetailsPublicationsTypes.GET_DETAILS_PUBLICATION,
      payload: idPublication,
    });
  } catch (error) {
    yield put({
      type: Types.POST_LIKED_PUBLICATION_FAIL,
      ...error.response.data,
      isLoading: false,
    });
  }
}

function* createPublicationCommentsReactions({ payload }) {
  const { idComment, reaction, postId } = payload;

  try {
    yield API_V2({
      method: "POST",
      url: `${basePathV2}/canvas_comments/${idComment}/canvas_comments_reactions`,
      data: { reaction },
    });

    yield put({
      type: CommentTypes.GET_POST_COMMENTS,
      payload: {
        postId,
        page: 1,
      },
    });
  } catch (error) {
    yield put({
      type: Types.CREATE_PUBLICATION_COMMENTS_REACTIONS_FAIL,
      error,
    });
  }
}

function* editPublicationCommentsReactions({ payload }) {
  const { idComment, reaction, idReaction, postId } = payload;

  try {
    yield API_V2({
      method: "PATCH",
      url: `${basePathV2}/canvas_comments/${idComment}/canvas_comments_reactions/${idReaction}`,
      data: { reaction },
    });

    yield put({
      type: CommentTypes.GET_POST_COMMENTS,
      payload: {
        postId,
        page: 1,
      },
    });
  } catch (error) {
    yield put({
      type: Types.EDIT_PUBLICATION_COMMENTS_REACTIONS_FAIL,
      error,
    });
  }
}

function* deletePublicationCommentsReactions({ payload }) {
  const { idComment, reaction, idReaction, postId } = payload;

  try {
    yield API_V2({
      method: "DELETE",
      url: `${basePathV2}/canvas_comments/${idComment}/canvas_comments_reactions/${idReaction}`,
      data: { reaction },
    });

    yield put({
      type: CommentTypes.GET_POST_COMMENTS,
      payload: {
        postId,
        page: 1,
      },
    });
  } catch (error) {
    yield put({
      type: Types.DELETE_PUBLICATION_COMMENTS_REACTIONS_FAIL,
      error,
    });
  }
}

function* getPublicationAllReactions({ payload }) {
  const { id, reaction, page, per_page } = payload;

  try {
    const reactions = yield API_V2({
      method: "GET",
      url: `${basePathV2}/canvas_publications/${id}/canvas_likes/reactions?reaction=${reaction}&page=${page}&per_page=${per_page}`,
    });

    yield put({
      allReactions: reactions.data,
      type: Types.GET_PUBLICATION_ALL_REACTIONS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_PUBLICATION_ALL_REACTIONS_FAIL,
      error,
    });
  }
}

function* getPublicationReactions({ payload }) {
  const { id, reaction, page, per_page } = payload;

  try {
    const reactions = yield API_V2({
      method: "GET",
      url: `${basePathV2}/canvas_publications/${id}/canvas_likes/reactions?reaction=${reaction}&page=${page}&per_page=${per_page}`,
    });

    yield put({
      reactions: reactions.data,
      type: Types.GET_PUBLICATION_REACTIONS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_PUBLICATION_REACTIONS_FAIL,
      error,
    });
  }
}

function* getPublicationAllCommentsReactions({ payload }) {
  const { id, reaction, page, per_page } = payload;
  try {
    const reactionsCommentsGet = yield API_V2({
      method: "GET",
      url: `${basePathV2}/canvas_comments/${id}/canvas_comments_reactions?reaction=${reaction}&page=${page}&per_page=${per_page}`,
    });

    yield put({
      allReactions: reactionsCommentsGet.data,
      type: Types.GET_PUBLICATION_ALL_COMMENTS_REACTIONS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_PUBLICATION_ALL_COMMENTS_REACTIONS_FAIL,
      error,
    });
  }
}

function* getPublicationCommentsReactions({ payload }) {
  const { id, reaction, page, per_page } = payload;
  try {
    const reactionsCommentsGet = yield API_V2({
      method: "GET",
      url: `${basePathV2}/canvas_comments/${id}/canvas_comments_reactions?reaction=${reaction}&page=${page}&per_page=${per_page}`,
    });

    yield put({
      reactions: reactionsCommentsGet.data,
      type: Types.GET_PUBLICATION_REACTIONS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_PUBLICATION_COMMENTS_REACTIONS_FAIL,
      error,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(
    Types.CREATE_PUBLICATION_COMMENTS_REACTIONS,
    createPublicationCommentsReactions,
  );
  yield takeEvery(Types.POST_LIKED_PUBLICATION, likedPublication);
  yield takeEvery(
    Types.DELETE_PUBLICATION_COMMENTS_REACTIONS,
    deletePublicationCommentsReactions,
  );
  yield takeEvery(
    Types.EDIT_PUBLICATION_COMMENTS_REACTIONS,
    editPublicationCommentsReactions,
  );
  yield takeEvery(Types.GET_PUBLICATION_REACTIONS, getPublicationReactions);
  yield takeEvery(
    Types.GET_PUBLICATION_COMMENTS_REACTIONS,
    getPublicationCommentsReactions,
  );
  yield takeEvery(
    Types.GET_PUBLICATION_ALL_REACTIONS,
    getPublicationAllReactions,
  );
  yield takeEvery(
    Types.GET_PUBLICATION_ALL_COMMENTS_REACTIONS,
    getPublicationAllCommentsReactions,
  );
}
